import type { AppConfiguration } from './types';

export * as names from './constants';
export * from './hooks';
export * from './language';
export * from './types';

export async function run(config: AppConfiguration) {
  const app = await import('./app');
  return await app.run(config);
}
