import * as React from 'react';

const ComingSoonIcon: React.FC = () => {
  return (
    <svg
      id="Group_4974"
      data-name="Group 4974"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="209.039"
      height="130"
      viewBox="0 0 209.039 130">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_2174" data-name="Rectangle 2174" width="209.039" height="130" fill="none" />
        </clipPath>
        <clipPath id="clip-path-6">
          <rect id="Rectangle_2150" data-name="Rectangle 2150" width="20.552" height="11.565" fill="none" />
        </clipPath>
        <clipPath id="clip-path-7">
          <rect id="Rectangle_2151" data-name="Rectangle 2151" width="1.705" height="23.582" fill="none" />
        </clipPath>
        <clipPath id="clip-path-8">
          <rect id="Rectangle_2152" data-name="Rectangle 2152" width="1.718" height="22.8" fill="none" />
        </clipPath>
        <clipPath id="clip-path-12">
          <rect id="Rectangle_2156" data-name="Rectangle 2156" width="8.52" height="1.417" fill="none" />
        </clipPath>
        <clipPath id="clip-path-13">
          <rect id="Rectangle_2157" data-name="Rectangle 2157" width="5.877" height="3.148" fill="none" />
        </clipPath>
        <clipPath id="clip-path-14">
          <rect id="Rectangle_2158" data-name="Rectangle 2158" width="3.665" height="7.955" fill="none" />
        </clipPath>
        <clipPath id="clip-path-15">
          <rect id="Rectangle_2163" data-name="Rectangle 2163" width="15.488" height="13.622" fill="none" />
        </clipPath>
        <clipPath id="clip-path-16">
          <rect id="Rectangle_2164" data-name="Rectangle 2164" width="8.747" height="2.096" fill="none" />
        </clipPath>
        <clipPath id="clip-path-18">
          <rect id="Rectangle_2166" data-name="Rectangle 2166" width="14.334" height="9.456" fill="none" />
        </clipPath>
        <clipPath id="clip-path-19">
          <rect id="Rectangle_2167" data-name="Rectangle 2167" width="5.867" height="17.412" fill="none" />
        </clipPath>
        <clipPath id="clip-path-20">
          <rect id="Rectangle_2168" data-name="Rectangle 2168" width="8.486" height="3.055" fill="none" />
        </clipPath>
        <clipPath id="clip-path-21">
          <rect id="Rectangle_2169" data-name="Rectangle 2169" width="6.76" height="1.328" fill="none" />
        </clipPath>
        <clipPath id="clip-path-22">
          <rect id="Rectangle_2170" data-name="Rectangle 2170" width="19.599" height="13.994" fill="none" />
        </clipPath>
        <clipPath id="clip-path-23">
          <rect id="Rectangle_2171" data-name="Rectangle 2171" width="6.46" height="5.476" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_4973" data-name="Group 4973" clip-path="url(#clip-path)">
        <g id="Group_4914" data-name="Group 4914">
          <g id="Group_4913" data-name="Group 4913" clip-path="url(#clip-path)">
            <path
              id="Path_94711"
              data-name="Path 94711"
              d="M60.338,14.957c.172-1.246-1.587-2.27-2.908-2.377a7.481,7.481,0,0,1-1.881-.163c-1.293-.451-1.809-2.121-3.071-2.653a3.61,3.61,0,0,0-2.944.416,7.677,7.677,0,0,1-2.84,1.1c-.889.057-1.854-.273-2.631.162-.58.323-.883.991-1.417,1.385a3.38,3.38,0,0,1-1.852.5,5.217,5.217,0,0,0-1.91.312,1.509,1.509,0,0,0-.956,1.532Z"
              transform="translate(-26.869 -6.798)"
              fill="#ebebeb"
            />
            <path
              id="Path_94712"
              data-name="Path 94712"
              d="M533.912,5.366c.172-1.246-1.587-2.27-2.908-2.377a7.468,7.468,0,0,1-1.881-.163c-1.293-.451-1.81-2.121-3.071-2.653a3.61,3.61,0,0,0-2.944.416,7.679,7.679,0,0,1-2.84,1.1c-.889.057-1.854-.273-2.632.162-.579.323-.883.991-1.417,1.385a3.38,3.38,0,0,1-1.852.5,5.217,5.217,0,0,0-1.91.312,1.51,1.51,0,0,0-.956,1.532Z"
              transform="translate(-362.523 -0.001)"
              fill="#ebebeb"
            />
            <path
              id="Path_94713"
              data-name="Path 94713"
              d="M245.146,123.131c.116-.843-1.074-1.537-1.968-1.609a5.081,5.081,0,0,1-1.273-.11c-.875-.305-1.225-1.436-2.079-1.8a2.443,2.443,0,0,0-1.993.282,5.2,5.2,0,0,1-1.922.748c-.6.038-1.255-.185-1.781.109-.392.219-.6.671-.959.937a2.287,2.287,0,0,1-1.254.34,3.527,3.527,0,0,0-1.292.211,1.021,1.021,0,0,0-.647,1.037Z"
              transform="translate(-162.992 -84.697)"
              fill="#f5f5f5"
            />
            <path
              id="Path_94714"
              data-name="Path 94714"
              d="M657.438,65.494c.116-.843-1.074-1.537-1.968-1.609a5.083,5.083,0,0,1-1.273-.11c-.875-.305-1.225-1.436-2.079-1.8a2.443,2.443,0,0,0-1.993.282,5.2,5.2,0,0,1-1.922.748c-.6.038-1.255-.185-1.781.109-.392.219-.6.671-.959.937a2.286,2.286,0,0,1-1.254.34,3.527,3.527,0,0,0-1.292.211,1.021,1.021,0,0,0-.647,1.037Z"
              transform="translate(-455.212 -43.846)"
              fill="#f5f5f5"
            />
            <path
              id="Path_94715"
              data-name="Path 94715"
              d="M223.04,111.292h-94.2a18.649,18.649,0,0,1-18.627-18.627V54.778a4.156,4.156,0,0,0-4.152-4.152H72.669a2.707,2.707,0,0,0-2.7,2.7V67.341A16.767,16.767,0,0,1,53.217,84.089H21.284V70.046H53.217a2.708,2.708,0,0,0,2.7-2.7V53.331A16.767,16.767,0,0,1,72.669,36.583h33.393a18.215,18.215,0,0,1,18.195,18.195V92.665a4.59,4.59,0,0,0,4.584,4.584h94.2Z"
              transform="translate(-15.085 -25.929)"
              fill="#ebebeb"
            />
            <path
              id="Path_94716"
              data-name="Path 94716"
              d="M210.791,43.579h0a1.69,1.69,0,0,1-1.69-1.69V24.537a1.69,1.69,0,0,1,3.38,0V41.889a1.69,1.69,0,0,1-1.69,1.69"
              transform="translate(-148.204 -16.193)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94717"
              data-name="Path 94717"
              d="M148.755,115.165h0a1.69,1.69,0,0,1-1.69,1.69H129.713a1.69,1.69,0,0,1,0-3.38h17.352a1.69,1.69,0,0,1,1.69,1.69"
              transform="translate(-90.739 -80.428)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94718"
              data-name="Path 94718"
              d="M90.178,163.433h0a1.69,1.69,0,0,1-1.69-1.69V144.391a1.69,1.69,0,0,1,3.38,0v17.352a1.69,1.69,0,0,1-1.69,1.69"
              transform="translate(-62.718 -101.142)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94719"
              data-name="Path 94719"
              d="M332.534,119.54h0a1.69,1.69,0,0,1-1.69,1.69H313.492a1.69,1.69,0,0,1,0-3.38h17.352a1.69,1.69,0,0,1,1.69,1.69"
              transform="translate(-220.996 -83.528)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94720"
              data-name="Path 94720"
              d="M332.534,217.408h0a1.69,1.69,0,0,1-1.69,1.69H313.492a1.69,1.69,0,0,1,0-3.38h17.352a1.69,1.69,0,0,1,1.69,1.69"
              transform="translate(-220.996 -152.894)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94721"
              data-name="Path 94721"
              d="M541.387,231.845h0a1.69,1.69,0,0,1,1.69,1.69v17.352a1.69,1.69,0,1,1-3.38,0V233.535a1.69,1.69,0,0,1,1.69-1.69"
              transform="translate(-382.521 -164.325)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94722"
              data-name="Path 94722"
              d="M658.186,231.845h0a1.69,1.69,0,0,1,1.69,1.69v17.352a1.69,1.69,0,0,1-3.38,0V233.535a1.69,1.69,0,0,1,1.69-1.69"
              transform="translate(-465.304 -164.325)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94723"
              data-name="Path 94723"
              d="M301.886,45.806h0a1.69,1.69,0,0,1-1.69-1.69V26.764a1.69,1.69,0,0,1,3.38,0V44.116a1.69,1.69,0,0,1-1.69,1.69"
              transform="translate(-212.77 -17.772)"
              fill="#e0e0e0"
            />
          </g>
        </g>
        <g id="Group_4916" data-name="Group 4916">
          <g id="Group_4915" data-name="Group 4915" clip-path="url(#clip-path)">
            <path
              id="Path_94724"
              data-name="Path 94724"
              d="M211.579,432.036c0,.042-46.005.076-102.743.076-56.757,0-102.753-.034-102.753-.076s46-.076,102.753-.076c56.737,0,102.743.034,102.743.076"
              transform="translate(-4.311 -306.16)"
              fill="#263238"
            />
          </g>
        </g>
        <g id="Group_4918" data-name="Group 4918">
          <g id="Group_4917" data-name="Group 4917" clip-path="url(#clip-path)">
            <path
              id="Path_94725"
              data-name="Path 94725"
              d="M645.673,368.508a3.47,3.47,0,0,1,1.8,2.037,6.125,6.125,0,0,1,.239,2.759,10.812,10.812,0,0,1-2.316,5.029c-1.508-1.316-1.855-3.611-1.957-4.787-.163-1.858.672-5.314,2.236-5.037"
              transform="translate(-456.034 -261.176)"
              fill="#377289"
            />
            <path
              id="Path_94726"
              data-name="Path 94726"
              d="M656.059,403.343a2.96,2.96,0,0,1,.956-2.814,3.93,3.93,0,0,1,2.9-.905,1.655,1.655,0,0,1,1.272.571,1.266,1.266,0,0,1,.02,1.283,2.567,2.567,0,0,1-.95.935,5.089,5.089,0,0,1-4.2.93"
              transform="translate(-464.946 -283.232)"
              fill="#377289"
            />
            <path
              id="Path_94727"
              data-name="Path 94727"
              d="M649.58,413.135a2.1,2.1,0,0,1,.054-.368l.182-.994a10.4,10.4,0,0,1,1.026-3.176,6.359,6.359,0,0,1,2.279-2.422,4.321,4.321,0,0,1,.93-.411,2.608,2.608,0,0,1,.268-.068.275.275,0,0,1,.1-.013,6.749,6.749,0,0,0-1.245.571,6.565,6.565,0,0,0-2.213,2.407,11.183,11.183,0,0,0-1.05,3.132c-.091.416-.165.754-.223.986a1.954,1.954,0,0,1-.1.357"
              transform="translate(-460.401 -287.534)"
              fill="#263238"
            />
            <path
              id="Path_94728"
              data-name="Path 94728"
              d="M649.6,377.777a.767.767,0,0,1,.018.16c.007.119.016.272.027.462.022.4.045.981.068,1.7.046,1.435.083,3.418.074,5.608s-.063,4.174-.122,5.608c-.029.717-.058,1.3-.083,1.7-.013.189-.023.342-.031.461a.726.726,0,0,1-.02.16.784.784,0,0,1-.006-.162c0-.119,0-.273.007-.462.01-.417.024-.994.041-1.7.035-1.434.074-3.416.084-5.606s-.013-4.17-.036-5.6c-.011-.705-.02-1.282-.027-1.7,0-.19,0-.343,0-.462a.763.763,0,0,1,.008-.161"
              transform="translate(-460.361 -267.757)"
              fill="#263238"
            />
            <path
              id="Path_94729"
              data-name="Path 94729"
              d="M633.521,403.221a6.924,6.924,0,0,0-3.781-5.072,1.46,1.46,0,0,0-1.576-.024,1.433,1.433,0,0,0-.119,1.571,5.916,5.916,0,0,0,5.44,3.673"
              transform="translate(-445.016 -282.019)"
              fill="#377289"
            />
            <path
              id="Path_94730"
              data-name="Path 94730"
              d="M635.122,404.581a1.786,1.786,0,0,1,.32.139,3.357,3.357,0,0,1,.361.193c.135.084.3.167.46.289a6.046,6.046,0,0,1,.527.4,6.8,6.8,0,0,1,.56.509,7.691,7.691,0,0,1,1.065,1.368,7.554,7.554,0,0,1,.7,1.586,6.558,6.558,0,0,1,.177.736,5.646,5.646,0,0,1,.091.653,5.169,5.169,0,0,1,.025.543,3.093,3.093,0,0,1-.012.409,1.762,1.762,0,0,1-.039.347c-.029,0,.022-.5-.066-1.29a6.037,6.037,0,0,0-.1-.642A6.927,6.927,0,0,0,639,409.1a7.85,7.85,0,0,0-.7-1.555,8.043,8.043,0,0,0-1.039-1.351,7.227,7.227,0,0,0-.544-.51c-.173-.158-.351-.284-.51-.4-.648-.464-1.1-.671-1.089-.7"
              transform="translate(-450.155 -286.754)"
              fill="#263238"
            />
          </g>
        </g>
        <path
          id="Path_94731"
          data-name="Path 94731"
          d="M308.681,351.855l4.3,4.559V375.26h4.605V356.414l4.484-4.36Z"
          transform="translate(-218.784 -249.384)"
          fill="#e0e0e0"
        />
        <g id="Group_4935" data-name="Group 4935">
          <g id="Group_4934" data-name="Group 4934" clip-path="url(#clip-path)">
            <path
              id="Path_94732"
              data-name="Path 94732"
              d="M308.681,351.856,322.07,352h.145l-.1.1-4.478,4.366.021-.049c0,4.35,0,9.269.007,14.458,0,1.488,0,2.953,0,4.388v.075h-.075l-4.605,0h-.073v-.073c.012-7.743.022-14.37.03-18.846l.012.03-4.267-4.589,4.331,4.529.012.013v.017c.007,4.476.017,11.1.03,18.846l-.073-.073,4.605,0-.075.075c0-1.435,0-2.9,0-4.388,0-5.189.005-10.107.007-14.458v-.029l.021-.02,4.49-4.354.041.1-13.387-.259"
              transform="translate(-218.783 -249.385)"
              fill="#263238"
            />
            <path
              id="Path_94733"
              data-name="Path 94733"
              d="M327.988,367.538a35.429,35.429,0,0,1-4.63,0,35.429,35.429,0,0,1,4.63,0"
              transform="translate(-229.186 -260.446)"
              fill="#263238"
            />
            <path
              id="Path_94734"
              data-name="Path 94734"
              d="M132.923,196.723h-.183L0,195.426l.208-23.4,125.289,1.23a3.96,3.96,0,0,0,4-3.96V151.432a19.98,19.98,0,0,1,19.98-19.98H209.04v23.405H156.858a3.96,3.96,0,0,0-3.96,3.96v17.925a19.98,19.98,0,0,1-19.975,19.98"
              transform="translate(0 -93.169)"
              fill="#536471"
            />
            <path
              id="Path_94735"
              data-name="Path 94735"
              d="M104.82,294.628h0a1.574,1.574,0,0,1-1.574-1.574V269.012a1.574,1.574,0,1,1,3.149,0v24.041a1.574,1.574,0,0,1-1.574,1.574"
              transform="translate(-73.178 -189.552)"
              fill="#455a64"
            />
            <path
              id="Path_94736"
              data-name="Path 94736"
              d="M104.635,294.444a1.892,1.892,0,0,0,.57-.124,1.564,1.564,0,0,0,.962-1.26c.017-1.458-.01-3.587-.011-6.208s-.01-5.747-.016-9.213c0-1.734,0-3.554,0-5.44q0-1.415,0-2.878a6.248,6.248,0,0,0-.02-.721,1.459,1.459,0,0,0-.263-.644,1.5,1.5,0,0,0-2.432,0,1.463,1.463,0,0,0-.264.644,6.172,6.172,0,0,0-.02.721q0,1.462,0,2.879c0,1.887,0,3.707,0,5.442-.006,3.467-.012,6.589-.016,9.214s-.028,4.749-.011,6.207a1.564,1.564,0,0,0,.963,1.26,1.889,1.889,0,0,0,.569.124s-.051,0-.15,0a1.528,1.528,0,0,1-.43-.1,1.555,1.555,0,0,1-.594-.4,1.579,1.579,0,0,1-.416-.881c-.036-1.473-.025-3.591-.038-6.215s-.01-5.747-.016-9.214c0-1.734,0-3.555,0-5.442q0-1.415,0-2.879a6.19,6.19,0,0,1,.021-.744,1.61,1.61,0,0,1,.29-.71,1.647,1.647,0,0,1,2.678,0,1.611,1.611,0,0,1,.289.71,6.253,6.253,0,0,1,.021.744q0,1.461,0,2.878c0,1.887,0,3.706,0,5.44-.006,3.466-.012,6.589-.016,9.213s0,4.742-.038,6.215a1.578,1.578,0,0,1-.416.882,1.552,1.552,0,0,1-.594.4,1.531,1.531,0,0,1-.43.1c-.1,0-.15,0-.15,0"
              transform="translate(-72.992 -189.367)"
              fill="#263238"
            />
            <path
              id="Path_94737"
              data-name="Path 94737"
              d="M466.739,193.535h0a1.575,1.575,0,0,1-2.15.576l-25.6-14.78a1.574,1.574,0,0,1,1.574-2.727l25.6,14.78a1.574,1.574,0,0,1,.576,2.15"
              transform="translate(-310.583 -125.022)"
              fill="#455a64"
            />
            <path
              id="Path_94738"
              data-name="Path 94738"
              d="M466.56,193.352a1.791,1.791,0,0,0,.189-.665,1.563,1.563,0,0,0-.164-.813,1.543,1.543,0,0,0-.319-.425,2.386,2.386,0,0,0-.505-.341l-6.416-3.676-9.506-5.47-5.611-3.234-2.967-1.712-.756-.436a1.686,1.686,0,0,0-.786-.286,1.5,1.5,0,0,0-1.355,2.342,1.681,1.681,0,0,0,.64.539l.756.437,2.967,1.714,5.608,3.243,9.491,5.5,6.392,3.718a2.355,2.355,0,0,0,.547.267,1.536,1.536,0,0,0,.527.063,1.559,1.559,0,0,0,.786-.265,1.785,1.785,0,0,0,.482-.5,1.634,1.634,0,0,1-.092.153,1.578,1.578,0,0,1-1.718.6,2.421,2.421,0,0,1-.563-.266l-6.416-3.676-9.507-5.47-5.612-3.235-2.968-1.712-.756-.437a1.838,1.838,0,0,1-.689-.584,1.647,1.647,0,0,1,1.492-2.579,1.841,1.841,0,0,1,.849.306l.756.437L444.3,178.6l5.606,3.242,9.49,5.5,6.392,3.718a2.421,2.421,0,0,1,.512.354,1.578,1.578,0,0,1,.344,1.786,1.667,1.667,0,0,1-.087.156"
              transform="translate(-310.404 -124.84)"
              fill="#263238"
            />
            <path
              id="Path_94739"
              data-name="Path 94739"
              d="M640.734,152.02h0a1.574,1.574,0,0,1-1.575-1.574V126.4a1.574,1.574,0,1,1,3.148,0v24.041a1.574,1.574,0,0,1-1.574,1.574"
              transform="translate(-453.016 -88.476)"
              fill="#455a64"
            />
            <path
              id="Path_94740"
              data-name="Path 94740"
              d="M640.547,151.836a1.885,1.885,0,0,0,.569-.124,1.563,1.563,0,0,0,.962-1.26c.017-1.459-.01-3.587-.011-6.208s-.01-5.747-.016-9.213c0-1.734,0-3.554,0-5.441q0-1.415,0-2.878a6.237,6.237,0,0,0-.02-.721,1.459,1.459,0,0,0-.263-.644,1.5,1.5,0,0,0-2.432,0,1.463,1.463,0,0,0-.264.644,6.221,6.221,0,0,0-.02.721q0,1.462,0,2.879c0,1.887,0,3.707,0,5.442-.006,3.467-.012,6.589-.016,9.214s-.028,4.749-.011,6.208a1.562,1.562,0,0,0,.962,1.26,1.886,1.886,0,0,0,.57.124s-.051,0-.151,0a1.525,1.525,0,0,1-.43-.1,1.554,1.554,0,0,1-.594-.4,1.58,1.58,0,0,1-.416-.881c-.036-1.473-.025-3.591-.037-6.215s-.01-5.747-.016-9.214c0-1.734,0-3.555,0-5.442q0-1.415,0-2.879a6.168,6.168,0,0,1,.021-.744,1.608,1.608,0,0,1,.29-.71,1.647,1.647,0,0,1,2.678,0,1.61,1.61,0,0,1,.289.71,6.2,6.2,0,0,1,.021.743q0,1.462,0,2.878c0,1.887,0,3.707,0,5.441-.006,3.466-.012,6.589-.017,9.213s0,4.742-.037,6.215a1.58,1.58,0,0,1-.416.882,1.548,1.548,0,0,1-.594.4,1.525,1.525,0,0,1-.43.1c-.1,0-.15,0-.15,0"
              transform="translate(-452.83 -88.291)"
              fill="#263238"
            />
            <path
              id="Path_94741"
              data-name="Path 94741"
              d="M388.446,294.628h0a1.574,1.574,0,0,1-1.574-1.574V269.012a1.574,1.574,0,1,1,3.149,0v24.041a1.574,1.574,0,0,1-1.574,1.574"
              transform="translate(-274.203 -189.552)"
              fill="#455a64"
            />
            <path
              id="Path_94742"
              data-name="Path 94742"
              d="M388.261,294.444a1.891,1.891,0,0,0,.57-.124,1.564,1.564,0,0,0,.962-1.26c.017-1.458-.01-3.587-.011-6.208s-.01-5.747-.016-9.213c0-1.734,0-3.554,0-5.44q0-1.415,0-2.878a6.241,6.241,0,0,0-.019-.721,1.459,1.459,0,0,0-.263-.644,1.5,1.5,0,0,0-2.432,0,1.463,1.463,0,0,0-.264.644,6.166,6.166,0,0,0-.02.721q0,1.462,0,2.879c0,1.887,0,3.707,0,5.442-.006,3.467-.012,6.589-.016,9.214s-.028,4.749-.011,6.207a1.563,1.563,0,0,0,.962,1.26,1.891,1.891,0,0,0,.569.124s-.051,0-.15,0a1.528,1.528,0,0,1-.43-.1,1.555,1.555,0,0,1-.594-.4,1.581,1.581,0,0,1-.416-.881c-.036-1.473-.025-3.591-.038-6.215s-.01-5.747-.016-9.214c0-1.734,0-3.555,0-5.442q0-1.415,0-2.879a6.181,6.181,0,0,1,.021-.744,1.61,1.61,0,0,1,.29-.71,1.647,1.647,0,0,1,2.678,0,1.612,1.612,0,0,1,.289.71,6.254,6.254,0,0,1,.021.744q0,1.461,0,2.878c0,1.887,0,3.706,0,5.44-.006,3.466-.012,6.589-.016,9.213s0,4.742-.038,6.215a1.58,1.58,0,0,1-.415.882,1.554,1.554,0,0,1-.594.4,1.532,1.532,0,0,1-.43.1c-.1,0-.15,0-.15,0"
              transform="translate(-274.018 -189.367)"
              fill="#263238"
            />
            <path
              id="Path_94743"
              data-name="Path 94743"
              d="M272.883,294.628h0a1.574,1.574,0,0,1-1.574-1.574V269.012a1.574,1.574,0,1,1,3.149,0v24.041a1.574,1.574,0,0,1-1.574,1.574"
              transform="translate(-192.295 -189.552)"
              fill="#455a64"
            />
            <path
              id="Path_94744"
              data-name="Path 94744"
              d="M272.7,294.444a1.892,1.892,0,0,0,.57-.124,1.564,1.564,0,0,0,.962-1.26c.017-1.458-.01-3.587-.011-6.208s-.01-5.747-.016-9.213c0-1.734,0-3.554,0-5.44q0-1.415,0-2.878a6.241,6.241,0,0,0-.02-.721,1.459,1.459,0,0,0-.263-.644,1.5,1.5,0,0,0-2.432,0,1.462,1.462,0,0,0-.264.644,6.166,6.166,0,0,0-.02.721q0,1.462,0,2.879c0,1.887,0,3.707,0,5.442-.006,3.467-.012,6.589-.016,9.214s-.028,4.749-.011,6.207a1.563,1.563,0,0,0,.962,1.26,1.889,1.889,0,0,0,.569.124s-.051,0-.15,0a1.528,1.528,0,0,1-.43-.1,1.554,1.554,0,0,1-.594-.4,1.579,1.579,0,0,1-.416-.881c-.036-1.473-.025-3.591-.038-6.215s-.01-5.747-.016-9.214c0-1.734,0-3.555,0-5.442q0-1.415,0-2.879a6.193,6.193,0,0,1,.021-.744,1.61,1.61,0,0,1,.29-.71,1.647,1.647,0,0,1,2.678,0,1.612,1.612,0,0,1,.289.71,6.267,6.267,0,0,1,.021.744q0,1.461,0,2.878c0,1.887,0,3.706,0,5.44-.006,3.466-.012,6.589-.016,9.213s0,4.742-.038,6.215a1.578,1.578,0,0,1-.416.882,1.551,1.551,0,0,1-.594.4,1.532,1.532,0,0,1-.43.1c-.1,0-.15,0-.15,0"
              transform="translate(-192.11 -189.367)"
              fill="#263238"
            />
            <g id="Group_4921" data-name="Group 4921" transform="translate(134.229 54.302)" opacity="0.3">
              <g id="Group_4920" data-name="Group 4920">
                <g id="Group_4919" data-name="Group 4919" clip-path="url(#clip-path-6)">
                  <path
                    id="Path_94745"
                    data-name="Path 94745"
                    d="M460.9,186.457a40.916,40.916,0,0,1,20.552,11.565Z"
                    transform="translate(-460.901 -186.457)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_4924" data-name="Group 4924" transform="translate(115.818 80.26)" opacity="0.3">
              <g id="Group_4923" data-name="Group 4923">
                <g id="Group_4922" data-name="Group 4922" clip-path="url(#clip-path-7)">
                  <path
                    id="Path_94746"
                    data-name="Path 94746"
                    d="M397.683,275.588a40.915,40.915,0,0,1,0,23.582Z"
                    transform="translate(-397.683 -275.588)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_4927" data-name="Group 4927" transform="translate(82.162 80.26)" opacity="0.3">
              <g id="Group_4926" data-name="Group 4926">
                <g id="Group_4925" data-name="Group 4925" clip-path="url(#clip-path-8)">
                  <path
                    id="Path_94747"
                    data-name="Path 94747"
                    d="M282.169,275.588s3.789,9.68-.048,22.8Z"
                    transform="translate(-282.121 -275.588)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_4930" data-name="Group 4930" transform="translate(33.024 80.26)" opacity="0.3">
              <g id="Group_4929" data-name="Group 4929">
                <g id="Group_4928" data-name="Group 4928" clip-path="url(#clip-path-8)">
                  <path
                    id="Path_94748"
                    data-name="Path 94748"
                    d="M113.444,275.588s3.789,9.68-.048,22.8Z"
                    transform="translate(-113.396 -275.588)"
                  />
                </g>
              </g>
            </g>
            <g id="Group_4933" data-name="Group 4933" transform="translate(189.291 37.928)" opacity="0.3">
              <g id="Group_4932" data-name="Group 4932">
                <g id="Group_4931" data-name="Group 4931" clip-path="url(#clip-path-7)">
                  <path
                    id="Path_94749"
                    data-name="Path 94749"
                    d="M649.97,130.235a40.915,40.915,0,0,1,0,23.582Z"
                    transform="translate(-649.97 -130.235)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94750"
              data-name="Path 94750"
              d="M25.519,351.062l4.341,4.6V374.7h4.651V355.666l4.528-4.4Z"
              transform="translate(-18.087 -248.822)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94751"
              data-name="Path 94751"
              d="M25.519,351.062l13.522.141.145,0-.1.1-4.522,4.409.021-.049c0,4.393,0,9.36.007,14.6,0,1.5,0,2.982,0,4.431v.075h-.075l-4.651,0h-.073V374.7c.012-7.819.022-14.512.029-19.032l.012.03-4.309-4.634,4.373,4.574.012.012v.018c.007,4.52.017,11.213.03,19.032l-.073-.073,4.651,0-.075.075c0-1.449,0-2.929,0-4.431,0-5.24.005-10.207.007-14.6v-.029l.021-.02L39,351.22l.041.1-13.52-.261"
              transform="translate(-18.087 -248.822)"
              fill="#263238"
            />
            <path
              id="Path_94752"
              data-name="Path 94752"
              d="M45.017,366.9a18.083,18.083,0,0,1-2.338.076,18.086,18.086,0,0,1-2.338-.076,18.083,18.083,0,0,1,2.338-.076,18.08,18.08,0,0,1,2.338.076"
              transform="translate(-28.592 -259.994)"
              fill="#263238"
            />
            <path
              id="Path_94753"
              data-name="Path 94753"
              d="M559.362,211.21l4.553,4.828v59.537h4.877V216.038l4.966-4.828Z"
              transform="translate(-396.458 -149.699)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94754"
              data-name="Path 94754"
              d="M559.362,211.1l14.4-.044h.109l-.079.076-4.96,4.834.016-.038c.006,13.727.014,33.136.023,54.629v4.983h-5.028v-.075c.02-26.727.037-49.066.045-59.537l.008.021-4.53-4.849,4.575,4.807.008.009v.013c.008,10.471.025,32.81.045,59.537l-.075-.075h4.877l-.076.076v-4.908c.009-21.494.017-40.9.023-54.629v-.022l.016-.015,4.972-4.822.031.076-14.4-.044"
              transform="translate(-396.458 -149.591)"
              fill="#263238"
            />
            <path
              id="Path_94755"
              data-name="Path 94755"
              d="M579.808,227.828a39.72,39.72,0,0,1-4.9,0,39.72,39.72,0,0,1,4.9,0"
              transform="translate(-407.475 -161.424)"
              fill="#263238"
            />
            <path
              id="Path_94756"
              data-name="Path 94756"
              d="M312.625,354.007c0,.042-6.83.032-15.253-.022s-15.254-.131-15.254-.173,6.829-.032,15.255.022,15.253.131,15.252.173"
              transform="translate(-199.956 -250.752)"
              fill="#263238"
            />
          </g>
        </g>
        <g id="Group_4952" data-name="Group 4952">
          <g id="Group_4951" data-name="Group 4951" clip-path="url(#clip-path)">
            <path
              id="Path_94757"
              data-name="Path 94757"
              d="M523.232,63.863a19.125,19.125,0,0,0-.952,12.486,18.827,18.827,0,0,0,1.275,3.4.736.736,0,0,0,1,.261.744.744,0,0,0,.261-1A17.507,17.507,0,0,1,524.49,64.6a.752.752,0,0,0-.261-1,.733.733,0,0,0-1,.261"
              transform="translate(-369.729 -45.013)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94758"
              data-name="Path 94758"
              d="M526.343,94.329l3.05-4.454a.733.733,0,0,0-.261-1,.747.747,0,0,0-1,.261l-3.049,4.454a.732.732,0,0,0,.261,1,.746.746,0,0,0,1-.261"
              transform="translate(-372.093 -62.927)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94759"
              data-name="Path 94759"
              d="M522.893,88.828a26.6,26.6,0,0,0,4.073-.049.728.728,0,0,0,0-1.456,26.6,26.6,0,0,1-4.073.049.732.732,0,0,0-.728.728.745.745,0,0,0,.728.728"
              transform="translate(-370.094 -61.891)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94760"
              data-name="Path 94760"
              d="M525.453,70.344a45.206,45.206,0,0,1,2.831,4.626.735.735,0,0,0,1,.261.744.744,0,0,0,.261-1,45.187,45.187,0,0,0-2.831-4.626.728.728,0,1,0-1.257.735"
              transform="translate(-372.35 -49.079)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94761"
              data-name="Path 94761"
              d="M410.365,384.594l-.787,8.959s8.842,3.809,8.858,5.479l-17.426-.9.654-14.01Z"
              transform="translate(-284.223 -272.252)"
              fill="#455a64"
            />
            <path
              id="Path_94762"
              data-name="Path 94762"
              d="M410.741,412.906a.712.712,0,0,0-.534.787.685.685,0,0,0,.775.536.751.751,0,0,0,.561-.836.71.71,0,0,0-.868-.468"
              transform="translate(-290.736 -292.639)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94763"
              data-name="Path 94763"
              d="M400.9,430.179l.062-1.357,16.754.974s.758.378.642.92Z"
              transform="translate(-284.147 -303.936)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94764"
              data-name="Path 94764"
              d="M427.431,416.029c-.007.085-.435.1-.868.354s-.674.6-.751.56c-.078-.02.075-.523.6-.818s1.036-.172,1.014-.1"
              transform="translate(-301.789 -294.801)"
              fill="#263238"
            />
            <path
              id="Path_94765"
              data-name="Path 94765"
              d="M434.6,419.081c.015.083-.361.209-.668.554s-.411.721-.5.713c-.081.009-.114-.492.277-.914s.889-.435.887-.354"
              transform="translate(-307.171 -297.003)"
              fill="#263238"
            />
            <path
              id="Path_94766"
              data-name="Path 94766"
              d="M440.523,423.381c-.078.014-.175-.417.068-.872s.65-.62.683-.547-.227.309-.42.686-.245.729-.33.733"
              transform="translate(-312.168 -299.062)"
              fill="#263238"
            />
            <path
              id="Path_94767"
              data-name="Path 94767"
              d="M426.833,408.324c-.04.076-.424-.062-.907-.054s-.866.14-.906.065.336-.36.9-.361.954.286.908.351"
              transform="translate(-301.239 -289.159)"
              fill="#263238"
            />
            <path
              id="Path_94768"
              data-name="Path 94768"
              d="M403.275,60.718a4.544,4.544,0,0,1-.864,2.975,4.034,4.034,0,0,1-2.454,1,5.446,5.446,0,0,1-1.516.047,2.287,2.287,0,0,1-1.348-.642,1.461,1.461,0,0,1-.372-1.4,1.583,1.583,0,0,1-1.514-.6,2.008,2.008,0,0,1-.34-1.626,2.769,2.769,0,0,1,.866-1.448,1.529,1.529,0,0,1-.432-1.779,1.4,1.4,0,0,1,1.81-1.078,1.11,1.11,0,0,1,.674-1.72l1.123-.218Z"
              transform="translate(-279.837 -38.437)"
              fill="#263238"
            />
            <path
              id="Path_94769"
              data-name="Path 94769"
              d="M445.639,44.351a1.643,1.643,0,0,0-1.483-2.408l-2.559.586,1.1,4.315a2.207,2.207,0,0,0,.541,1.137,1.493,1.493,0,0,0,1.132.321,2.249,2.249,0,0,0,1.266-3.951"
              transform="translate(-312.99 -29.727)"
              fill="#263238"
            />
            <path
              id="Path_94770"
              data-name="Path 94770"
              d="M417.673,54.918a3.99,3.99,0,0,1-4.28-3.583l-1.293-13c-.458-3.48,1.185-6.648,4.676-7.01l.325-.015a6.275,6.275,0,0,1,6.509,5.482c.381,3.019.763,6.359.816,7.985a4.043,4.043,0,0,1-3.232,4.041c-.008,0,.388,2.218.476,3.2.2,2.212-1.782,2.719-4,2.891"
              transform="translate(-292.03 -22.191)"
              fill="#b56760"
            />
            <path
              id="Path_94771"
              data-name="Path 94771"
              d="M432.461,88.563a8.137,8.137,0,0,1-4.439-1.1,4.331,4.331,0,0,0,4.58,1.95Z"
              transform="translate(-303.369 -61.992)"
              fill="#8a4a43"
            />
            <path
              id="Path_94772"
              data-name="Path 94772"
              d="M447.014,59.052a.481.481,0,0,1-.442.506.46.46,0,0,1-.515-.411.481.481,0,0,1,.441-.506.46.46,0,0,1,.515.411"
              transform="translate(-316.151 -41.561)"
              fill="#263238"
            />
            <path
              id="Path_94773"
              data-name="Path 94773"
              d="M444.683,53.717c-.057.065-.433-.188-.95-.163s-.884.3-.943.239c-.029-.027.027-.143.187-.27a1.321,1.321,0,0,1,.752-.269,1.267,1.267,0,0,1,.759.208c.165.115.224.226.2.255"
              transform="translate(-313.83 -37.744)"
              fill="#263238"
            />
            <path
              id="Path_94774"
              data-name="Path 94774"
              d="M430.282,60.161a.481.481,0,0,1-.441.506.46.46,0,0,1-.515-.411.481.481,0,0,1,.441-.506.46.46,0,0,1,.515.411"
              transform="translate(-304.292 -42.347)"
              fill="#263238"
            />
            <path
              id="Path_94775"
              data-name="Path 94775"
              d="M427.323,55.218c-.057.065-.433-.188-.95-.163s-.884.3-.943.24c-.029-.027.026-.143.187-.27a1.318,1.318,0,0,1,.751-.269,1.269,1.269,0,0,1,.76.208c.164.115.223.226.2.255"
              transform="translate(-301.527 -38.808)"
              fill="#263238"
            />
            <path
              id="Path_94776"
              data-name="Path 94776"
              d="M437.872,59.244a3.416,3.416,0,0,1,.833-.189c.132-.02.255-.052.273-.143a.674.674,0,0,0-.106-.387l-.436-.988a17.263,17.263,0,0,1-1-2.59,17.368,17.368,0,0,1,1.2,2.5q.22.521.42.995a.764.764,0,0,1,.092.515.33.33,0,0,1-.208.2.889.889,0,0,1-.223.041,3.363,3.363,0,0,1-.851.04"
              transform="translate(-310.042 -38.944)"
              fill="#263238"
            />
            <path
              id="Path_94777"
              data-name="Path 94777"
              d="M434.957,73.191a.665.665,0,0,0-.486-.51.678.678,0,0,0-.429.055.536.536,0,0,0-.271.326.242.242,0,0,0-.009.069.317.317,0,0,0-.036.125.834.834,0,0,0,.255.718.6.6,0,0,0,.429.148.562.562,0,0,0,.383-.195.851.851,0,0,0,.165-.737"
              transform="translate(-307.409 -51.505)"
              fill="#8a4a43"
            />
            <path
              id="Path_94778"
              data-name="Path 94778"
              d="M433.586,71.167c.084-.009.11.552.609.929s1.093.288,1.1.366c.009.035-.128.115-.381.133a1.4,1.4,0,0,1-.918-.274,1.219,1.219,0,0,1-.474-.781c-.032-.234.025-.375.063-.374"
              transform="translate(-307.261 -50.441)"
              fill="#263238"
            />
            <path
              id="Path_94779"
              data-name="Path 94779"
              d="M426.2,49.358c-.045.143-.564.1-1.167.2s-1.087.288-1.173.166c-.039-.059.041-.195.232-.34a2.057,2.057,0,0,1,1.783-.278c.227.08.344.185.325.253"
              transform="translate(-300.41 -34.739)"
              fill="#263238"
            />
            <path
              id="Path_94780"
              data-name="Path 94780"
              d="M444.021,47.135c-.086.121-.447.016-.876.029s-.788.115-.876,0c-.039-.058.013-.178.166-.3a1.268,1.268,0,0,1,1.412-.02c.157.114.212.232.174.292"
              transform="translate(-313.457 -33.056)"
              fill="#263238"
            />
            <path
              id="Path_94781"
              data-name="Path 94781"
              d="M408.075,63.565c-.059-.022-2.343-.58-2.166,1.717a1.732,1.732,0,0,0,2.445,1.579c0-.066-.28-3.3-.28-3.3"
              transform="translate(-287.689 -45)"
              fill="#b56760"
            />
            <path
              id="Path_94782"
              data-name="Path 94782"
              d="M409.024,67.92c-.01-.007-.038.031-.1.067a.4.4,0,0,1-.3.029.993.993,0,0,1-.527-.853,1.331,1.331,0,0,1,.068-.582.465.465,0,0,1,.277-.33.2.2,0,0,1,.245.091c.036.062.025.109.037.112s.046-.042.021-.136a.257.257,0,0,0-.1-.137.3.3,0,0,0-.226-.041.563.563,0,0,0-.391.388,1.361,1.361,0,0,0-.089.651,1.042,1.042,0,0,0,.66.947.429.429,0,0,0,.361-.089c.067-.062.075-.114.067-.118"
              transform="translate(-289.127 -46.875)"
              fill="#8a4a43"
            />
            <path
              id="Path_94783"
              data-name="Path 94783"
              d="M410.491,44.436a1.757,1.757,0,0,0-1.08-.331,5.368,5.368,0,0,0-2.874.7,2.562,2.562,0,0,0-1.105,1.086,1.277,1.277,0,0,0,.218,1.455,1.615,1.615,0,0,0,1.521,2.817,1.048,1.048,0,0,0,1.644.665,1.648,1.648,0,0,0,.384-.405,3.352,3.352,0,0,0,.176-2.366,1.531,1.531,0,0,0,1.127-.717,2.969,2.969,0,0,0,.42-1.306,1.8,1.8,0,0,0-.429-1.6"
              transform="translate(-287.048 -31.257)"
              fill="#263238"
            />
            <path
              id="Path_94784"
              data-name="Path 94784"
              d="M406.968,22.812c-.154.045-3.831,1.464-4.366,4.437s-.128,3.8-.128,3.8a1.875,1.875,0,0,0-.347.4c-.131.209.232,1.068.713.894,3.474-2.191,7.847-3,12.768-3.573l3.745-.6c1.069-.032,1.4-.365.914-.564-.369-.152-3.526-.91-3.526-.91l-.649-.341-1.865-1.92s.147-.327-.268-.52-3.952-2-6.992-1.107"
              transform="translate(-284.996 -15.995)"
              fill="#377289"
            />
            <g id="Group_4938" data-name="Group 4938" transform="translate(118.743 7.582)" opacity="0.3">
              <g id="Group_4937" data-name="Group 4937">
                <g id="Group_4936" data-name="Group 4936" clip-path="url(#clip-path-12)">
                  <path
                    id="Path_94785"
                    data-name="Path 94785"
                    d="M407.729,27.451s4-1.266,5.967-.653l-.229-.076,1.847.508a1.1,1.1,0,0,0,.935-.027,8.217,8.217,0,0,0-2.849-1.024c-1.8-.328-4.56-.177-5.671,1.272"
                    transform="translate(-407.729 -26.033)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94786"
              data-name="Path 94786"
              d="M407.535,27.337a2.961,2.961,0,0,1,.332-.295,5.156,5.156,0,0,1,1.032-.632,6.139,6.139,0,0,1,1.72-.51,5.147,5.147,0,0,1,2.188.1,9.266,9.266,0,0,1,1.071.389c.337.146.668.27.974.42a5.561,5.561,0,0,0,.87.387,1.787,1.787,0,0,0,.816.043c1.009-.134,1.6-.433,1.543-.491a.163.163,0,0,1-.069.091,1.318,1.318,0,0,1-.294.15,5.543,5.543,0,0,1-1.174.294,1.827,1.827,0,0,1-.836-.037,5.51,5.51,0,0,1-.88-.385c-.3-.146-.635-.27-.972-.415a9.366,9.366,0,0,0-1.064-.386,5.155,5.155,0,0,0-2.163-.107,6.221,6.221,0,0,0-1.709.493,5.435,5.435,0,0,0-1.035.611c-.114.085-.2.155-.257.2s-.09.071-.09.071"
              transform="translate(-288.848 -18.308)"
              fill="#263238"
            />
            <g id="Group_4941" data-name="Group 4941" transform="translate(117.354 10.024)" opacity="0.3">
              <g id="Group_4940" data-name="Group 4940">
                <g id="Group_4939" data-name="Group 4939" clip-path="url(#clip-path-13)">
                  <path
                    id="Path_94787"
                    data-name="Path 94787"
                    d="M403.027,36.886a9.3,9.3,0,0,0,1.97-.652c.752-.284,1.5-.532,2.241-.852a3.445,3.445,0,0,0,1.548-.962.86.86,0,0,1-.143.833,4.139,4.139,0,0,1-1.166.734,21.827,21.827,0,0,1-4.518,1.581,5.11,5.11,0,0,1,.068-.682"
                    transform="translate(-402.958 -34.42)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94788"
              data-name="Path 94788"
              d="M402.462,34.373c-.436.27-.7.448-.657.847.037.332.163.726.5.736a.816.816,0,0,0,.3-.066l4.923-1.767a.761.761,0,0,0,.435-.284.519.519,0,0,0-.278-.672,1.423,1.423,0,0,0-.8-.058,17.048,17.048,0,0,0-4.414,1.265"
              transform="translate(-284.783 -23.446)"
              fill="#377289"
            />
            <path
              id="Path_94789"
              data-name="Path 94789"
              d="M408.664,33.469a.494.494,0,0,0,.1-.241.644.644,0,0,0-.3-.61,1.6,1.6,0,0,0-1.053-.076c-.4.08-.848.174-1.309.289a13.606,13.606,0,0,0-2.306.768c-.285.126-.508.242-.663.323l-.177.1a.275.275,0,0,1-.064.029.3.3,0,0,1,.057-.04l.171-.106c.151-.088.373-.211.656-.342a12.443,12.443,0,0,1,2.309-.789c.463-.115.907-.207,1.313-.285a1.616,1.616,0,0,1,1.088.1.665.665,0,0,1,.3.651.437.437,0,0,1-.078.188.236.236,0,0,1-.05.05"
              transform="translate(-285.559 -22.99)"
              fill="#263238"
            />
            <path
              id="Path_94790"
              data-name="Path 94790"
              d="M437.96,31.065l-3.2.4.4.86,3.46-.582Z"
              transform="translate(-308.143 -22.018)"
              fill="#fff"
            />
            <path
              id="Path_94791"
              data-name="Path 94791"
              d="M430.6,38.892c-.005-.017,1.388-.429,3.111-.92s3.124-.875,3.129-.859-1.388.429-3.112.92-3.124.875-3.129.859"
              transform="translate(-305.197 -26.305)"
              fill="#263238"
            />
            <path
              id="Path_94792"
              data-name="Path 94792"
              d="M464.8,69.56l-7.92,8.59-9.491,1.834,2.767,8.242,8.654-2.959a7.832,7.832,0,0,0,3.939-2.953c2.051-3.033,5.548-9.337,5.548-9.337Z"
              transform="translate(-317.097 -49.302)"
              fill="#377289"
            />
            <path
              id="Path_94793"
              data-name="Path 94793"
              d="M489.084,84.744l4.558,4.943,1.329-2.213-4.206-4.554Z"
              transform="translate(-346.647 -58.771)"
              fill="#fff"
            />
            <path
              id="Path_94794"
              data-name="Path 94794"
              d="M509.021,54.593s3.745-3.033,4.514-3.114,1.295-.283,1.82.162a8.506,8.506,0,0,1,1.689,2.151,3.3,3.3,0,0,1-.147,1.3A3.757,3.757,0,0,0,515.982,54c-.288-.22-1.4-.538-1.56.514a5.1,5.1,0,0,0,0,1.74l.62-.086.408,1.288-4.1.174-1.082,2.045-3.091-3.011Z"
              transform="translate(-359.471 -36.404)"
              fill="#b56760"
            />
            <g id="Group_4944" data-name="Group 4944" transform="translate(130.407 30.682)" opacity="0.3">
              <g id="Group_4943" data-name="Group 4943">
                <g id="Group_4942" data-name="Group 4942" clip-path="url(#clip-path-14)">
                  <path
                    id="Path_94795"
                    data-name="Path 94795"
                    d="M447.779,105.354a6.783,6.783,0,0,1,3.123,3.22,8.73,8.73,0,0,1,.37,4.735Z"
                    transform="translate(-447.779 -105.354)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94796"
              data-name="Path 94796"
              d="M403.2,239c-1.367,6.38-2.875,26.087-2.875,26.087l9.629.318,3.471-19.884s1.565-6.975,2.523-10.825c.853-3.429,3.724-29.89,3.724-29.89l-16.678.388a22.606,22.606,0,0,0-1.067,5.583,25.161,25.161,0,0,0-.072,6.782,6.9,6.9,0,0,0,1.039,2.7l1.239,2.67Z"
              transform="translate(-283.738 -145.157)"
              fill="#377289"
            />
            <rect
              id="Rectangle_2159"
              data-name="Rectangle 2159"
              width="2.98"
              height="4.978"
              transform="translate(165.435 32.055)"
              fill="#e0e0e0"
            />
            <rect
              id="Rectangle_2160"
              data-name="Rectangle 2160"
              width="9.278"
              height="1.358"
              transform="translate(162.493 36.884)"
              fill="#e0e0e0"
            />
            <rect
              id="Rectangle_2161"
              data-name="Rectangle 2161"
              width="3.885"
              height="2.074"
              transform="translate(158.238 24.21)"
              fill="#e0e0e0"
            />
            <rect
              id="Rectangle_2162"
              data-name="Rectangle 2162"
              width="9.95"
              height="12.294"
              transform="translate(161.821 19.95)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94797"
              data-name="Path 94797"
              d="M568.618,81.764a2.866,2.866,0,1,1-2.866-2.866,2.866,2.866,0,0,1,2.866,2.866"
              transform="translate(-398.955 -55.92)"
              fill="#fff"
            />
            <path
              id="Path_94798"
              data-name="Path 94798"
              d="M568.4,81.58c-.022,0-.008-.255-.124-.691a2.858,2.858,0,0,0-1.047-1.536,2.8,2.8,0,0,0-2.643-.386,2.79,2.79,0,0,0-1.289.972,2.771,2.771,0,0,0,0,3.283,2.793,2.793,0,0,0,1.289.972,2.794,2.794,0,0,0,2.643-.386,2.857,2.857,0,0,0,1.047-1.536c.115-.436.1-.693.124-.691a.7.7,0,0,1,.009.184,1.289,1.289,0,0,1-.014.223,1.978,1.978,0,0,1-.051.3A2.853,2.853,0,0,1,567.3,83.91a2.9,2.9,0,0,1-1.23.56,2.942,2.942,0,0,1-1.548-.119,2.943,2.943,0,0,1-1.376-1.024,2.932,2.932,0,0,1,0-3.494,2.947,2.947,0,0,1,1.375-1.024,2.941,2.941,0,0,1,1.548-.119,2.9,2.9,0,0,1,1.23.56,2.853,2.853,0,0,1,1.038,1.621,1.963,1.963,0,0,1,.05.3,1.273,1.273,0,0,1,.014.223.7.7,0,0,1-.009.184"
              transform="translate(-398.734 -55.737)"
              fill="#263238"
            />
            <path
              id="Path_94799"
              data-name="Path 94799"
              d="M555.445,68.316a1.265,1.265,0,0,1,.173-.008l.522-.008,2.006-.02,7.249-.039h.076v.076c0,3.518.01,7.746.015,12.293.02-.019-.173.174-.093.092H562.74l-2.559,0-4.736-.007h-.082V80.61c.017-3.662.031-6.742.041-8.916.009-1.069.016-1.915.022-2.5q.006-.421.01-.656a1.871,1.871,0,0,1,.009-.219.368.368,0,0,1,0,.062c0,.046,0,.1,0,.175q0,.241.01.672c.005.592.013,1.444.021,2.521.01,2.161.024,5.223.041,8.863l-.081-.081,4.736-.007,2.559,0h2.654c.082-.081-.109.111-.089.09.006-4.547.011-8.775.015-12.293l.076.076-7.2-.039-2.023-.02-.537-.008a1.751,1.751,0,0,1-.19-.008"
              transform="translate(-393.624 -48.366)"
              fill="#263238"
            />
            <path
              id="Path_94800"
              data-name="Path 94800"
              d="M566.988,126.426c0,.05-2.053.091-4.584.091s-4.584-.041-4.584-.091,2.052-.091,4.584-.091,4.584.041,4.584.091"
              transform="translate(-395.365 -89.542)"
              fill="#263238"
            />
            <path
              id="Path_94801"
              data-name="Path 94801"
              d="M571.713,83.822l.8.248.3-2.124Z"
              transform="translate(-405.212 -58.081)"
              fill="#377289"
            />
            <path
              id="Path_94802"
              data-name="Path 94802"
              d="M533.053,76.119a4.541,4.541,0,0,0,1.529-2.722,24.648,24.648,0,0,0,.78-5,17.42,17.42,0,0,0-.88-6.771,3.568,3.568,0,0,0-.746-1.222,1.459,1.459,0,0,0-1.564-.561c-1.1.453-.278,1.734,0,2.122a9.506,9.506,0,0,1,.964,3.31,33.454,33.454,0,0,1-.711,8.653A8.479,8.479,0,0,0,532,75.716s.764.532.849.549a.711.711,0,0,0,.2-.146"
              transform="translate(-376.792 -42.363)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94803"
              data-name="Path 94803"
              d="M532.872,75.938a.319.319,0,0,1-.057.058c-.02.018-.045.04-.077.065a.2.2,0,0,1-.06.036.23.23,0,0,1-.076-.03c-.187-.1-.45-.276-.794-.508l-.013-.009v-.014a7.156,7.156,0,0,1,.224-1.132c.11-.429.266-.9.359-1.438a35.492,35.492,0,0,0,.461-3.819c.053-.726.091-1.494.1-2.295a13.538,13.538,0,0,0-.156-2.484,10.017,10.017,0,0,0-.329-1.267,7.681,7.681,0,0,0-.5-1.239,2.982,2.982,0,0,1-.581-1.288.893.893,0,0,1,.209-.717.977.977,0,0,1,.31-.223,1,1,0,0,1,.365-.112,1.36,1.36,0,0,1,.922.273,3.055,3.055,0,0,1,.67.655A4.911,4.911,0,0,1,534.6,62.1a16.637,16.637,0,0,1,.6,3.344,22.015,22.015,0,0,1-.3,5.73c-.144.806-.31,1.528-.487,2.154a6.668,6.668,0,0,1-.6,1.557,3.587,3.587,0,0,1-.662.821c-.088.082-.158.142-.206.181a.435.435,0,0,1-.075.056c-.005-.006.091-.088.26-.259a3.811,3.811,0,0,0,.632-.828,6.811,6.811,0,0,0,.57-1.55c.169-.625.326-1.346.463-2.15a22.321,22.321,0,0,0,.265-5.7,16.676,16.676,0,0,0-.608-3.313,4.783,4.783,0,0,0-.729-1.6,2.924,2.924,0,0,0-.636-.621,1.212,1.212,0,0,0-.819-.247.85.85,0,0,0-.315.1.828.828,0,0,0-.265.189.741.741,0,0,0-.171.6,2.867,2.867,0,0,0,.558,1.224,7.675,7.675,0,0,1,.518,1.273,10.144,10.144,0,0,1,.331,1.286,13.527,13.527,0,0,1,.15,2.512c-.01.805-.052,1.575-.108,2.3a34.066,34.066,0,0,1-.494,3.828c-.1.543-.264,1.017-.378,1.44a7.535,7.535,0,0,0-.242,1.112l-.012-.022c.338.239.6.419.776.529a.474.474,0,0,0,.059.03.216.216,0,0,0,.051-.029l.08-.059a.356.356,0,0,1,.066-.047"
              transform="translate(-376.612 -42.182)"
              fill="#263238"
            />
            <path
              id="Path_94804"
              data-name="Path 94804"
              d="M407.16,95.319l-.276,1.647a18.118,18.118,0,0,0-3.008,10.721c.4,6.7,1.016,19.306,1.016,19.306l-.029.174a2.869,2.869,0,0,0,.893,2.582l.012.011,15.227.328a3.524,3.524,0,0,0,2.912-3.944l-2.4-17.883L416.882,97.7l-.434-.905-.52-.314Z"
              transform="translate(-286.234 -67.559)"
              fill="#377289"
            />
            <path
              id="Path_94805"
              data-name="Path 94805"
              d="M404.318,144.1l.366,6.737,17.9-.126-.387-6.516Z"
              transform="translate(-286.568 -102.134)"
              fill="#fff"
            />
            <path
              id="Path_94806"
              data-name="Path 94806"
              d="M421.867,99.055a3.287,3.287,0,0,1-.031.452,11.219,11.219,0,0,0-.038,1.227l0,.069-.069-.014c-.894-.179-2.107-.391-3.448-.569-1.418-.19-2.708-.324-3.64-.436-.466-.055-.843-.1-1.1-.139a2.135,2.135,0,0,1-.4-.072,2.09,2.09,0,0,1,.408.013l1.108.091c.935.084,2.227.2,3.65.392,1.344.178,2.56.407,3.451.61l-.068.055a8.536,8.536,0,0,1,.088-1.236,1.968,1.968,0,0,1,.093-.445"
              transform="translate(-292.818 -70.207)"
              fill="#263238"
            />
            <path
              id="Path_94807"
              data-name="Path 94807"
              d="M452.4,113.434a2.6,2.6,0,0,1-.193-.408l-.482-1.13c-.405-.955-.972-2.272-1.613-3.718l-1.665-3.7-.5-1.122a2.592,2.592,0,0,1-.167-.42,2.626,2.626,0,0,1,.22.394c.133.258.32.634.547,1.1.453.934,1.063,2.233,1.706,3.681s1.194,2.773,1.574,3.739c.19.483.34.876.438,1.149a2.572,2.572,0,0,1,.139.43"
              transform="translate(-317.37 -72.96)"
              fill="#263238"
            />
            <path
              id="Path_94808"
              data-name="Path 94808"
              d="M425.012,212.683a.735.735,0,0,1-.169.03l-.487.056c-.423.046-1.037.1-1.8.161-1.517.119-3.618.234-5.941.271s-4.427-.015-5.947-.087c-.76-.035-1.375-.072-1.8-.1l-.488-.041a.736.736,0,0,1-.17-.024.724.724,0,0,1,.172-.006l.49.012,1.8.057c1.52.044,3.621.078,5.941.043s4.418-.136,5.936-.227l1.8-.112.489-.028a.74.74,0,0,1,.172,0"
              transform="translate(-289.33 -150.74)"
              fill="#263238"
            />
            <path
              id="Path_94809"
              data-name="Path 94809"
              d="M512.315,110.862l2.233-2.42a1.04,1.04,0,0,1,.764-.335h.777s-.244,1.271.674,1.377a1.19,1.19,0,0,0,1.239-.726s1.374.62,1.233,1.538a2.1,2.1,0,0,1-.565,1.271,1.819,1.819,0,0,1,.07,1.342,2.109,2.109,0,0,1-.848.847,4.294,4.294,0,0,1-.989.918,25.291,25.291,0,0,1-3.557.368l-2.731,2.8-2.01-4.411Z"
              transform="translate(-360.485 -76.624)"
              fill="#b56760"
            />
            <g id="Group_4947" data-name="Group 4947" transform="translate(120.808 38.245)" opacity="0.3">
              <g id="Group_4946" data-name="Group 4946">
                <g id="Group_4945" data-name="Group 4945" clip-path="url(#clip-path-15)">
                  <path
                    id="Path_94810"
                    data-name="Path 94810"
                    d="M414.822,131.322s-.171,1.394,1.678,4.169,4.836,7.619,8.932,8.679,4.873.723,4.873.723l-.676-5.04Z"
                    transform="translate(-414.818 -131.322)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94811"
              data-name="Path 94811"
              d="M412.352,109.613a9.823,9.823,0,0,0,3.145,6.627c2.853,2.677,8.4,8.519,11.989,8.6,5.117.111,15.7-8.956,15.7-8.956l-2.189-5.729-13.122,6.291-6.739-8.786a6.391,6.391,0,0,0-4.652-2.533,4,4,0,0,0-3.1.978,4.441,4.441,0,0,0-1.027,3.511"
              transform="translate(-292.254 -74.501)"
              fill="#377289"
            />
            <path
              id="Path_94812"
              data-name="Path 94812"
              d="M487.754,130.56l2.667,7.234,2.231-1.58-2.385-6.833Z"
              transform="translate(-345.705 -91.702)"
              fill="#fff"
            />
            <path
              id="Path_94813"
              data-name="Path 94813"
              d="M412.326,109.515s-.018-.15-.022-.44a6.312,6.312,0,0,1,.107-1.278,3.4,3.4,0,0,1,.927-1.822,3.46,3.46,0,0,1,1.1-.705,4.382,4.382,0,0,1,1.453-.291,6.163,6.163,0,0,1,3.312.84,6.629,6.629,0,0,1,1.528,1.218c.233.246.442.523.654.8l.647.841L427.9,116.3l-.083-.02,13.119-6.3.074-.036.029.077,1.56,4.083.629,1.646.019.05-.041.034a76.714,76.714,0,0,1-6.618,4.982c-1.115.737-2.229,1.433-3.36,2.044a20.458,20.458,0,0,1-3.442,1.531,8.569,8.569,0,0,1-1.781.387,5.212,5.212,0,0,1-.9,0,5.333,5.333,0,0,1-.864-.189,11.7,11.7,0,0,1-3-1.545A40.8,40.8,0,0,1,418.6,119.2c-.673-.629-1.3-1.238-1.9-1.814l-.868-.838c-.281-.269-.559-.527-.81-.794A10.119,10.119,0,0,1,413,112.616a9.278,9.278,0,0,1-.605-2.281c-.036-.268-.052-.473-.057-.611s-.008-.209-.008-.209.01.07.024.208.031.342.072.608a9.54,9.54,0,0,0,.629,2.263,10.139,10.139,0,0,0,2.032,3.1c.251.263.528.518.812.787l.873.834c.6.573,1.23,1.179,1.9,1.805a41.008,41.008,0,0,0,4.644,3.824,11.6,11.6,0,0,0,2.966,1.521,5.19,5.19,0,0,0,.842.183,5.125,5.125,0,0,0,.872,0,8.448,8.448,0,0,0,1.751-.384,20.386,20.386,0,0,0,3.416-1.525c1.125-.61,2.235-1.3,3.346-2.041a77.159,77.159,0,0,0,6.6-4.975l-.022.085-.629-1.646-1.56-4.083.1.041-13.125,6.284-.05.024-.033-.044-5.846-7.64-.645-.843a10.188,10.188,0,0,0-.645-.792,6.551,6.551,0,0,0-1.5-1.206,6.091,6.091,0,0,0-3.261-.844,4.324,4.324,0,0,0-1.429.277,3.41,3.41,0,0,0-1.086.684,3.366,3.366,0,0,0-.928,1.785,6.67,6.67,0,0,0-.127,1.269c0,.145,0,.255,0,.329s0,.112,0,.112"
              transform="translate(-292.228 -74.403)"
              fill="#263238"
            />
            <path
              id="Path_94814"
              data-name="Path 94814"
              d="M464.948,147.023a26.633,26.633,0,0,1,.2-2.683,7.741,7.741,0,0,1-.2,2.683"
              transform="translate(-329.53 -102.304)"
              fill="#263238"
            />
            <g id="Group_4950" data-name="Group 4950" transform="translate(120.739 29.095)" opacity="0.3">
              <g id="Group_4949" data-name="Group 4949">
                <g id="Group_4948" data-name="Group 4948" clip-path="url(#clip-path-16)">
                  <path
                    id="Path_94815"
                    data-name="Path 94815"
                    d="M414.58,100.222c-.052.016,8.747,1.78,8.747,1.78l-.437-2.1-.125,1.433s-7.507-1.324-8.185-1.117"
                    transform="translate(-414.58 -99.906)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94816"
              data-name="Path 94816"
              d="M404.739,155.333a.747.747,0,0,1-.017-.174c0-.13-.011-.295-.019-.5-.011-.432-.037-1.057-.077-1.829-.075-1.544-.239-3.673-.432-6.025s-.34-4.486-.388-6.033c-.026-.774-.035-1.4-.029-1.833,0-.2.007-.368.009-.5a.745.745,0,0,1,.014-.174.769.769,0,0,1,.017.174c.005.13.012.3.02.5.011.432.037,1.057.077,1.829.075,1.544.239,3.673.432,6.026s.34,4.485.388,6.032c.026.774.035,1.4.029,1.833,0,.2-.007.368-.009.5a.755.755,0,0,1-.013.174"
              transform="translate(-286.183 -98.002)"
              fill="#263238"
            />
          </g>
        </g>
        <g id="Group_4972" data-name="Group 4972">
          <g id="Group_4971" data-name="Group 4971" clip-path="url(#clip-path)">
            <path
              id="Path_94817"
              data-name="Path 94817"
              d="M210.519,392.323l8.214-2.81s5.614,7.406,7.183,7.034l-4.875-16.122-12.978,3.852Z"
              transform="translate(-147.468 -269.634)"
              fill="#455a64"
            />
            <path
              id="Path_94818"
              data-name="Path 94818"
              d="M237.113,393.575a.688.688,0,0,1,.614-.682.661.661,0,0,1,.682.6.726.726,0,0,1-.654.719c-.338-.015-.706-.374-.64-.705"
              transform="translate(-168.054 -278.469)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94819"
              data-name="Path 94819"
              d="M250.717,380.034l-1.258.372,4.787,15.475s.529.623,1.01.389Z"
              transform="translate(-176.809 -269.356)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94820"
              data-name="Path 94820"
              d="M244.207,408.382c.078-.026-.006-.431.131-.895s.4-.77.351-.833c-.037-.068-.472.192-.627.756s.078,1.01.145.971"
              transform="translate(-172.945 -288.216)"
              fill="#263238"
            />
            <path
              id="Path_94821"
              data-name="Path 94821"
              d="M248.652,414.264c.082,0,.112-.386.365-.755s.581-.552.554-.629c-.01-.078-.488.007-.792.471s-.2.934-.126.913"
              transform="translate(-176.179 -292.619)"
              fill="#263238"
            />
            <path
              id="Path_94822"
              data-name="Path 94822"
              d="M253.912,418.794c-.005-.077-.432-.067-.8.265s-.43.753-.355.766.237-.284.545-.553.626-.4.611-.479"
              transform="translate(-179.125 -296.8)"
              fill="#263238"
            />
            <path
              id="Path_94823"
              data-name="Path 94823"
              d="M236.6,409.956c.062-.055-.156-.383-.26-.837s-.069-.843-.149-.864-.26.4-.13.931.489.828.539.77"
              transform="translate(-167.281 -289.357)"
              fill="#263238"
            />
            <path
              id="Path_94824"
              data-name="Path 94824"
              d="M177.457,343.551c-.316-.087-14.227,14.835-14.543,17.3s.19,4.616,1.834,6.007,20.361-3.162,20.361-3.162l-1.77-7.841,6.766-2.276-6.046-11.066Z"
              transform="translate(-115.405 -242.764)"
              fill="#377289"
            />
            <g id="Group_4955" data-name="Group 4955" transform="translate(58.316 103.368)" opacity="0.3">
              <g id="Group_4954" data-name="Group 4954">
                <g id="Group_4953" data-name="Group 4953" clip-path="url(#clip-path-18)">
                  <path
                    id="Path_94825"
                    data-name="Path 94825"
                    d="M200.685,355.662l-.446.479s7.974,8.364,10.42,8.25l3.915-1.326-13.245-8.128Z"
                    transform="translate(-200.239 -354.936)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94826"
              data-name="Path 94826"
              d="M201.743,389.025c.012.04-2.893.929-6.486,1.985s-6.517,1.88-6.529,1.839,2.892-.929,6.487-1.985,6.517-1.88,6.529-1.839"
              transform="translate(-133.764 -275.728)"
              fill="#263238"
            />
            <path
              id="Path_94827"
              data-name="Path 94827"
              d="M162.15,386.288l2.7,8.578s-7.811,5.629-7.465,7.263l16.82-4.645-3.665-13.538Z"
              transform="translate(-111.544 -272.128)"
              fill="#455a64"
            />
            <path
              id="Path_94828"
              data-name="Path 94828"
              d="M198.564,413.992a.712.712,0,0,1,.691.653.685.685,0,0,1-.641.691.752.752,0,0,1-.728-.7.71.71,0,0,1,.746-.645"
              transform="translate(-140.255 -293.42)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94829"
              data-name="Path 94829"
              d="M174.226,428.441l-.354-1.312-16.148,4.57s-.659.533-.429,1.037Z"
              transform="translate(-111.452 -302.736)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94830"
              data-name="Path 94830"
              d="M182.027,420.94c.025.082.446.005.924.158s.787.438.854.385c.071-.037-.187-.494-.768-.668s-1.049.055-1.01.126"
              transform="translate(-129.013 -298.213)"
              fill="#263238"
            />
            <path
              id="Path_94831"
              data-name="Path 94831"
              d="M176.666,425.4c0,.085.4.126.772.4s.557.615.638.589c.081-.008,0-.5-.468-.832s-.962-.232-.942-.153"
              transform="translate(-125.215 -301.452)"
              fill="#263238"
            />
            <path
              id="Path_94832"
              data-name="Path 94832"
              d="M172.694,430.741c.08,0,.08-.445-.255-.836s-.769-.465-.785-.387.288.253.559.579.4.659.481.644"
              transform="translate(-121.662 -304.409)"
              fill="#263238"
            />
            <path
              id="Path_94833"
              data-name="Path 94833"
              d="M180.644,413.058c.055.066.4-.152.873-.249s.875-.05.9-.133-.406-.279-.961-.157-.869.485-.811.539"
              transform="translate(-128.03 -292.354)"
              fill="#263238"
            />
            <g id="Group_4958" data-name="Group 4958" transform="translate(56.918 105.26)" opacity="0.3">
              <g id="Group_4957" data-name="Group 4957">
                <g id="Group_4956" data-name="Group 4956" clip-path="url(#clip-path-19)">
                  <path
                    id="Path_94834"
                    data-name="Path 94834"
                    d="M201.306,378.666,200.27,374.6l.771-.359-4.7-12.808-.9.781,3.7,11.733,1.323,4.9Z"
                    transform="translate(-195.439 -361.431)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94835"
              data-name="Path 94835"
              d="M159.6,311.387a6.6,6.6,0,0,0-5.294,1.135,4.857,4.857,0,0,0-1.975,4.858c.759,5.311,7.546,23.442,7.546,23.442L169.273,338c.054-.016.107-.171.077-.124-.077.124-4.765-14.98-4.647-14.8s9.478,7.735,13.365,9.124a8.617,8.617,0,0,0,9.845-2.939c.91-1.274.975-6.865,0-9.82,0,0-27.1-7.684-27.611-7.874a5.584,5.584,0,0,0-.7-.176"
              transform="translate(-107.924 -220.601)"
              fill="#377289"
            />
            <path
              id="Path_94836"
              data-name="Path 94836"
              d="M176.139,330.712a12.734,12.734,0,0,1,5.506,8.946"
              transform="translate(-124.842 -234.398)"
              fill="#377289"
            />
            <path
              id="Path_94837"
              data-name="Path 94837"
              d="M187.883,328.867s-.036.061-.113.178a5.733,5.733,0,0,1-.372.5,8.5,8.5,0,0,1-1.81,1.579,9.523,9.523,0,0,1-1.544.828,8.933,8.933,0,0,1-2.018.562,8.458,8.458,0,0,1-2.42,0,8.631,8.631,0,0,1-2.6-.826c-.434-.206-.866-.444-1.308-.684-.432-.259-.887-.5-1.328-.792s-.9-.565-1.358-.873-.922-.617-1.391-.944c-1.876-1.3-3.843-2.776-5.88-4.4l-.765-.62c-.128-.108-.252-.209-.383-.325l-.1-.094c.044,0-.116-.01.1-.01.02-.075.006-.02.011-.033l.006.027.016.059.07.239.6,1.943,1.272,4.025,2.733,8.571.023.071-.071.021-9.513,2.863-.068.02-.024-.066c-.616-1.654-1.258-3.416-1.9-5.2-1.359-3.81-2.653-7.543-3.791-11.185-.571-1.82-1.1-3.618-1.525-5.408-.116-.444-.194-.9-.276-1.345a6,6,0,0,1-.118-1.359,4.722,4.722,0,0,1,.781-2.546,6.219,6.219,0,0,1,4.327-2.563,6.744,6.744,0,0,1,2.46.122l.59.151.572.17,1.129.337,8.306,2.478,12.025,3.6,3.268.986.849.26c.191.059.288.093.288.093s-.1-.024-.292-.079l-.853-.246L182.213,318l-12.042-3.553-8.315-2.461-1.13-.335-.572-.169-.575-.146a6.616,6.616,0,0,0-2.412-.116,6.384,6.384,0,0,0-2.353.794,5.647,5.647,0,0,0-1.875,1.714,4.582,4.582,0,0,0-.755,2.473,5.854,5.854,0,0,0,.118,1.332c.082.444.159.892.275,1.335.421,1.782.955,3.578,1.526,5.4,1.139,3.638,2.434,7.369,3.792,11.178.639,1.785,1.28,3.548,1.9,5.2l-.092-.046,9.515-2.858-.049.092-2.725-8.574-1.267-4.027-.6-1.945-.07-.242-.016-.061-.008-.034c0-.022-.014.022.007-.063.154.041.1-.02.118.007l.084.076.374.318.762.62c2.032,1.625,3.995,3.1,5.865,4.407.467.327.928.645,1.386.946s.908.595,1.353.875.892.534,1.322.794c.44.241.87.479,1.3.685a8.571,8.571,0,0,0,2.57.828,8.39,8.39,0,0,0,2.4.006,8.917,8.917,0,0,0,2.005-.547,9.567,9.567,0,0,0,1.54-.813,8.672,8.672,0,0,0,1.818-1.554c.172-.211.306-.37.381-.488s.124-.171.124-.171"
              transform="translate(-107.76 -220.443)"
              fill="#263238"
            />
            <path
              id="Path_94838"
              data-name="Path 94838"
              d="M180.225,337.575a3.856,3.856,0,0,1,.774.433,11.813,11.813,0,0,1,1.679,1.33,10.431,10.431,0,0,1,1.4,1.627,6.2,6.2,0,0,1,.343.552.729.729,0,0,1,.1.217c-.021.012-.2-.273-.53-.706a13.384,13.384,0,0,0-1.413-1.579,15.808,15.808,0,0,0-1.635-1.351,7.59,7.59,0,0,1-.714-.521"
              transform="translate(-127.738 -239.262)"
              fill="#263238"
            />
            <path
              id="Path_94839"
              data-name="Path 94839"
              d="M191.227,333.985a3.259,3.259,0,0,1,.331.71,12.794,12.794,0,0,1,.818,3.68,3.26,3.26,0,0,1,0,.783,5.158,5.158,0,0,1-.108-.773c-.058-.477-.156-1.133-.314-1.848s-.349-1.35-.5-1.807a5.215,5.215,0,0,1-.229-.746"
              transform="translate(-135.534 -236.718)"
              fill="#263238"
            />
            <path
              id="Path_94840"
              data-name="Path 94840"
              d="M190.255,187.437l2.8,12.927c.476,2.2.727,5.525-2.782,6.12-3.084.287-4.467-2.406-4.91-4.458-.21-.973-.387-1.784-.415-1.883a4.01,4.01,0,0,1-4.3-2.8c-.456-1.549-1.136-4.816-1.716-7.779a6.224,6.224,0,0,1,4.427-7.186l.31-.087c3.4-.745,5.935,1.728,6.585,5.149"
              transform="translate(-126.739 -129.106)"
              fill="#ffbe9d"
            />
            <path
              id="Path_94841"
              data-name="Path 94841"
              d="M199.88,238.038a8.071,8.071,0,0,0,3.84-2.415,4.188,4.188,0,0,1-3.635,3.329Z"
              transform="translate(-141.669 -167.002)"
              fill="#eb996e"
            />
            <path
              id="Path_94842"
              data-name="Path 94842"
              d="M182.39,211.563c.074.051.5-.179,1.117-.186s1.082.2,1.147.148c.032-.024-.045-.117-.248-.214a2.123,2.123,0,0,0-.921-.183,1.937,1.937,0,0,0-.886.213c-.186.1-.246.2-.209.222"
              transform="translate(-129.265 -149.641)"
              fill="#263238"
            />
            <path
              id="Path_94843"
              data-name="Path 94843"
              d="M199.9,206.964c.088.012.367-.377.916-.657s1.053-.3,1.088-.377c.018-.035-.091-.083-.315-.079a2.149,2.149,0,0,0-.9.247,1.962,1.962,0,0,0-.7.579c-.12.173-.132.283-.09.287"
              transform="translate(-141.666 -145.901)"
              fill="#263238"
            />
            <path
              id="Path_94844"
              data-name="Path 94844"
              d="M200.478,211.873a.369.369,0,0,0,.443.262.352.352,0,0,0,.276-.423.369.369,0,0,0-.442-.262.352.352,0,0,0-.276.423"
              transform="translate(-142.086 -149.863)"
              fill="#263238"
            />
            <path
              id="Path_94845"
              data-name="Path 94845"
              d="M184.335,215.614a.368.368,0,0,0,.443.262.352.352,0,0,0,.276-.423.369.369,0,0,0-.442-.262.353.353,0,0,0-.276.423"
              transform="translate(-130.644 -152.514)"
              fill="#263238"
            />
            <path
              id="Path_94846"
              data-name="Path 94846"
              d="M193.9,212.108a3.386,3.386,0,0,0-.843.081c-.13.022-.257.031-.3-.049a.668.668,0,0,1-.021-.4q.049-.508.1-1.066a17.144,17.144,0,0,0,.132-2.75,17.163,17.163,0,0,0-.353,2.733q-.045.559-.086,1.067a.758.758,0,0,0,.074.514.33.33,0,0,0,.259.126.879.879,0,0,0,.223-.031,3.349,3.349,0,0,0,.814-.227"
              transform="translate(-136.453 -147.371)"
              fill="#263238"
            />
            <path
              id="Path_94847"
              data-name="Path 94847"
              d="M198.643,199.492c.077.11.5-.07,1.026-.15s.983-.052,1.021-.181c.015-.061-.089-.156-.289-.228a1.69,1.69,0,0,0-.811-.059,1.673,1.673,0,0,0-.748.312c-.166.131-.233.254-.2.307"
              transform="translate(-140.786 -140.939)"
              fill="#263238"
            />
            <path
              id="Path_94848"
              data-name="Path 94848"
              d="M181.526,206.5c.077.087.482-.109,1-.231s.956-.143.99-.257c.014-.054-.09-.125-.286-.167a1.848,1.848,0,0,0-.79.029,1.9,1.9,0,0,0-.722.342c-.159.129-.222.241-.187.283"
              transform="translate(-128.654 -145.874)"
              fill="#263238"
            />
            <path
              id="Path_94849"
              data-name="Path 94849"
              d="M219.492,207.8c.048-.039,2.027-1.275,2.574.944A1.718,1.718,0,0,1,220.254,211c-.02-.063-.762-3.192-.762-3.192"
              transform="translate(-155.569 -147.016)"
              fill="#ffbe9d"
            />
            <path
              id="Path_94850"
              data-name="Path 94850"
              d="M223.154,211.673c.008-.01.045.017.118.031a.4.4,0,0,0,.29-.066.985.985,0,0,0,.231-.967,1.313,1.313,0,0,0-.245-.527.459.459,0,0,0-.364-.225.2.2,0,0,0-.2.162c-.015.069.01.11,0,.117s-.057-.025-.061-.121a.253.253,0,0,1,.053-.16.3.3,0,0,1,.2-.109.559.559,0,0,1,.489.244,1.352,1.352,0,0,1,.287.585,1.033,1.033,0,0,1-.327,1.1.426.426,0,0,1-.368.029c-.082-.038-.106-.085-.1-.091"
              transform="translate(-157.999 -148.702)"
              fill="#eb996e"
            />
            <path
              id="Path_94851"
              data-name="Path 94851"
              d="M200.156,223.15c-.036-.052.376-.272.6-.746a2.563,2.563,0,0,0,.191-.648c.032-.176.061-.288.086-.285s.041.114.041.3a1.685,1.685,0,0,1-.155.713,1.366,1.366,0,0,1-.482.554c-.157.1-.271.132-.281.114"
              transform="translate(-141.863 -156.972)"
              fill="#263238"
            />
            <path
              id="Path_94852"
              data-name="Path 94852"
              d="M167.56,183.457a.619.619,0,0,0-.262.559.727.727,0,0,0,.647.6.926.926,0,0,0,.819-.4,1.322,1.322,0,0,0-.22.335.586.586,0,0,0,.995.6l.007-.01a5.817,5.817,0,0,1,.365.47v0l.024.03c.073.1.147.2.233.291v-.007l1.073,1.314c-.139-.928-.481-2.211-.62-3.139a5.229,5.229,0,0,0,3.307-2.43,6.134,6.134,0,0,1,1.244-1.9,3.784,3.784,0,0,1,2.849-.372l-.046-.027a4.176,4.176,0,0,1,1.45.655,13,13,0,0,0,1.65,5.277c.139.243.481.732.758.779.542.091.573-.448.82-.781a1.356,1.356,0,0,1,.868-.411,20.373,20.373,0,0,0,.141-2.447l.15-.856-.285-.869c-.627-.694.279-1.3-.155-2.131a3.023,3.023,0,0,0-2.037-1.773l-.018.013a2.4,2.4,0,0,0-2.323.7,3.124,3.124,0,0,0-2.8-1.91,7.734,7.734,0,0,0-3.98,1.142,8.489,8.489,0,0,0-2.226,2.591,25.111,25.111,0,0,0-.7,2.614Z"
              transform="translate(-118.573 -124.469)"
              fill="#263238"
            />
            <path
              id="Path_94853"
              data-name="Path 94853"
              d="M173,170.415c.166-.005,4.251.212,5.734,2.974s1.348,3.71,1.348,3.71a1.947,1.947,0,0,1,.471.283c.2.164.115,1.128-.416,1.112-4.131-1.045-8.707-.44-13.746.581l-3.887.613c-1.064.312-1.495.088-1.083-.262.316-.269,3.186-2.032,3.186-2.032l.53-.545,1.223-2.494s-.25-.275.1-.6,3.258-3.24,6.542-3.341"
              transform="translate(-114.301 -120.785)"
              fill="#377289"
            />
            <path
              id="Path_94854"
              data-name="Path 94854"
              d="M174.559,190.771s.053-.006.151-.025l.431-.083c.373-.08.918-.168,1.592-.268a30.394,30.394,0,0,1,5.313-.245,46.7,46.7,0,0,1,5.3.535c.674.1,1.219.193,1.6.255l.434.07c.1.015.152.02.152.02s-.051-.014-.15-.033l-.431-.083c-.375-.069-.918-.169-1.592-.276a43.622,43.622,0,0,0-5.3-.555,29.092,29.092,0,0,0-5.322.265c-1.351.2-2.168.423-2.168.423"
              transform="translate(-123.722 -134.707)"
              fill="#263238"
            />
            <g id="Group_4961" data-name="Group 4961" transform="translate(54.099 50.213)" opacity="0.3">
              <g id="Group_4960" data-name="Group 4960">
                <g id="Group_4959" data-name="Group 4959" clip-path="url(#clip-path-20)">
                  <path
                    id="Path_94855"
                    data-name="Path 94855"
                    d="M194.247,172.947s-4.355.038-6.1,1.274l.2-.148-1.659,1.094a1.144,1.144,0,0,1-.932.274,8.529,8.529,0,0,1,2.482-1.926c1.671-.9,4.442-1.641,6-.568"
                    transform="translate(-185.761 -172.417)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94856"
              data-name="Path 94856"
              d="M189.345,173.011a3.062,3.062,0,0,0-.422-.184,5.37,5.37,0,0,0-1.221-.292,6.377,6.377,0,0,0-1.861.05,5.351,5.351,0,0,0-2.126.806,9.609,9.609,0,0,0-.932.729c-.286.253-.573.481-.826.727a5.782,5.782,0,0,1-.734.662,1.855,1.855,0,0,1-.791.3c-1.039.192-1.715.086-1.68.012a.17.17,0,0,0,.1.068,1.368,1.368,0,0,0,.338.053,5.744,5.744,0,0,0,1.253-.087,1.9,1.9,0,0,0,.813-.305,5.737,5.737,0,0,0,.745-.663c.25-.241.54-.47.826-.722a9.757,9.757,0,0,1,.926-.723,5.347,5.347,0,0,1,2.1-.8,6.467,6.467,0,0,1,1.845-.063,5.664,5.664,0,0,1,1.218.27c.14.047.247.089.319.119s.112.041.112.041"
              transform="translate(-126.694 -122.255)"
              fill="#263238"
            />
            <g id="Group_4964" data-name="Group 4964" transform="translate(58.537 53.183)" opacity="0.3">
              <g id="Group_4963" data-name="Group 4963">
                <g id="Group_4962" data-name="Group 4962" clip-path="url(#clip-path-21)">
                  <path
                    id="Path_94857"
                    data-name="Path 94857"
                    d="M207.473,183.2a9.647,9.647,0,0,1-2.153-.01c-.834-.038-1.654-.041-2.485-.12a3.572,3.572,0,0,1-1.836-.451.893.893,0,0,0,.409.776,4.289,4.289,0,0,0,1.386.349,22.649,22.649,0,0,0,4.965.107,5.309,5.309,0,0,0-.286-.652"
                    transform="translate(-200.999 -182.614)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94858"
              data-name="Path 94858"
              d="M206.713,179.4c.517.127.836.217.92.624.07.339.072.769-.254.886a.852.852,0,0,1-.313.03l-5.425-.16a.794.794,0,0,1-.52-.14.539.539,0,0,1,.059-.752,1.478,1.478,0,0,1,.772-.316,17.712,17.712,0,0,1,4.762-.171"
              transform="translate(-142.457 -127.095)"
              fill="#377289"
            />
            <path
              id="Path_94859"
              data-name="Path 94859"
              d="M201.094,180.266a.513.513,0,0,1-.18-.2.667.667,0,0,1,.1-.7,1.655,1.655,0,0,1,1.015-.414c.424-.051.892-.1,1.384-.136a14.171,14.171,0,0,1,2.522.016c.322.032.58.075.758.106l.205.037a.32.32,0,0,0,.073.009.29.29,0,0,0-.07-.022l-.2-.05c-.177-.038-.435-.088-.757-.127a12.924,12.924,0,0,0-2.532-.036c-.494.036-.962.087-1.387.142a1.678,1.678,0,0,0-1.043.444.69.69,0,0,0-.09.74.457.457,0,0,0,.137.16.257.257,0,0,0,.066.033"
              transform="translate(-142.336 -126.659)"
              fill="#263238"
            />
            <path
              id="Path_94860"
              data-name="Path 94860"
              d="M176.387,185.09l3.288-.633-.12.977-3.6.538Z"
              transform="translate(-124.711 -130.738)"
              fill="#fff"
            />
            <path
              id="Path_94861"
              data-name="Path 94861"
              d="M179.921,193.743c0-.018-1.508.023-3.365.093s-3.364.141-3.363.159,1.507-.023,3.366-.093,3.363-.141,3.363-.159"
              transform="translate(-122.753 -137.316)"
              fill="#263238"
            />
            <path
              id="Path_94862"
              data-name="Path 94862"
              d="M53.739,250.285h-2.55v-5.557A3.006,3.006,0,0,1,54.17,241.7h2.665v2.586H54.17a.435.435,0,0,0-.432.438Z"
              transform="translate(-36.281 -171.313)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94863"
              data-name="Path 94863"
              d="M64.393,248.018a1.263,1.263,0,0,1,.866-1.526c1.319-.388,3.36-.874,3.916-.366.841.768,2.852,2.633,2.852,2.633l4.279,1.024.256,3.84-7.533-2.157s-2.048.109-1.865-.988c0,0-1.024-.4-.878-1.024s.987-.768.987-.768l-.183-1.1-2.687.471Z"
              transform="translate(-45.611 -174.292)"
              fill="#ffbe9d"
            />
            <path
              id="Path_94864"
              data-name="Path 94864"
              d="M65.094,240.825a2.578,2.578,0,1,1-2.578-2.578,2.578,2.578,0,0,1,2.578,2.578"
              transform="translate(-42.482 -168.862)"
              fill="#fff"
            />
            <path
              id="Path_94865"
              data-name="Path 94865"
              d="M62.161,243.194a2.724,2.724,0,1,1,2.724-2.724,2.727,2.727,0,0,1-2.724,2.724m0-5.156a2.433,2.433,0,1,0,2.433,2.432,2.435,2.435,0,0,0-2.433-2.432"
              transform="translate(-42.127 -168.508)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94866"
              data-name="Path 94866"
              d="M70.6,250.1l-3.4-.878s-.914-.256-.439.732,1.9.768,2.45,1.353a4.057,4.057,0,0,0,3.127,1.938"
              transform="translate(-47.223 -176.618)"
              fill="#ffbe9d"
            />
            <path
              id="Path_94867"
              data-name="Path 94867"
              d="M72.164,253.072a2.314,2.314,0,0,1-.448-.056,4.844,4.844,0,0,1-1.174-.379,3.23,3.23,0,0,1-.712-.481,5.2,5.2,0,0,1-.646-.738,1.626,1.626,0,0,0-.35-.357,1.808,1.808,0,0,0-.48-.212,8.932,8.932,0,0,1-1.105-.354,1.735,1.735,0,0,1-.5-.348,1.6,1.6,0,0,1-.3-.51,1.182,1.182,0,0,1-.066-.291.371.371,0,0,1,.115-.3.46.46,0,0,1,.294-.092,1.01,1.01,0,0,1,.276.038l1.756.47,1.18.33a2.547,2.547,0,0,1,.428.141,2.56,2.56,0,0,1-.442-.084l-1.193-.283-1.765-.439a.546.546,0,0,0-.44.03c-.1.08-.066.272,0,.433a1.477,1.477,0,0,0,.275.458,1.606,1.606,0,0,0,.455.314,9.378,9.378,0,0,0,1.086.35,1.925,1.925,0,0,1,.516.234,1.738,1.738,0,0,1,.379.391,5.259,5.259,0,0,0,.621.731,3.2,3.2,0,0,0,.676.478,5.485,5.485,0,0,0,1.138.417,2.851,2.851,0,0,1,.435.114"
              transform="translate(-47.046 -176.447)"
              fill="#eb996e"
            />
            <path
              id="Path_94868"
              data-name="Path 94868"
              d="M66.943,242.9l.383-.222-1.115-1.505Z"
              transform="translate(-46.928 -170.933)"
              fill="#377289"
            />
            <path
              id="Path_94869"
              data-name="Path 94869"
              d="M59.935,240.911c.017.019-.125.153-.273.422a2.846,2.846,0,0,0-.332,1.14,2.984,2.984,0,0,0,.135,1.182,4.143,4.143,0,0,1,.178.473,1.109,1.109,0,0,1-.278-.435,2.6,2.6,0,0,1-.186-1.232,2.509,2.509,0,0,1,.391-1.184c.181-.269.356-.379.365-.366"
              transform="translate(-41.937 -170.75)"
              fill="#263238"
            />
            <path
              id="Path_94870"
              data-name="Path 94870"
              d="M104.586,254.549l14.426,1.6,12.288-2.858.112,10.172s-13.421-.476-14.3-.549-12.831-1.644-12.831-1.644Z"
              transform="translate(-73.913 -179.527)"
              fill="#377289"
            />
            <path
              id="Path_94871"
              data-name="Path 94871"
              d="M114.713,258.668l-.078,6.809,2.57.335.056-6.861Z"
              transform="translate(-81.25 -183.336)"
              fill="#fff"
            />
            <path
              id="Path_94872"
              data-name="Path 94872"
              d="M204.029,232.018l1.078.906,7.212,2.423,4.159,6.146s1.939,18.29,1.832,19.89.082,1.9.082,1.9l-21.942-6.237-1.177-9.98.518-8.851,1.064-1.66,1.207,1.974,2.129-2.975Z"
              transform="translate(-138.404 -164.447)"
              fill="#377289"
            />
            <path
              id="Path_94873"
              data-name="Path 94873"
              d="M216.915,284.652l-21.965-.836.405,3.577,21.968,1.36Z"
              transform="translate(-138.174 -201.16)"
              fill="#fff"
            />
            <path
              id="Path_94874"
              data-name="Path 94874"
              d="M192.516,268.8a.537.537,0,0,1,.04.142l.085.413c.072.359.162.881.266,1.527.209,1.292.442,3.085.673,5.067s.448,3.775.629,5.069l.219,1.532.057.417a.564.564,0,0,1,.01.147.518.518,0,0,1-.04-.142l-.085-.413c-.072-.359-.163-.88-.267-1.526-.208-1.292-.442-3.085-.673-5.067s-.448-3.774-.629-5.069l-.218-1.533-.057-.417a.538.538,0,0,1-.01-.147"
              transform="translate(-136.448 -190.52)"
              fill="#263238"
            />
            <path
              id="Path_94875"
              data-name="Path 94875"
              d="M204.848,241.243a1.134,1.134,0,0,1,.157-.207l.473-.55,1.68-1.888.047-.052.053.047,1.505,1.354.125.113-.111.01c.9-1.185,1.725-2.247,2.324-3.009l.714-.9a2.269,2.269,0,0,1,.277-.316,2.272,2.272,0,0,1-.231.351l-.676.926c-.577.779-1.383,1.851-2.287,3.037l-.05.066-.061-.055-.125-.113-1.5-1.361.1,0-1.721,1.851-.5.522a1.1,1.1,0,0,1-.191.176"
              transform="translate(-145.19 -167.162)"
              fill="#263238"
            />
            <path
              id="Path_94876"
              data-name="Path 94876"
              d="M198.861,251.981a4.225,4.225,0,0,1-.942-1.028l.119,0c-.081.109-.164.228-.245.352a7.569,7.569,0,0,0-.531.962l-.044.1-.059-.082a2.883,2.883,0,0,1-.383-.6,2.9,2.9,0,0,1,.479.528l-.1.013a5.379,5.379,0,0,1,.515-1c.083-.128.17-.248.255-.358l.062-.08.057.082c.451.651.85,1.086.822,1.113"
              transform="translate(-139.467 -177.749)"
              fill="#263238"
            />
            <path
              id="Path_94877"
              data-name="Path 94877"
              d="M147.819,273.614l8.121,6.586,1.959-2.353-7.93-6.695Z"
              transform="translate(-104.77 -192.184)"
              fill="#263238"
            />
            <path
              id="Path_94878"
              data-name="Path 94878"
              d="M136.474,269.352a7.231,7.231,0,0,0-2.6-1.788,4.766,4.766,0,0,0-3.414-.164l-.108-.27a4.987,4.987,0,0,1,3.64.168,7.367,7.367,0,0,1,2.708,1.874Z"
              transform="translate(-92.392 -189.146)"
              fill="#263238"
            />
            <path
              id="Path_94879"
              data-name="Path 94879"
              d="M161.291,283.407a9.815,9.815,0,0,1,2.112.478c.542.28,2.111,1.324,2.111,1.324l3.172.6-.758,5.237-2.122-1.052-4.676.465s-1.018-.233-1.018-.961a1.924,1.924,0,0,1-.906-1.371,21.063,21.063,0,0,1-1.364-1.959c-.112-.317.523-1.067.523-1.067a7.386,7.386,0,0,0,2.146,1.309c.812.177,1.545-.143,1.615-.664a3.96,3.96,0,0,0-.835-2.337"
              transform="translate(-111.864 -200.87)"
              fill="#e0e0e0"
            />
            <g id="Group_4967" data-name="Group 4967" transform="translate(57.816 81.179)" opacity="0.3">
              <g id="Group_4966" data-name="Group 4966">
                <g id="Group_4965" data-name="Group 4965" clip-path="url(#clip-path-22)">
                  <path
                    id="Path_94880"
                    data-name="Path 94880"
                    d="M198.579,289.137s10.644,3.817,14.216,3.59c3.025-.192,4.514-1.61,4.754-3.59s.573-10.394.573-10.394l-5.711,9.9-13.888-.436Z"
                    transform="translate(-198.523 -278.743)"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_94881"
              data-name="Path 94881"
              d="M206.693,263.054a64.861,64.861,0,0,0,1.607-6.4l1.327-7.008a7.63,7.63,0,0,0-1.726-5.025,4.8,4.8,0,0,0-3.716-1.929c-3.445.015-3.323,6.365-3.323,6.365l-.938,9.09-13.478-2.2-.855,6.481,15.852,3.42a4.714,4.714,0,0,0,3.888-.749,4.124,4.124,0,0,0,1.363-2.051"
              transform="translate(-131.541 -172.015)"
              fill="#377289"
            />
            <path
              id="Path_94882"
              data-name="Path 94882"
              d="M242.4,244.58c0,.026-.275.034-.7.146a3.684,3.684,0,0,0-2.481,2.144c-.172.4-.22.674-.245.669a1.763,1.763,0,0,1,.145-.709,3.453,3.453,0,0,1,2.556-2.209,1.762,1.762,0,0,1,.723-.041"
              transform="translate(-169.374 -173.337)"
              fill="#263238"
            />
            <path
              id="Path_94883"
              data-name="Path 94883"
              d="M199.2,290.149l-.479,6.744,2.762.6.424-6.9Z"
              transform="translate(-140.844 -205.648)"
              fill="#fff"
            />
            <path
              id="Path_94884"
              data-name="Path 94884"
              d="M209.423,265.14s-.006.058-.024.169l-.086.492-.343,1.888-.552,2.986c-.111.582-.218,1.2-.353,1.863s-.285,1.352-.452,2.078-.35,1.486-.562,2.272c-.1.393-.221.793-.346,1.2a8.413,8.413,0,0,1-.462,1.214,3.858,3.858,0,0,1-.785,1.1,4.175,4.175,0,0,1-1.173.756,4.894,4.894,0,0,1-2.86.3l-12.533-2.7-3.521-.76-.068-.015.009-.069.858-6.48.01-.074.073.012,13.475,2.217-.06.046.711-6.662c.086-.778.153-1.383.2-1.8.024-.2.042-.355.056-.466s.024-.158.024-.158,0,.054-.009.16-.023.266-.041.468c-.04.417-.1,1.022-.172,1.8l-.664,6.667-.006.055-.055-.009-13.482-2.178.083-.062-.852,6.481-.059-.084,3.522.759,12.527,2.707a4.763,4.763,0,0,0,2.781-.284,4.056,4.056,0,0,0,1.136-.727,3.733,3.733,0,0,0,.761-1.062,8.278,8.278,0,0,0,.459-1.195c.126-.4.243-.8.349-1.191.214-.783.4-1.541.571-2.266s.323-1.417.462-2.073.25-1.28.364-1.86l.577-2.981.371-1.883.1-.489c.024-.11.039-.166.039-.166"
              transform="translate(-131.337 -187.506)"
              fill="#263238"
            />
            <path
              id="Path_94885"
              data-name="Path 94885"
              d="M235.865,297.98a7.372,7.372,0,0,1-.886-1.2l-.567-.881a.619.619,0,0,0-.324-.241c0-.009.043-.028.125-.013a.555.555,0,0,1,.282.185c.181.216.379.522.611.865a7.391,7.391,0,0,1,.76,1.283"
              transform="translate(-165.914 -209.541)"
              fill="#263238"
            />
            <g id="Group_4970" data-name="Group 4970" transform="translate(60.132 69.094)" opacity="0.3">
              <g id="Group_4969" data-name="Group 4969">
                <g id="Group_4968" data-name="Group 4968" clip-path="url(#clip-path-23)">
                  <path
                    id="Path_94886"
                    data-name="Path 94886"
                    d="M206.474,241.714l1.826-1.125,2.015,2.134,2.618-5.476-2.944,3.86-1.627-1.471Z"
                    transform="translate(-206.474 -237.247)"
                  />
                </g>
              </g>
            </g>
            <rect
              id="Rectangle_2172"
              data-name="Rectangle 2172"
              width="3.319"
              height="8.489"
              transform="translate(10.45 70.437)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94887"
              data-name="Path 94887"
              d="M35.709,218.216a4.27,4.27,0,1,1-4.27-4.319,4.295,4.295,0,0,1,4.27,4.319"
              transform="translate(-19.257 -151.604)"
              fill="#fff"
            />
            <path
              id="Path_94888"
              data-name="Path 94888"
              d="M31.085,222.326a4.465,4.465,0,1,1,4.416-4.464,4.445,4.445,0,0,1-4.416,4.464m0-8.637a4.173,4.173,0,1,0,4.125,4.173,4.154,4.154,0,0,0-4.125-4.173"
              transform="translate(-18.902 -151.249)"
              fill="#e0e0e0"
            />
            <path
              id="Path_94889"
              data-name="Path 94889"
              d="M40.01,221.916l.817.237.662-3.035Z"
              transform="translate(-28.358 -155.304)"
              fill="#377289"
            />
            <path
              id="Path_94890"
              data-name="Path 94890"
              d="M38.579,242.184A1.477,1.477,0,0,1,38,242.5a4.11,4.11,0,0,1-1.57.212,4.852,4.852,0,0,1-1.55-.323,1.7,1.7,0,0,1-.574-.316c.012-.022.232.094.61.215a5.9,5.9,0,0,0,1.521.273,4.781,4.781,0,0,0,1.53-.162c.38-.11.595-.238.608-.215"
              transform="translate(-24.318 -171.572)"
              fill="#263238"
            />
            <path
              id="Path_94891"
              data-name="Path 94891"
              d="M32.664,270.811c0,.042-3.8.045-8.475.007s-8.475-.1-8.475-.144,3.795-.045,8.476-.007,8.475.1,8.474.144"
              transform="translate(-11.137 -191.822)"
              fill="#263238"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ComingSoonIcon;
