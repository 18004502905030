import * as React from 'react';

export interface StatProps {
  title: React.ReactNode;
  color?: string;
  value: React.ReactNode;
  unit: React.ReactNode;
}

export const Stat: React.FC<StatProps> = ({ color, title, value, unit }) => (
  <div className="usage-stats">
    <div className="stat-key">
      {color && <div className="circle" style={{ backgroundColor: color }} />}
      {title}
    </div>
    <div className="stat-value">
      {value}
      <span className="unit">{unit}</span>
    </div>
  </div>
);
