import { setupOidcClient, LogLevel } from 'piral-oidc';
import { oidcUrl } from './generated/config.codegen';

export const client = setupOidcClient({
  appUri: location.origin + '/',
  clientId: 'co4_portal',
  identityProviderUri: oidcUrl,
  postLogoutRedirectUri: location.origin + '/logout',
  redirectUri: location.origin + '/auth',
  responseType: 'code',
  logLevel: LogLevel.warn,
  scopes: ['email', 'offline_access', 'openid', 'profile'],
});
