import createStore from 'zustand/vanilla';
import { removeController, updateController } from './controller';
import {
  GraphNode,
  NatureDefinition,
  NavChildren,
  SystemObject,
  SystemObjectData,
  SystemObjectDetails,
  SystemObjectRef,
  SystemObjectResult,
} from './types';

export interface Requester {
  query<T>(op: string, args: any, signal?: AbortSignal): Promise<T>;
  mutate<T>(op: string, args: any): Promise<T>;
}

const roots: Array<number> = [];
const requester: Requester = {
  mutate() {
    return Promise.reject('No requester configured.');
  },
  query() {
    return Promise.reject('No requester configured.');
  },
};

export const natures: Array<NatureDefinition> = [];

export const nodes = createStore(() => ({} as Record<string, GraphNode>));

export function configureRequester(newRequester: Requester) {
  Object.assign(requester, newRequester);
}

export function initializeGraph(objectRoots: Array<SystemObject>, initialNatures: Array<NatureDefinition>) {
  const rootNodes = objectRoots.map(toNode);
  natures.push(...initialNatures);
  roots.push(...rootNodes.map((r) => r.id));
  updateNodes(rootNodes);
}

function toNode(item: SystemObject): GraphNode {
  return {
    id: item.id,
    item,
    childNodes: item?.children?.map((m) => m.id) ?? [],
    facts: {},
    loaded: false,
    loading: false,
    deleted: false,
    details: {
      description: '',
      icon: item?.icon,
      images: [],
      labels: [],
      parents: item?.parents ?? [],
      path: item?.path ?? [],
      natures: item?.natures ?? [],
      name: item?.name,
    },
  };
}

function updateNodes(newNodes: Array<GraphNode>) {
  const snapshot = nodes.getState();
  const update: Record<string, GraphNode> = {};
  const queue = [...newNodes];

  while (queue.length > 0) {
    const node = queue.pop();

    if (node) {
      const existing = snapshot[node.id];

      if (!existing || newNodes.includes(node)) {
        update[node.id] = node;
      } else if (existing && !existing.loaded) {
        update[node.id] = {
          ...existing,
          item: {
            ...existing.item,
            children: node.item?.children || existing.item.children,
          },
          childNodes: node.childNodes || existing.childNodes,
        };
      }

      const children = node.item?.children?.map(toNode) ?? [];
      queue.push(...children);
    }
  }

  nodes.setState(update);
}

async function resyncNode(id: number) {
  let node = findGraphNode(id);

  if (node) {
    const result = await requester.query<SystemObjectData>('co4CoreGIGet', { id });
    updateNode(id, (node) => ({
      item: {
        ...node.item,
        natures: result.object.natures,
      },
      details: result.object,
      facts: {},
    }));

    //Update the "children" in parent nodes
    const parents = result.object?.parents || [];
    for (const parent of parents) {
      updateNode(parent.id, (node) => {
        const { children } = node?.item;
        if (children) {
          const child = children?.find((c) => c.id === id);
          if (child) {
            child.natures = result?.object?.natures ?? child?.natures ?? [];
          }
        }

        return {
          item: {
            ...node.item,
            children,
          },
        };
      });
    }
  } else {
    await loadGraphNode(id, false).then(() => resyncNode(id));
  }
}

function updateNode(id: number, fn: (node: GraphNode) => Partial<GraphNode>) {
  const state = nodes.getState();
  const oldItem = state[id];
  const newItem = fn(oldItem);
  updateNodes([
    {
      ...oldItem,
      ...newItem,
    },
  ]);
}

function moveNode(id: number, parents: Array<SystemObjectRef>) {
  const state = nodes.getState();
  const node = state[id];

  if (node) {
    const oldParents = node.details?.parents || [];
    const newParents = parents.map((p) => findGraphNode(p.id)!);

    updateNode(id, (node) => ({
      details: {
        ...node.details,
        parents,
      },
    }));

    for (const parent of oldParents) {
      updateNode(parent.id, (node) => ({
        childNodes: node.childNodes.filter((c) => c !== id),
      }));
    }

    for (const parent of newParents) {
      updateNode(parent.id, (node) => ({
        childNodes: [...node.childNodes, id],
      }));
    }
  }
}

export function retrieveGraphNode(id: number): GraphNode {
  const state = nodes.getState();
  const node = state[id];

  if (!node) {
    loadGraphNode(id);
  }

  return node;
}

export function findGraphNode(id?: number): GraphNode | undefined {
  if (typeof id === 'number') {
    const state = nodes.getState();
    return state[id];
  }

  return undefined;
}

export function findGraphNodes(ids: Array<number>): Array<GraphNode> {
  const state = nodes.getState();
  return ids.map((id) => state[id]).filter(Boolean);
}

export function getRootIds() {
  return roots;
}

export function mapSystemObjects(nodes: Array<GraphNode>) {
  return nodes.map((node) => node.item);
}

export function getRootNodes() {
  const nodes = findGraphNodes(roots);
  return mapSystemObjects(nodes);
}

export function queryNode(id: number, details: false, anon: boolean): Promise<[NavChildren, false]>;

export function queryNode(id: number, details: true, anon: boolean): Promise<[NavChildren, SystemObjectData]>;

export async function queryNode(
  id: number,
  details = false,
  anon = false,
): Promise<[NavChildren, SystemObjectData | false]> {
  if (typeof id !== 'number') {
    throw new Error('Received not valid id: ' + id);
  }

  return await Promise.all([
    requester.query<NavChildren>('co4CoreNavNext', { id, anon }),
    details && requester.query<SystemObjectData>('co4CoreGIGet', { id, anon }),
  ]);
}

export async function loadGraphChildren(id: number, anon = false) {
  const state = nodes.getState();
  const node = state[id];

  // load only if its not loaded and not loading and not all children are loaded
  if (!node.loaded && !node.loading) {
    await loadGraphNode(id, anon);
  }
}

async function completeNode(id: number, anon = false) {
  const [{ object: list }, { object: details }] = await queryNode(id, true, anon).catch((err) => {
    const deleted = !!err?.list?.some((m) => m?.message?.toLowerCase().includes('not found'));

    updateNode(id, () => {
      return {
        ...toNode({ id, name: 'Loading...', natures: [] } as SystemObject),
        id,
        loaded: true,
        loading: false,
        deleted,
      };
    });

    return [];
  });

  const node = nodes.getState()[id];
  const { item } = node || {};
  const childNodes = list.next.map((m) => m.id);

  return {
    ...node,
    item: {
      ...item,
      children: list.next,
    },
    loaded: true,
    loading: false,
    details,
    childNodes,
  } as GraphNode;
}

export async function completeNodes(loadedNodes: Array<GraphNode>, anon = true) {
  const unloadedNodes = loadedNodes.filter((child) => !child.loaded && !child.loading);

  if (unloadedNodes.length > 0) {
    const initial = unloadedNodes.reduce((obj, node) => {
      obj[node.id] = {
        ...node,
        loading: true,
      };
      return obj;
    }, {});

    nodes.setState(initial);

    const newStates = await Promise.all(unloadedNodes.map((node) => completeNode(node.id, anon)));
    const snapshot = nodes.getState();
    const updated = unloadedNodes.map((node, i) => {
      const c = snapshot[node.id];
      const s = newStates[i];

      return {
        ...c,
        ...s,
      };
    });

    updateNodes(updated);
  }
}

export async function loadGraphNode(id: number, anon = false) {
  updateNode(id, () => ({
    loading: true,
  }));

  const newState = await completeNode(id, anon);

  updateNode(id, () => newState);
  return newState;
}

export function loadGraphNodes(ids: Array<number>) {
  const current: Array<GraphNode> = [];
  const snapshot = nodes.getState();
  const promises: Array<Promise<void>> = [];

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const node = snapshot[id];
    current.push(node);

    if (!node?.loaded && !node?.deleted) {
      if (!node?.loading) {
        // load the node
        promises.push(
          loadGraphNode(id).then((res) => {
            // load parents of the node if not loaded
            // (when node is visited directly using URL with missing nodes in the path)
            const parents = res?.details?.parents?.map((v) => v?.id) || [];
            loadGraphNodes(parents);
          }),
        );
      }

      current.push(
        ...ids.slice(i + 1).map(
          (id): GraphNode => ({
            id,
            item: {
              id,
              name: '...',
              natures: [],
              children: undefined,
              icon: undefined,
            },
            loaded: false,
            deleted: false,
            loading: true,
            facts: {},
            childNodes: [],
            details: {
              images: [],
              natures: [],
              parents: [],
              path: [],
            },
          }),
        ),
      );

      break;
    }
  }

  return current;
}

export function removeFact(soid: number, fid: number) {
  return removeFacts(soid, [fid]);
}

export async function removeFacts(soid: number, fids: Array<number>) {
  const result = await requester.mutate('co4DeleteFacts', { ids: fids });
  await resyncNode(soid);
  return result;
}

export function updateFact(soid: number, fid: number, value: any) {
  return updateFacts(soid, [{ fid, value }]);
}

export async function updateFacts(soid: number, values: Array<{ fid: number; value: any }>) {
  const result = await Promise.all(
    values.map(({ fid, value }) => requester.mutate('co4SetFactValue', { id: fid, value })),
  );
  await resyncNode(soid);
  return result;
}

export function createFact(soid: number, type: string, name: string, value: string | number) {
  return createFacts(soid, type, { [name]: value });
}

export async function createFacts(soid: number, type: string, obj: Record<string, string | number>) {
  const result = await requester.mutate('co4AssertFacts', {
    soid,
    entries: Object.entries(obj).map(([name, value]) => ({
      characteristic: `${type}.${name}`,
      value,
    })),
  });

  await resyncNode(soid);
  return result;
}

export async function saveNature<T>(operation: string, soid: number, payload: Record<string, any>) {
  const result = await requester.mutate<T>(operation, payload);
  await resyncNode(soid);
  return result;
}

export async function addGraphNode(parents: Array<number>, input: Partial<Omit<SystemObjectDetails, 'parents'>>) {
  const res = await requester.mutate<SystemObjectResult>('co4CreateSystemObject', {
    parentIds: parents,
    input,
  });

  let newChild: SystemObject;

  if (res) {
    const path = (findGraphNode(parents[0])?.details?.path || []).concat(res.object.id);
    newChild = {
      ...input,
      name: input.name || '',
      id: res.object.id,
      natures: [],
      path,
    };
  }

  for (const parent of parents) {
    const parentNode = findGraphNode(parent);
    const children = [...parentNode?.item.children]?.concat(newChild).sort((a, b) => a.name.localeCompare(b.name));

    updateNode(parent, (n) => ({
      loaded: n.loaded,
      loading: false,
      item: {
        ...parentNode?.item,
        children,
      },
      childNodes: children.map((c) => c.id),
    }));
  }

  return res;
}

export async function deleteGraphNode(id: number) {
  const node = findGraphNode(id);

  const res = await requester.mutate('co4DeleteSystemObjects', {
    ids: [id],
  });

  if (node) {
    const parents = node.details?.parents || [];

    updateNode(node.id, () => ({
      deleted: true,
    }));

    for (const parent of parents) {
      updateNode(parent.id, (p) => ({
        loaded: false,
        loading: false,
        item: {
          ...p.item,
          children: p.item.children?.filter((c) => c.id !== id),
        },
        childNodes: p.childNodes.filter((c) => c !== id),
      }));
    }
  }

  return res;
}

export async function loadFact<T>(soid: number, op: string, force = false) {
  const controller = updateController(`fact-${op}-${soid}`);
  const so = findGraphNode(soid);

  if (!force && so && so.facts && op in so.facts) {
    return so.facts[op];
  }

  const result = await requester
    .query<T>(op, { soid }, controller.signal)
    .finally(() => removeController(`fact-${op}-${soid}`));

  updateNode(soid, (node) => {
    if (!node) {
      return {
        id: soid,
        loaded: false,
        loading: false,
        childNodes: [],
        details: {
          images: [],
          parents: [],
          natures: [],
          path: node?.details?.path ?? [],
        },
        facts: {
          [op]: result,
        },
        item: {
          id: soid,
          name: '...',
          natures: [],
          children: undefined,
          icon: undefined,
        },
      };
    } else {
      return {
        facts: {
          ...node.facts,
          [op]: result,
        },
      };
    }
  });

  return result;
}

export async function updateGraphNode(id: number, input: Partial<SystemObjectDetails>) {
  const { labels = [], parents = [], path, ...rest } = input;
  const res = await requester.mutate('co4UpdateSystemObject', { id, labels, ...rest });

  const currentParents = findGraphNode(id)?.details?.parents || [];
  const parentsChanged = JSON.stringify(currentParents) !== JSON.stringify(parents);

  if (parentsChanged) {
    await requester
      .mutate('co4MoveSystemObject', { id, parents: parents.map((p) => p?.id) })
      .then(() => moveNode(id, parents));
  }

  updateNode(id, (state) => ({
    details: {
      ...state.details,
      ...rest,
      labels,
      parents,
    },
  }));

  return res;
}

export function getSystemObjectRefs(segment: string): Array<number> {
  return segment
    .split('-')
    .map((x) => parseInt(x, 16))
    .filter((x) => !isNaN(x));
}

export function getSystemObjectLink(objects: Array<SystemObjectRef>) {
  return objects.map((obj) => obj.id.toString(16)).join('-');
}

export function getRootTrail() {
  const [root] = roots;
  return root.toString(16);
}

export function getObjectPath(id: number) {
  const object = findGraphNode(id);
  const path = object?.details?.path ?? [];
  return path.map((p) => p?.toString(16)).join('-');
}

export function formatObjectPath(path: Array<number>) {
  return path.map((p) => p.toString(16)).join('-');
}
