import { useRef } from 'react';

export function useDebouncedCallback<T extends any[], K = void>(
  callback: (...args: T) => K,
  delay?: number,
): (...args: T) => K {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const resultRef = useRef<K>();

  const cleanup = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (...args: T): K => {
    cleanup();

    timeoutRef.current = setTimeout(() => {
      resultRef.current = callback(...args);
    }, delay || 500);

    return resultRef.current as K;
  };
}
