import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlSelect, SlOption, SlButtonGroup, SlButton, SlIcon } from '@shoelace-style/shoelace/dist/react';

const pageRows = [5, 10, 15, 20, 25];
const pages = Array(20)
  .fill(0)
  .map((_, i) => i + 1);

const maxRange = 5;
const maxAround = Math.ceil(maxRange / 2);

export interface PaginationProps {
  maxRange?: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  rowsPerPage?: number;
  onRowsChange?: (rows: number) => void;
  currentRange: { start: number; end: number };
  totalData: number;
  activePage?: number
}

export const Pagination: React.FC<PaginationProps> = ({
  maxRange = 5,
  onPageChange,
  totalPages = pages.length,
  rowsPerPage = 10,
  onRowsChange = () => {},
  currentRange,
  totalData,
  activePage,
}) => {
  const [t] = useTranslation();
  const [current, setCurrent] = useState(activePage);
  const [rows, setRows] = useState(rowsPerPage);
  let pageNumbers: Array<number | '...'> = [];

  useEffect(() => {
    setCurrent(activePage);
  }, [activePage]);

  //for the first range of pages
  if (current < maxRange) {
    if (totalPages <= maxRange) {
      pageNumbers = pages.slice(0, totalPages);
    } else {
      pageNumbers = pages.slice(0, maxRange);
      pageNumbers.push('...');
    }
  }
  //for the last range of pages
  else if (totalPages - maxAround < current && current <= totalPages) {
    if (totalPages - current <= maxAround) {
      pageNumbers = pages.slice(totalPages - maxRange, totalPages);
    } else {
      pageNumbers = pages.slice(current - maxAround, totalPages);
    }
    if (maxRange < totalPages) {
      pageNumbers.unshift('...');
    }
  }
  // for the rest of the range
  else {
    const start = current - maxAround,
      end = start + maxRange;
    pageNumbers = pages.slice(start, end);
    pageNumbers.unshift('...');
    pageNumbers.push('...');
  }

  const handlePageChange = (page: number) => {
    if (page !== current) {
      setCurrent(page);
      onPageChange(page);
    }
  };
  const handleRowsChange = (e) => {
    const rowsPerPage = +(e.target as HTMLInputElement).value;
    if (rowsPerPage !== rows) {
      setRows(rowsPerPage);
      onRowsChange(rowsPerPage);
      setCurrent(1);
    }
  };

  return (
    <div className="pagination">
      <div className="page-row-stat">
        {t('show')}{' '}
        <SlSelect placement="top" value={`${rows}`} onSlChange={handleRowsChange} size="small">
          {pageRows.map((label) => (
            <SlOption value={`${label}`} key={label}>
              {label}
            </SlOption>
          ))}
        </SlSelect>{' '}
        {t('entries')}{' '}
        <span>{t('paginationText', { from: currentRange.start + 1, to: currentRange.end, total: totalData })}</span>
      </div>

      <div className="page-navigation">
        <SlButtonGroup>
          <SlButton
            size="small"
            disabled={current === 1}
            onClick={() => {
              handlePageChange(1);
            }}>
            <SlIcon library="fa" name="fas-angles-left"></SlIcon>
          </SlButton>
          <SlButton
            size="small"
            disabled={current === 1}
            onClick={() => {
              handlePageChange(current - 1);
            }}>
            <SlIcon library="fa" name="fas-angle-left"></SlIcon>
          </SlButton>
          {pageNumbers.map((num, i) => (
            <SlButton
              key={i}
              size="small"
              className={isNaN(+num) ? 'page-ellipsis' : ''}
              variant={num === current ? 'primary' : 'default'}
              onClick={() => {
                if (num !== '...') {
                  handlePageChange(num);
                }
              }}>
              {num}
            </SlButton>
          ))}
          <SlButton
            size="small"
            disabled={current === totalPages}
            onClick={() => {
              handlePageChange(current + 1);
            }}>
            <SlIcon library="fa" name="fas-angle-right"></SlIcon>
          </SlButton>
          <SlButton
            size="small"
            disabled={current === totalPages}
            onClick={() => {
              handlePageChange(totalPages);
            }}>
            <SlIcon library="fa" name="fas-angles-right"></SlIcon>
          </SlButton>
        </SlButtonGroup>
      </div>
    </div>
  );
};
