const requests = new Map<string, AbortController>();

/**
 * checks if the controller exists and request is in progress
 */
export const isRequestInProgress = (name: string) => {
  const controller = requests.get(name);
  return !!controller;
};

/**
 * generates a new controller if it does not exist
 * @param name string with soid. eg: fact-coreGIGet-100
 * @returns AbortController
 */
export const getController = (name: string) => {
  let controller = requests.get(name);

  if (!controller) {
    controller = new AbortController();
    requests.set(name, controller);
  }

  return controller;
};

/**
 * checks if there is already a similar fact request for different soid. If there is, abort the request.
 * @param name string with soid. eg: fact-coreGIGet-100
 * @returns string with the existing controller name if it exists else null
 */
const verifyController = (name: string) => {
  const lastHyphen = name.lastIndexOf('-');
  const controllerName = name.slice(0, lastHyphen);
  const soid = name.slice(lastHyphen + 1);

  for (const [key] of requests) {
    if (key.includes(controllerName) && !key.includes(soid)) {
      return key;
    }
  }
  return null;
};

/**
 * aborts the request if it exists
 * @param name string with soid. eg: fact-coreGIGet-100
 */
export const abortRequest = (name: string) => {
  const controller = requests.get(name);

  if (controller) {
    controller.abort('Request aborted');
  }
};

/**
 * updates the controller if it exists. aborts the previous requests and generates a new controller for new request
 * @param name string with soid. eg: fact-coreGIGet-100
 * @returns AbortController
 */
export const updateController = (name: string) => {
  const existingControllerKey = verifyController(name);

  if (existingControllerKey) {
    abortRequest(existingControllerKey);
    removeController(existingControllerKey);
  }

  return getController(name);
};

/**
 * removes the controller from the map
 * @param name string with soid. eg: fact-coreGIGet-100
 */
export const removeController = (name: string) => {
  requests.delete(name);
};
