import { useEffect, useLayoutEffect, useState } from 'react';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export interface ResponsiveScreenSize {
  width: number;
  height: number;
  breakpoint: string;
  isDown: (media: string) => boolean;
  isUp: (media: string) => boolean;
}

const breakpoints = { xs: 0, sm: 481, md: 768, lg: 1024, xl: 1201 };

const isUp = (media: string) => window.innerWidth > breakpoints[media];
const isDown = (media: string) => window.innerWidth <= breakpoints[media];
const defaultScreenSize: ResponsiveScreenSize = {
  breakpoint: 'xs',
  height: 0,
  width: 0,
  isDown: () => true,
  isUp: () => false,
};

function getScreen(): ResponsiveScreenSize {
  const breakpoint =
    Object.keys(breakpoints).find(
      (v, i, a) => breakpoints[a[i]] < window.innerWidth && window.innerWidth <= breakpoints[a[i + 1]],
    ) || 'xl';

  if (typeof window !== 'undefined' && window.screen) {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height, breakpoint, isDown, isUp };
  }

  return defaultScreenSize;
}

export function useMediaQuery() {
  const [screen, setScreen] = useState(getScreen);
  const handleSize = () => setScreen(getScreen);

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(handleSize, []);

  return screen;
}
