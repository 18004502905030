import * as React from 'react';

interface NoDataProps {
  /**
   * Text to display
   * @default 'NO DATA FOUND!'
   */
  text?: string;
}

export const NoData: React.FC<NoDataProps> = ({ text, ...props }) => (
  <>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="195.2px"
      height="115px"
      viewBox="0 0 195.2 115"
      enableBackground="new 0 0 195.2 115"
      xmlSpace="preserve">
      <g id="Layer_1"></g>
      <g id="Layer_2">
        <g>
          <g>
            <g>
              <path
                fill="#EEEEEE"
                d="M57.9,13c1.3,1.9,0.2,4.3-1.1,5.6c-1.6,1.7-3.2,1.4-4,2.9c-0.9,1.8,1,3.1,0,5c-0.6,1.1-1.5,0.9-2.7,2.3
					c-1.5,1.8,0,2.3-1.1,3.6c-3.4,4.2-4.7,1.3-6.8,2.4c-2.7,1.4-4.5,2.6-5.8,2.4c-5.1-1.1-3.6-4.5-6-5.8c-3.4-1.8-5-9-2.7-11.5
					c1.2-1.3,2.4-1.5,2.5-2.6c0.2-1.6-2.2-2.1-2.5-4c-0.3-2.2,2.6-3.2,2.2-5.1c-0.4-1.8-3.4-1.7-3.6-3C26.1,3.3,32.4-0.2,39,0
					c3.1,0.1,8.2,1.1,9.1,3.7c0.5,1.5-0.8,2.5-0.1,4.4c0.5,1.4,1.7,2.2,3.2,3.2C52.7,12.4,55.6,9.8,57.9,13z"
              />
              <path
                fill="#BDBDBD"
                d="M42.2,11.9c1-0.1,1.6,6.8,1,7.7c-0.6,0.9-1.7,1.6-1.8,2.6c-0.1,1,0.4,18.4,0.7,18.2
					c0.4-0.3,3.4-4.8,2.8-5.9c-0.6-1.1-1.3-2.7-1.1-4.1c0.1-1.4,0.1-3.4,0.5-3.3c0.4,0,0.1,3.5,0.3,4.1c0.3,0.7,0.3,1.2,0.7,1.2
					c0.4,0,0.4-3.6,1-3.5c0.6,0.1,0.2,5.4,0,6.4c-0.2,1-0.9,4.4-1.5,5.6c-0.6,1.3-2.6,2.6-2.6,3c0,0.4,0.3,10.6,0.3,10.6l-5.1,0.1
					c0,0,1.9-20,1.5-20.7c-0.4-0.7-3.1-6.1-3.7-6.6c-0.6-0.6-0.9-3.9-0.6-4c0.3,0,0.5,3.2,1.1,3.3c0.6,0.1,1-6.4,1.4-6.3
					c0.5,0.1-0.4,6.7-0.2,7.1c0.2,0.4,1.7,2.9,2.2,2.8c0.5-0.1-0.3-18,0.1-18.2c0.4-0.2,0.7,0,1,1.8c0.3,1.9,0.7,6,1,6
					c0.3,0,1-0.6,1-1C42,18.2,41.7,11.9,42.2,11.9z"
              />
            </g>
            <g>
              <path
                fill="#EEEEEE"
                d="M153.4,32.3c-0.7,1.1-0.1,2.4,0.6,3.2c0.9,0.9,1.8,0.8,2.3,1.6c0.5,1-0.6,1.8,0,2.8
					c0.3,0.6,0.8,0.5,1.5,1.3c0.8,1,0,1.3,0.6,2c1.9,2.4,2.7,0.7,3.8,1.3c1.5,0.8,2.5,1.5,3.3,1.3c2.9-0.6,2-2.5,3.4-3.3
					c1.9-1,2.8-5.1,1.5-6.5c-0.7-0.8-1.4-0.8-1.4-1.5c-0.1-0.9,1.3-1.2,1.4-2.3c0.2-1.2-1.5-1.8-1.2-2.9c0.2-1,1.9-1,2-1.7
					c0.2-1.1-3.4-3.1-7.1-2.9c-1.8,0.1-4.7,0.6-5.1,2.1c-0.3,0.9,0.4,1.4,0,2.5c-0.3,0.8-1,1.3-1.8,1.8
					C156.3,31.9,154.7,30.4,153.4,32.3z"
              />
              <path
                fill="#BDBDBD"
                d="M162.2,31.6c-0.6,0-0.9,3.9-0.5,4.4c0.3,0.5,1,0.9,1,1.4c0,0.6-0.2,10.4-0.4,10.3
					c-0.2-0.1-1.9-2.7-1.6-3.3c0.3-0.6,0.7-1.5,0.6-2.3c-0.1-0.8-0.1-1.9-0.3-1.9c-0.2,0,0,2-0.2,2.3c-0.2,0.4-0.2,0.7-0.4,0.7
					c-0.2,0-0.2-2-0.6-2c-0.3,0-0.1,3,0,3.6c0.1,0.6,0.5,2.5,0.9,3.2c0.4,0.7,1.5,1.5,1.5,1.7c0,0.2-0.2,6-0.2,6l2.9,0.1
					c0,0-1.1-11.3-0.8-11.7c0.2-0.4,1.8-3.4,2.1-3.8c0.3-0.3,0.5-2.2,0.3-2.2c-0.2,0-0.3,1.8-0.6,1.9c-0.4,0-0.5-3.6-0.8-3.6
					c-0.3,0,0.3,3.8,0.1,4c-0.1,0.2-0.9,1.6-1.2,1.6c-0.3,0,0.1-10.2-0.1-10.3c-0.2-0.1-0.4,0-0.5,1c-0.1,1.1-0.4,3.4-0.6,3.4
					c-0.2,0-0.6-0.4-0.6-0.6C162.3,35.2,162.5,31.6,162.2,31.6z"
              />
            </g>

            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="97.6124"
              y1="30.2406"
              x2="97.6124"
              y2="84.4946"
              gradientTransform="matrix(-1 0 0 1 195.2069 0)">
              <stop offset="0" style={{ stopColor: '#E5E5E5' }} />
              <stop offset="0.4764" style={{ stopColor: '#F4F4F4' }} />
              <stop offset="1" style={{ stopColor: '#FFFFFF' }} />
            </linearGradient>
            <ellipse fill="url(#SVGID_1_)" cx="97.6" cy="80.4" rx="97.6" ry="34.6" />
            <text
              transform="matrix(1 0 0 1 37.813 84.1673)"
              fill="#3D5259"
              fontFamily="Poppins"
              fontWeight={500}
              fontSize={14}>
              {text ?? 'NO DATA FOUND!'}
            </text>
          </g>
        </g>
      </g>
    </svg>
  </>
);
