import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

const options: Highcharts.Options = {
  chart: {
    type: 'pie',
    spacing: [0, 0, 0, 0],
    width: 240,
    height: 240,
    marginLeft: -100,
    marginRight: 0,
    plotBackgroundColor: 'transparent',
    backgroundColor: 'transparent',
  },
  colors: ['#59B593', '#377289', '#C58293'],
  credits: { enabled: false },
  legend: {
    enabled: false,
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    floating: false,
    borderWidth: 0,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      borderWidth: 0,
      borderRadius: 0,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
    },
  },
  title: { style: { display: 'none', height: 0, width: 0 } },
  tooltip: {
    formatter: function () {
      const markerStyle = `background-color: ${this.point.color};
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;`;
      const markerHtml = `<span style="${markerStyle}"></span>`;
      return `${markerHtml} <b style="font-size:11px;">${this.point.name}: ${this.y}</b>`;
    },
    outside: true,
    useHTML: true,
  },
};

export interface DonutChartProps {
  data: Array<Highcharts.PointOptionsObject>;
  dataLabel: string;
}

export const DonutChart: React.FC<DonutChartProps> = ({ data, dataLabel }) => {
  const chartRef = React.useRef<HighchartsReact.RefObject>(null);

  React.useEffect(() => {
    const chart = chartRef.current?.chart;

    if (chart) {
      const { renderer, series } = chart;
      const sum = series[0]?.data.reduce((acc, point) => acc + (point.y ?? 0), 0) ?? 0;

      const { plotWidth, plotHeight, plotLeft, plotTop } = chart;

      const xPos = plotLeft + plotWidth / 2;
      const yPos = plotTop + plotHeight / 2;

      const font = { weight: '500', family: 'Poppins', color: '#3D5259' };

      const valueLabel = renderer
        .text(`${sum}`, xPos, yPos)
        .attr({
          zIndex: 5,
          align: 'center',
          rotation: 0,
        })
        .css({
          fontSize: '20px',
          fontWeight: font.weight,
          color: font.color,
          fontFamily: font.family,
        })
        .add();

      const unitLabel = renderer
        .text('kWh', xPos, yPos + 15)
        .attr({
          zIndex: 5,
          align: 'center',
          rotation: 0,
        })
        .css({
          fontSize: '12px',
          fontWeight: font.weight,
          color: font.color,
          fontFamily: font.family,
        })
        .add();

      // Cleanup function to remove the custom labels
      return () => {
        valueLabel.destroy();
        unitLabel.destroy();
      };
    }

    return () => {};
  }, []);

  const highChartsData: Highcharts.Options = {
    ...options,
    series: [
      {
        type: 'pie',
        name: dataLabel,
        data,
        size: '100%',
        innerSize: '60%',
        center: ['50%', '50%'],
      },
    ],
  };

  return (
    <div className="donut">
      <HighchartsReact highcharts={Highcharts} options={highChartsData} ref={chartRef} />
    </div>
  );
};
