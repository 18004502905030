import { useCurrentLanguage } from '@co4/base';
import { useDebouncedCallback, useOnClickOutside } from '@co4/components';
import { SlDropdown as DropdownEl } from '@shoelace-style/shoelace';
import { SlButton, SlCard, SlDropdown, SlIcon, SlIconButton, SlTooltip } from '@shoelace-style/shoelace/dist/react';
import { add, endOfDay, format, intervalToDuration, isAfter, isBefore, startOfDay, sub } from 'date-fns';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Range, DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { createStaticRanges, defineds } from './ranges';

import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

interface DatePickerProps {
  dates: {
    start: number | Date;
    end: number | Date;
  };
  /**
   * Disable dates before this date. Provide a date object or a timestamp.
   * @default undefined
   */
  min?: Date | number;
  /**
   * Disable dates after this date. Provide a date object or a timestamp.
   * @default Today
   * */
  max?: Date | number;
  /**
   *
   * @param dates returns the start and end dates of the selected range as timestamps
   * @returns
   */
  onSave: (dates: DatePickerProps['dates']) => void;
}

const isValidDate = (date: Date) => date instanceof Date && !isNaN(date.getTime());
const defaultDates = { start: defineds.startOfToday, end: defineds.endOfToday };

export const DateRangePicker: React.FC<DatePickerProps> = (props) => {
  const { dates = defaultDates, onSave, max = defineds.endOfToday, min } = props;

  const dateObjs = useMemo(() => ({ start: startOfDay(dates.start), end: endOfDay(dates.end) }), [dates]);

  const lang = useCurrentLanguage();
  const locale = locales?.[lang]?.[lang] || locales?.[lang];
  const [t] = useTranslation();
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [range, setRange] = useState({ ...dateObjs });
  const [error, setError] = useState('');
  const ref = useRef<DropdownEl>(null);
  const rangeRef = useRef({ ...dateObjs });

  const staticRanges = useMemo(
    () =>
      createStaticRanges([
        {
          label: t('today'),
          range: () => {
            return {
              startDate: defineds.startOfToday,
              endDate: defineds.endOfToday,
            };
          },
        },
        {
          label: t('yesterday'),
          range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
          }),
        },

        {
          label: t('thisWeek'),
          range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfToday,
          }),
        },
        {
          label: t('lastWeek'),
          range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek,
          }),
        },
        {
          label: t('thisMonth'),
          range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfToday,
          }),
        },
        {
          label: t('lastMonth'),
          range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
          }),
        },
        {
          label: t('last3Months'),
          range: () => ({
            startDate: defineds.startOfLast3Months,
            endDate: defineds.endOfLast3Months,
          }),
        },
        {
          label: t('thisYear'),
          range: () => ({
            startDate: defineds.startOfThisYear,
            endDate: defineds.endOfToday,
          }),
        },
        {
          label: t('lastYear'),
          range: () => ({
            startDate: defineds.startOfLastYear,
            endDate: defineds.endOfLastYear,
          }),
        },
      ]),
    [],
  );

  const close = () => {
    setRange({ ...dateObjs });
    setError('');
    setShowDatePickers(false);
  };

  useOnClickOutside(ref, close);

  const before = useMemo(() => (min ? new Date(min) : undefined), [min]);
  const after = useMemo(() => (max ? new Date(max) : undefined), [max]);

  const checkDatesValidity = useCallback(
    (dates: typeof range) => {
      let areValid = true,
        msg = '';
      const { start, end } = dates;
      if (!isValidDate(new Date(start)) || !isValidDate(new Date(end)) || start.valueOf() > end.valueOf()) {
        msg = t('startBeforeEndError');
        areValid = false;
      } else if ((before && isBefore(start, before)) || (after && isAfter(end, after))) {
        msg = t('dateRangeError');
        areValid = false;
      }

      setError(msg);

      return areValid;
    },
    [before, after],
  );

  const onDatesApply = useDebouncedCallback((dates: typeof range) => {
    if (checkDatesValidity(dates)) {
      setShowDatePickers(false);
      const start = startOfDay(dates?.start);
      const end = endOfDay(dates?.end);
      rangeRef.current = { start, end };
      onSave({ start: start?.valueOf(), end: end?.valueOf() });
    }
  });

  const updateDates = useDebouncedCallback((dates: typeof range) => {
    setRange(dates);
  });

  const moveRange = (direction: 'forward' | 'backward', dates: typeof range) => {
    const start = startOfDay(dates.start),
      end = endOfDay(dates.end);

    const intervals = intervalToDuration({ start, end: add(end, { seconds: 1 }) });

    let newStartDate = direction === 'forward' ? add(start, intervals) : sub(start, intervals);
    let newEndDate = direction === 'forward' ? add(end, intervals) : sub(end, intervals);
    let newRange = { start: newStartDate, end: newEndDate };
    rangeRef.current = { ...newRange };

    updateDates(newRange);
    onDatesApply(newRange);
  };

  return (
    <div className="date-range-picker">
      <SlIconButton name="chevron-left" library="lucide" onClick={() => moveRange('backward', rangeRef?.current)} />
      <SlDropdown hoist open={showDatePickers} ref={ref}>
        <div
          slot="trigger"
          className="date-range"
          aria-invalid={!!error}
          onClick={() => setShowDatePickers((prev) => !prev)}>
          {format(rangeRef?.current?.start, 'dd.MM.yyyy')} - {format(rangeRef?.current?.end, 'dd.MM.yyyy')}
        </div>
        <SlCard className="date-pickers-card">
          <ReactDateRangePicker
            inputRanges={[]}
            weekStartsOn={1}
            dateDisplayFormat="dd.MM.yyyy"
            direction="horizontal"
            rangeColors={['#377289']}
            staticRanges={staticRanges}
            ranges={[{ startDate: range.start, endDate: range.end, key: 'selection' }] as Range[]}
            onChange={({ selection }) => setRange({ start: selection.startDate, end: selection.endDate })}
            locale={locale}
            minDate={before}
            maxDate={after}
          />

          <div className="picker-actions">
            {!!error && (
              <SlTooltip content={error}>
                <SlIcon name="exclamation-triangle-fill" />
              </SlTooltip>
            )}
            <SlButton variant="neutral" size="small" onClick={close}>
              {t('close')}
            </SlButton>
            <SlButton variant="primary" size="small" onClick={() => onDatesApply(range)}>
              {t('apply')}
            </SlButton>
          </div>
        </SlCard>
      </SlDropdown>
      <SlIconButton name="chevron-right" library="lucide" onClick={() => moveRange('forward', rangeRef?.current)} />
    </div>
  );
};
