import { setDefaultAnimation } from '@shoelace-style/shoelace/dist/utilities/animation-registry';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library';
import Highcharts from 'highcharts';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icons from './icons';

function getIconFolder(name: string) {
  if (name.substring(0, 4) === 'fas-') {
    return 'solid';
  } else if (name.substring(0, 4) === 'fab-') {
    return 'brands';
  } else {
    return 'regular';
  }
}

Highcharts.setOptions({
  legend: {
    itemHiddenStyle: {
      color: '#536471',
      opacity: 0.7,
      textDecoration: 'none',
    },
  },
});

HighchartsAccessibility(Highcharts);

// Change the default animation for all dialogs
setDefaultAnimation('dialog.denyClose', {
  keyframes: [],
  options: {},
});

registerIconLibrary('fa', {
  resolver: (name) => {
    const filename = name.replace(/^fa[rbs]-/, '');
    const folder = getIconFolder(name);
    return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.2.1/svgs/${folder}/${filename}.svg`;
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('lucide', {
  resolver: (name) => `https://cdn.jsdelivr.net/npm/lucide-static@0.283.0/icons/${name}.svg`,
});

registerIconLibrary('unicons', {
  resolver: (name) => {
    const match = name.match(/^(.*?)(-s)?$/);
    return `https://cdn.jsdelivr.net/npm/@iconscout/unicons@3.0.3/svg/${match?.[2] === '-s' ? 'solid' : 'line'}/${
      match?.[1]
    }.svg`;
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('boxicons', {
  resolver: (name) => {
    let folder = 'regular';
    if (name.substring(0, 4) === 'bxs-') folder = 'solid';
    if (name.substring(0, 4) === 'bxl-') folder = 'logos';
    return `https://cdn.jsdelivr.net/npm/boxicons@2.0.5/svg/${folder}/${name}.svg`;
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('material', {
  resolver: (name) => {
    const match = name.match(/^(.*?)(_(round|sharp))?$/);

    if (match) {
      return `https://cdn.jsdelivr.net/npm/@material-icons/svg@1.0.33/svg/${match[1]}/${match[3] || 'outline'}.svg`;
    }

    return '';
  },
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

registerIconLibrary('core', {
  resolver: (name) => icons[name] || '',
  mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

setBasePath(__webpack_public_path__);

/* @ts-ignore */
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export * from '@shoelace-style/shoelace/dist/react';
export * as Highcharts from 'highcharts';
export { default as HighchartsReact } from 'highcharts-react-official';
export { default as HighchartsStock } from 'highcharts/highstock';
export { default as HighchartsMore } from 'highcharts/highcharts-more';
export { default as HighchartsAccessibility } from 'highcharts/modules/accessibility';
export { default as HighchartsArcDiagram } from 'highcharts/modules/arc-diagram';
export { default as HighchartsBoost } from 'highcharts/modules/boost';
export { default as HighchartsBoostCanvas } from 'highcharts/modules/boost-canvas';
export { default as HighchartsData } from 'highcharts/modules/data';
export { default as HighchartsDrilldown } from 'highcharts/modules/drilldown';
export { default as HighchartsExporting } from 'highcharts/modules/exporting';
export { default as HighchartsHeatmap } from 'highcharts/modules/heatmap';
export { default as HighchartsNetworkGraph } from 'highcharts/modules/networkgraph';
export { default as HighchartsSankey } from 'highcharts/modules/sankey';
export { default as HighchartsTimeline } from 'highcharts/modules/timeline';
export { default as HighchartsTreeGraph } from 'highcharts/modules/treegraph';
export { default as HighchartsTreeMap } from 'highcharts/modules/treemap';
export * as Leaflet from 'leaflet';
export * from 'react-leaflet';
export { default as Lottie } from 'lottie-react';
export * from './Accordion';
export * from './AddressAutocomplete';
export * from './Autocomplete';
export * from './Breadcrumbs';
export * from './Card';
export * from './ConfigureApp';
export * from './DateRangePicker';
export * from './EnergyChart';
export * from './FileUpload';
export * from './StepperWizard';
export * from './Heading';
export * from './Icon';
export * from './Illustrations';
export * from './LeftLabelledInput';
export * from './Logo';
export * from './MaskedInput';
export * from './NavButton';
export * from './NavTab';
export * from './Pagination';
export * from './Prompt';
export * from './Stats';
export * from './Tab';
export * from './Table';
export * from './TimeFilter';
export * from './UOMInput';
export * from './hooks';
export * from './utils';
