import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SlButton, SlIcon } from '@shoelace-style/shoelace/dist/react';

type PickedHTMLInputAttributes = Partial<
  Pick<HTMLInputElement, 'id' | 'required' | 'placeholder' | 'disabled' | 'name' | 'value' | 'type' | 'className'>
>;

export interface FileUploadProps extends PickedHTMLInputAttributes {
  onChange: (files: Array<File>) => void;
  files?: Array<File>;
  helperText?: string;
  label?: string;
  multiple?: boolean;
  labelPlacement?: 'top' | 'left';
  /**
   * Comma separated list of accepted file types. Eg: .pdf,.jpg
   */
  accepted?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onChange,
  files = [],
  helperText = '',
  placeholder = '',
  label = '',
  multiple = false,
  labelPlacement = 'top',
  accepted,
}) => {
  const { t } = useTranslation();
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const addFile = (file: File) => {
    const acceptedTypes = accepted?.split(/,\s?/g)?.filter(Boolean) || [];
    const extension = file?.name?.split(/(?=\.)/g)?.[1];
    if (!extension || (acceptedTypes?.length && !acceptedTypes.includes(extension))) {
      alert(t('invalidFileType', { accepted }));
      return;
    }
    onChange(multiple ? [...files, file] : [file]);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const { files: newFiles } = e.dataTransfer;
    if (newFiles?.[0]) {
      addFile(newFiles[0] as File);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    const { files: newFiles } = e.target;
    if (newFiles?.[0]) {
      addFile(newFiles[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const handleRemoveFile = (index: number) => {
    const clone = [...files];
    clone.splice(index, 1);
    onChange(clone);
  };

  const shortenFileName = (name: string) => {
    if (name.length < 10) return name;
    return name.slice(0, 4) + '....' + name.slice(name.length - 7);
  };

  const leftLabelled = labelPlacement === 'left';

  return (
    <div className="file-upload">
      <div
        id="form-file-upload"
        className={`${leftLabelled ? 'left-labelled' : ''}`}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}>
        <label htmlFor="input-file-upload" className="form-control__label">
          <p>{label}</p>
        </label>
        <div className="drop-zone-wrapper">
          <div className={`drop-zone ${dragActive ? 'drag-active' : ''}`}>
            {Boolean(files.length) ? (
              files.map((file, i) => (
                <div className="uploaded-file" key={Date.now()}>
                  {shortenFileName(file.name)}
                  <SlIcon name="x-circle-fill" onClick={() => handleRemoveFile(i)} />
                </div>
              ))
            ) : (
              <span className="drop-placeholder">{placeholder}</span>
            )}
          </div>
          <SlButton variant="neutral" className="upload-btn" onClick={onButtonClick}>
            {t('upload')}
          </SlButton>
        </div>
        {helperText && <span className="helper-text">{helperText}</span>}
        <input
          ref={inputRef}
          accept={accepted}
          type="file"
          id="input-file-upload"
          multiple={true}
          onChange={handleChange}
        />
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </div>
    </div>
  );
};
