import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DeepPartial } from '../types';

export interface StackedChartProps {
  data: Array<Highcharts.SeriesColumnOptions>;
  labels: Array<string>;
  axis?: DeepPartial<{
    x: {
      title: string;
    };
    y: {
      title: string;
    };
  }>;
}

export const StackedChart: React.FC<StackedChartProps> = ({ data, labels, axis }) => {
  const highChartsData: Highcharts.Options = {
    chart: {
      type: 'column',
      spacing: [0, 0, 0, 0],
      height: 300,
      backgroundColor: 'transparent',
      marginTop: 12,
    },
    colors: ['#59B593', '#377289', '#C58293'],
    credits: { enabled: false },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      column: {
        pointWidth: 10,
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        borderWidth: 0,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    legend: { enabled: false, floating: false, align: 'right', verticalAlign: 'top' },
    series: data,
    title: { text: undefined },
    xAxis: { categories: labels },
    yAxis: {
      title: { text: axis?.y?.title ?? '' },
      endOnTick: false,
    },
  };

  return (
    <div className="bar-chart">
      <HighchartsReact highcharts={Highcharts} options={highChartsData} />
    </div>
  );
};
