import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SlButton, SlDialog } from '@shoelace-style/shoelace/dist/react';

interface PromptProps {
  open: boolean;
  title: string;
  content: string;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
  confirmText?: string;
  cancelText?: string;
}

export const Prompt: React.FC<PromptProps> = (props) => {
  const [t] = useTranslation();
  const {
    open,
    content,
    title,
    loading,
    onConfirm,
    onClose,
    confirmText = t('confirm'),
    cancelText = t('cancel'),
  } = props;

  return (
    <SlDialog open={open} label={title}>
      <div className="modal-body">
        <p>{content}</p>
      </div>
      <div className="dialogFooter">
        <SlButton slot="footer" variant="neutral" onClick={onConfirm} disabled={loading} loading={loading}>
          {confirmText}
        </SlButton>
        <SlButton slot="footer" variant="primary" onClick={onClose} disabled={loading}>
          {cancelText}
        </SlButton>
      </div>
    </SlDialog>
  );
};
