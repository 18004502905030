import React from 'react';
import Lottie from 'lottie-react';
import chartLoading from '../animations/chart-loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface ChartLoadingProps {
  loop?: boolean;
  className?: string;
}

export const ChartLoading: React.FC<ChartLoadingProps> = ({ className, ...props }) => {
  return (
    <div className={`chart-loading-skeleton ${className}`}>
      <Lottie {...defaultOptions} animationData={chartLoading} {...props} />;
    </div>
  );
};
