import { SlTab, SlTabPanel } from '@shoelace-style/shoelace/dist/react';
import { useEffect, useState } from 'react';
import { GetProps } from '../types';

interface TabProps extends GetProps<typeof SlTab> {
  label: string;
  name: string;
  tabClassName?: string;
  panelClassName?: string;
}

/**
 * A wrapper on the shoelace tab component. This includes the tab and tab panel.
 * This wrapper is added to enhance the tab component for _lazy loading_. The content is only loaded when the tab is active for the first time and then it stays in the DOM.
 *
 * Recommended to use this within the `SlTabGroup` component.
 */
export const Tab: React.FC<TabProps> = (props) => {
  const { name, label, active, disabled, children, tabClassName = '', panelClassName = '', ...rest } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (active && !loaded) {
      setLoaded(true);
    }
  }, [active]);

  return (
    <>
      <SlTab disabled={disabled} className={tabClassName} active={active} panel={name} slot="nav" {...rest}>
        {label}
      </SlTab>

      {loaded && (
        <SlTabPanel className={panelClassName} active={active} name={name}>
          {children}
        </SlTabPanel>
      )}
    </>
  );
};
