import 'regenerator-runtime/runtime';

import * as React from 'react';
import * as names from './constants';
import { Redirect } from 'react-router-dom';
import { getRootTrail } from '@co4/graph';
import { type SidebarRegistration, run } from '@co4/base';
import { type NavButtonProps, NavButton, LoadingIndicator } from '@co4/components';
import { type InitialTile, type TileComponentProps } from 'piral-dashboard';
import { feedUrl, apiUrl, appEnv } from './generated/config.codegen';
import { client } from './oauth';
import './sentry';

import homeIcon from '../icons/home.svg';
import objectsIcon from '../icons/objects.svg';
import logoutIcon from '../icons/logout.svg';

// disable the piral state view by default in console
sessionStorage.setItem('dbg:view-state', 'off');

if (process.env.DEBUG_PILET === 'on') {
  // default behavior is "off", but we want to load pilets from the included feed
  sessionStorage.setItem('dbg:load-pilets', 'on');
}

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Layout = React.lazy(() => import('./components/Layout'));
const Greetings = React.lazy(() => import('./tiles/GreetingsTile'));

function makeSidebar(navbarProps: NavButtonProps, position?: 'top' | 'bottom'): SidebarRegistration {
  return {
    component: () => <NavButton {...navbarProps} />,
    pilet: '',
    position,
  };
}

function makeTile(
  component: React.ComponentType<TileComponentProps>,
  initialColumns: number,
  initialRows: number,
): InitialTile {
  return {
    component,
    preferences: {
      initialColumns,
      initialRows,
    },
  };
}

async function init() {
  try {
    const { shouldRender } = await client.handleAuthentication();

    if (shouldRender) {
      await run({
        client,
        feedUrl,
        apiUrl,
        appEnv,
        Layout: ({ children }) => (
          <React.Suspense fallback={<LoadingIndicator />}>
            <Layout>{children}</Layout>
          </React.Suspense>
        ),
        tiles: [makeTile(() => <Greetings />, 16, 4)],
        sidebars: {
          home: makeSidebar({ title: 'Home', href: '/', src: homeIcon, exact: true }, 'top'),
          objects: makeSidebar({ title: 'Object', href: names.objectsRootPath, src: objectsIcon, exact: false }, 'top'),
          logout: makeSidebar({ title: 'Logout', onClick: client.logout, src: logoutIcon, exact: true }, 'bottom'),
        },
        routes: {
          [names.objectsRootPath]: (props) => <Redirect to={names.objectsDetailsPath} {...props} />,
          [names.objectsDetailsPath]: (props) => <Redirect to={names.objectsDetailsAppPath('gi')} {...props} />,
          [names.objectsDetailsAppPath()]: (props) => (
            <Redirect to={names.objectsDetailsArgsPath(props.match.params.app, getRootTrail())} {...props} />
          ),
          [names.objectsDetailsArgsPath()]: (props) => (
            <Dashboard {...props} app={props.match.params.app} trail={props.match.params.trail} />
          ),
          [names.objectsDetailsArgsSubPath()]: (props) => (
            <Dashboard {...props} app={props.match.params.app} trail={props.match.params.trail} />
          ),
        },
      });
    }
  } catch (error) {
    console.error(error);

    await client.logout();
  }
}

init();
