import * as React from 'react';
import { SlInput, SlOption, SlSelect } from '@co4/components';
import { GetProps } from '../types';

type SlInputProps = GetProps<typeof SlInput>;
type SlSelectProps = GetProps<typeof SlSelect>;

type SelectProps = SlSelectProps & { selectInput: true; options: Array<{ label: string; value: string }> };
type InputProps = SlInputProps & { selectInput?: false; options?: never };

type LLProps = SelectProps | InputProps;

export const LeftLabelledInput: React.FC<LLProps> = ({ selectInput, options, className, ...props }) => {
  if (selectInput) {
    return (
      <SlSelect className={`label-on-left ${className}`} {...(props as SlSelectProps)}>
        {options.map(({ label, value }) => (
          <SlOption value={value} key={value}>
            {label}
          </SlOption>
        ))}
      </SlSelect>
    );
  } else {
    return <SlInput className={`label-on-left ${className}`} {...(props as SlInputProps)} />;
  }
};
