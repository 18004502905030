import * as React from 'react';
import { Link } from 'react-router-dom';

import logoUrl from './logo_white.svg';

export const Logo = () => (
  <Link to="/" className='logo-link'>
    <div className='logo-wrapper'>
      <img src={logoUrl} alt="co4 Logo" className="header-logo" />
    </div>
  </Link>
);
