import * as React from 'react';

export interface UsageStatsProps {
  children: React.ReactNode;
  flow?: 'horizontal' | 'vertical';
}

export const UsageStats: React.FC<UsageStatsProps> = ({ children, flow = 'horizontal' }) => (
  <div className={`usage-stats-wrapper show-${flow}`}>{children}</div>
);
