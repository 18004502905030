import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SlIcon } from '@shoelace-style/shoelace/dist/react';

export interface AccordionProps {
  children: React.ReactNode;
  summary: string | JSX.Element;
  onlyShow?: boolean;
  noExpansion?: boolean;
  onClose?: () => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  summary,
  onClose = () => {},
  onlyShow,
  noExpansion,
}) => {
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const { t } = useTranslation();

  const toggleAccordion = () => {
    if (!onlyShow && !noExpansion) {
      openAccordion && onClose();
      setOpenAccordion((prev) => !prev);
    }
  };

  return (
    <div className={`accordion`}>
      <div className={`accordion-summary ${onlyShow ? 'only-show' : ''}`}>
        {summary}
        {!onlyShow && !noExpansion && (
          <div className="view-more" onClick={toggleAccordion}>
            {openAccordion ? t('less') : t('more')} <SlIcon name={openAccordion ? 'chevron-up' : 'chevron-down'} />
          </div>
        )}
      </div>
      <div className={`accordion-content ${!noExpansion && (openAccordion || onlyShow) ? 'open' : ''}`}>{children}</div>
    </div>
  );
};
