import * as React from 'react';
import { SlBreadcrumb, SlBreadcrumbItem, SlIconButton } from '@co4/components';

interface BreadcrumbsProps {
  breadcrumbs: Array<{ id: number | string; title: string }>;
  onBack: () => void;
  onBreadcrumbClick?: (id: number | string) => void;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs, onBack, onBreadcrumbClick = () => {} }) => {
  const handleBack = () => {
    onBack();
  };

  return (
    <div className="breadcrumbs">
      <SlIconButton library="fa" slot="prefix" name="fas-arrow-left" onClick={handleBack} />

      {breadcrumbs.length > 1 ? (
        <SlBreadcrumb>
          {breadcrumbs.map(({ title, id }, i) => (
            <SlBreadcrumbItem
              className={i === breadcrumbs?.length - 1 ? 'active-crumb' : ''}
              onClick={() => onBreadcrumbClick(id)}
              key={id}>
              {title}
            </SlBreadcrumbItem>
          ))}
        </SlBreadcrumb>
      ) : (
        <span>{breadcrumbs?.[0]?.title}</span>
      )}
    </div>
  );
};
