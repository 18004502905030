import * as React from 'react';
import { SlIcon, SlMenu, SlMenuItem, SlTag } from '@shoelace-style/shoelace/dist/react';
import { SlMenu as MenuEl } from '@shoelace-style/shoelace';
import { useDebouncedCallback, useOnClickOutside } from '../hooks';

export interface AutocompleteTagOption {
  name: string;
  value: string;
}

export interface AutocompleteProps {
  options: Array<AutocompleteTagOption | string>;
  label?: string;
  placeholder?: string;
  onChange: (tags: Array<string>) => void;
  value: Array<string>;
  disabled?: boolean;
  searchPlaceholder?: string;
  noTagsText?: string;
  helperText?: string;
  addTagText?: (value: string) => string | React.ReactNode;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  label = '',
  placeholder = '',
  value = [],
  onChange,
  disabled,
  searchPlaceholder = '',
  addTagText,
  noTagsText,
  helperText = '',
}) => {
  const [tags, setTags] = React.useState<Array<string>>(value);
  const givenOptions: Array<AutocompleteTagOption> = React.useMemo(
    () => options.map((o) => (typeof o === 'object' ? o : { name: o, value: o })),
    [options],
  );
  const [filteredOptions, setFilteredOptions] = React.useState<Array<AutocompleteTagOption>>(givenOptions);
  const listRef = React.useRef<MenuEl>(null);
  const selectorRef = React.useRef<HTMLDivElement>(null);
  const autocompleteRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!filteredOptions?.length) {
      setFilteredOptions(givenOptions);
    }
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value);
    }
  }, [value, givenOptions]);

  const addTag = (value: string) => {
    if (value && value.trim()) {
      const v = value.toLowerCase();
      const existingTag = givenOptions.find((op) => op.name.toLowerCase() === v);
      const tag = existingTag?.value ?? value;
      const newTags = [...tags, tag];
      setTags(newTags);
      onChange(newTags);
      addNewOption(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      addTag(e.target.value);
      e.target.value = '';
      setFilteredOptions(givenOptions);
    }
  };

  const handleChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const opts = value ? givenOptions.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase())) : [];
    setFilteredOptions(opts);
  }, 100);

  const handleRemove = (e: Event, tag: string) => {
    e.stopPropagation();
    const index = tags.indexOf(tag);

    if (index !== -1) {
      const newTags = [...tags];
      newTags.splice(index, 1);
      setTags(newTags);
      onChange(newTags);
    }
  };

  const toggleVisibility = (show?: boolean) => {
    if (selectorRef.current && !disabled) {
      if (show !== undefined) {
        selectorRef.current.style.display = show ? '' : 'none';
      } else {
        selectorRef.current.style.display = selectorRef.current?.style.display === 'none' ? '' : 'none';
      }
    }
  };

  const handleSelection = (tag: string) => {
    const index = tags.indexOf(tag);
    const newTags = [...tags];

    if (index === -1) {
      newTags.push(tag);
    } else {
      newTags.splice(index, 1);
    }

    setTags(newTags);
    onChange(newTags);
    addNewOption(tag);
  };

  const addNewOption = (value: string) => {
    const v = value.toLowerCase();
    const existingTag = givenOptions.find((op) => op.name.toLowerCase() === v);
    if (!existingTag) {
      givenOptions.push({ name: value, value });
      setFilteredOptions((prev) => [...prev, { name: value, value }]);
    }
  };

  useOnClickOutside(autocompleteRef, () => toggleVisibility(false));

  return (
    <div ref={autocompleteRef} className="tag-input">
      {label !== undefined && <label className="sub-heading">{label}</label>}
      <div className={`tags-input-container ${disabled ? 'disabled' : ''}`} onClick={() => toggleVisibility()}>
        <div className="tags">
          {Boolean(tags.length) ? (
            tags.map((tag) => (
              <SlTag
                size="medium"
                key={tag}
                removable={!disabled}
                onSlRemove={(e) => {
                  handleRemove(e, tag);
                }}>
                {tag}
              </SlTag>
            ))
          ) : (
            <span className="place-holder" hidden={!placeholder}>
              {placeholder}
            </span>
          )}
        </div>
        <div className="expand-icon">{!disabled && <SlIcon name="chevron-down"></SlIcon>}</div>
      </div>
      {helperText && <span className="helper-text">{helperText}</span>}

      <div ref={selectorRef} className="selector-list" style={{ display: 'none' }}>
        <input
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          type="text"
          className="tags-input"
          placeholder={searchPlaceholder}
        />
        <SlMenu ref={listRef}>
          {Boolean(filteredOptions.length) ? (
            filteredOptions.map(({ name, value }) => (
              <SlMenuItem
                key={value}
                value={value}
                checked={tags.includes(value)}
                onClick={() => handleSelection(value)}>
                {name}
              </SlMenuItem>
            ))
          ) : inputRef.current?.value ? (
            <SlMenuItem
              className="not-found-item"
              onClick={() => {
                if (inputRef.current) {
                  addTag(inputRef.current.value);
                  inputRef.current.value = '';
                  toggleVisibility(false);
                  setFilteredOptions(givenOptions);
                }
              }}>
              {addTagText ? (
                addTagText(inputRef.current?.value)
              ) : (
                <>
                  No Tag Found. <br />
                  Add <strong>"{inputRef.current?.value}"</strong>?
                </>
              )}
            </SlMenuItem>
          ) : (
            <SlMenuItem
              onClick={() => {
                if (inputRef.current) {
                  addTag(inputRef.current.value);
                  inputRef.current.value = '';
                  toggleVisibility(false);
                  setFilteredOptions(givenOptions);
                }
              }}>
              {noTagsText ? noTagsText : 'No Tags found'}
            </SlMenuItem>
          )}
        </SlMenu>
      </div>
    </div>
  );
};
