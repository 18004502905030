import { init, makeFetchTransport, makeMultiplexedTransport, moduleMetadataIntegration } from '@sentry/browser';
import { browserTracingIntegration, replayIntegration } from '@sentry/react';
import { appEnv } from './generated/config.codegen';

const EXTRA_KEY = 'PILET_ROUTE_TO';

const transport = makeMultiplexedTransport(makeFetchTransport, (args) => {
  const event = args.getEvent();
  if (event && event.extra && EXTRA_KEY in event.extra && Array.isArray(event.extra[EXTRA_KEY])) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

// Initialize Sentry (only on servers and not local development)
if (process.env.NODE_ENV === 'production' && !location.host.includes(':1234')) {
  init({
    dsn: 'https://76bfd150753f680223c974e25d5d4d16@o4507286704357376.ingest.de.sentry.io/4507286706520144',
    integrations: [
      moduleMetadataIntegration(),
      replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: [new RegExp(/https:\/\/gateway\.co4\.dev\/.*/)],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
      browserTracingIntegration(),
    ],
    ignoreErrors: [/frames/, /ResizeObserver/, /\(reading 'type'\)/],
    transport,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/localhost/, /^https:\/\/.\.co4\.(dev|cloud)/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: (event) => {
      if (event.request && event.request.url) {
        const url = new URL(event.request.url);
        event.request.url = url.hostname + url.pathname + url.search;
      }

      if (event?.exception?.values?.[0].stacktrace.frames) {
        const frames = event.exception.values[0].stacktrace.frames;
        // Find the last frame with module metadata containing a DSN
        const routeTo = frames
          .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
          .map((v) => v.module_metadata)
          .slice(-1); // using top frame only - you may want to customize this according to your needs

        if (routeTo.length) {
          event.extra = {
            ...event.extra,
            [EXTRA_KEY]: routeTo,
          };
        }
      }

      return event;
    },
  });
}
