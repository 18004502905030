import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ComingSoonIcon from './ComingSoonIcon';
import './styles.scss';

export const ComingSoon: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className="coming-soon">
      <ComingSoonIcon />

      <div className="coming-soon__content">
        <span className="coming-soon__title">{t('comingSoon')}</span>
        <br />
        <span className="coming-soon__description">{t('comingSoonDesc')}</span>
      </div>
    </div>
  );
};
