import * as React from 'react';
import {
  SlButton,
  SlDialog,
  SlIcon,
  SlIconButton,
  SlTab,
  SlTabGroup,
  SlTabPanel,
} from '@shoelace-style/shoelace/dist/react';
import { useTranslation } from 'react-i18next';
import { defaultIcon, icons } from './constants';
import { IconDefintion } from './types';

export interface IconSelectorProps {
  open: boolean;
  selected: IconDefintion;
  onChange(icon: IconDefintion): void;
  onClose(): void;
}

const Icon = ({ library = '', name = '', label = '', handleClick = () => {}, selected, t = (key: string) => key }) => (
  <div className="icon-selector--icon" aria-selected={selected} id={name} onClick={handleClick}>
    <SlIcon name={name} id={name} library={library} />
    <div className="icon-name" id={name}>
      {t(label)}
    </div>
  </div>
);

export const IconSelector: React.FC<IconSelectorProps> = ({ open, selected, onChange, onClose }) => {
  const [t] = useTranslation();
  const [selectedIcon, setSelectedIcon] = React.useState(selected);

  React.useEffect(() => {
    setSelectedIcon(selected);
  }, [selected]);

  const handleSave = () => {
    onChange(selectedIcon);
    onClose();
  };

  return (
    <>
      <div className="icon-selector">
        <SlDialog open={open} noHeader onSlHide={onClose}>
          <div className="icon-selector--body">
            <div>
              <div className="is-label">
                {t('iconSelector')}
                <span>{t('choosePresetIcon')}</span>
              </div>
              <div className="selected-icon-view">
                <div className="selected-icon">
                  <SlIcon name={selectedIcon.name} library={selectedIcon.library} />
                </div>
                <SlButton variant="neutral" className="use-default-btn" onClick={() => setSelectedIcon(defaultIcon)}>
                  {t('useDefault')}
                </SlButton>
                <div className="action-btns">
                  <SlButton size="small" onClick={onClose}>
                    {t('cancel')}
                  </SlButton>
                  <SlButton variant="primary" size="small" onClick={handleSave}>
                    {t('save')}
                  </SlButton>
                </div>
              </div>
            </div>
            <div className="icon-grid-wrapper">
              <SlTabGroup>
                {Object.keys(icons).map((tab) => (
                  <React.Fragment key={tab}>
                    <SlTab panel={tab} slot="nav">
                      {t(tab)}
                    </SlTab>
                    <SlTabPanel name={tab}>
                      <div className="icon-grid">
                        {icons[tab].map((icon) => (
                          <Icon
                            {...icon}
                            key={icon.label}
                            selected={icon.label === selectedIcon.label}
                            handleClick={() => {
                              setSelectedIcon(icon);
                            }}
                            t={t}
                          />
                        ))}
                      </div>
                    </SlTabPanel>
                  </React.Fragment>
                ))}
              </SlTabGroup>
              <SlIconButton name="multiply" library="unicons" onClick={onClose} />
            </div>
          </div>
        </SlDialog>
      </div>
    </>
  );
};
