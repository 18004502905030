import * as React from 'react';
import { SlIcon } from '@shoelace-style/shoelace/dist/react';
import { IconSelector } from './IconSelector';
import { defaultIcon } from './constants';
import { IconDefintion } from './types';

export interface IconEditProps {
  icon?: IconDefintion;
  onChange(value: IconDefintion): void;
}

export const IconEdit: React.FC<IconEditProps> = ({ onChange, icon = defaultIcon }) => {
  const [openSelector, setOpenSelector] = React.useState(false);

  return (
    <>
      <div className="so-edit-icon" onClick={() => setOpenSelector(true)}>
        <SlIcon {...icon} />
        <i className="fa-solid fa-pen" />
      </div>
      <IconSelector open={openSelector} selected={icon} onChange={onChange} onClose={() => setOpenSelector(false)} />
    </>
  );
};
