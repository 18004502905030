import * as React from 'react';
import * as Highcharts from 'highcharts';
import { SlIcon } from '@shoelace-style/shoelace/dist/react';
import { StackedChart } from './StackedChart';
import { DonutChart } from './DonutChart';
import { NoData } from '../Illustrations';

const colors = ['#59B593', '#377289', '#C58293'];

interface DoughnutData extends Highcharts.PointOptionsObject {
  visible?: boolean;
}

interface EnergyChartData {
  doughnut: EnergyChartProps['doughnut'];
  bar: EnergyChartProps['bar'];
}

function getIcon(icon: string | JSX.Element) {
  return typeof icon === 'string' ? <SlIcon src={icon} /> : icon;
}

export interface EnergyChartProps {
  doughnut: { data: Array<DoughnutData>; unit: string };
  bar: { data: Array<Highcharts.SeriesColumnOptions>; labels: Array<string> };
  labels: Array<string>;
  icons: Array<string | JSX.Element>;
  title: string;
  noDataText?: string;
}

export const EnergyChart: React.FC<EnergyChartProps> = (props) => {
  const { doughnut, icons, bar, title, noDataText = 'No Data Available' } = props;
  const [data, setData] = React.useState<EnergyChartData | null>(null);

  React.useEffect(() => {
    const barData = bar?.data.map((d) => ({ ...d, visible: true }));
    const doughnutData = doughnut?.data.map((d) => ({ ...d, visible: true }));

    setData({ bar: { ...bar, data: barData }, doughnut: { ...doughnut, data: doughnutData } });
  }, [bar, doughnut]);

  const getValueInPercentage = (value: number) => {
    const total = doughnut?.data.reduce((a, b: Highcharts.PointOptionsObject) => a + (b?.y ?? 0), 0);
    return ((value / total) * 100).toFixed(1) + '%';
  };

  const toggleVisibility = (d: EnergyChartProps['bar']['data'][0]) => {
    if (data) {
      const { bar, doughnut } = data;

      const index = bar?.data.findIndex((b) => b.name === d?.name);

      const updatedBarData = [...bar.data];
      updatedBarData[index] = { ...updatedBarData[index], visible: !updatedBarData[index].visible };

      const updatedDoughnutData = [...doughnut.data];
      updatedDoughnutData[index] = { ...updatedDoughnutData[index], visible: !updatedDoughnutData[index].visible };

      setData({ doughnut: { ...doughnut, data: updatedDoughnutData }, bar: { ...bar, data: updatedBarData } });
    }
  };

  return (
    <div className="energy-chart--container">
      <div className="title">{title}</div>
      {data ? (
        <>
          <div className="energy-chart--legend">
            {data.bar?.data.map((d, i) => (
              <div
                className={`legend-item ${!d?.visible ? 'disabled' : ''}`}
                key={i}
                onClick={() => toggleVisibility(d)}>
                <div className="point" style={{ backgroundColor: colors[i] }} />
                <div className="name">{d?.name}</div>
              </div>
            ))}
          </div>
          <div className="energy-chart--body">
            <div className="statistics">
              {doughnut?.data.map((d, i) => (
                <div className="stat" key={i}>
                  <div className="icon" style={{ color: colors[i] }}>
                    {getIcon(icons[i])}
                  </div>
                  <div className="value">
                    {getValueInPercentage(d?.y ?? 0)}
                    <span>
                      {d?.y} {doughnut.unit}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="energy-donut">
              <DonutChart data={data.doughnut?.data} dataLabel={doughnut?.unit} />
            </div>
            <div className="energy-column-chart">
              <StackedChart {...data?.bar} axis={{ y: { title: 'kWh' } }} />
            </div>
          </div>
        </>
      ) : (
        <div className="no-data">
          <div className="illustration">
            <NoData />
          </div>
          {noDataText}
        </div>
      )}
    </div>
  );
};
