import * as React from 'react';
import { createPortal } from 'react-dom';

export interface HeadingProps {
  title: string;
  hideTitle?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const Heading: React.FC<HeadingProps> = ({ title, hideTitle = false, children, className = '' }) => {
  const [target, setTarget] = React.useState(document.querySelector('.page-heading'));

  React.useLayoutEffect(() => {
    if (!target) {
      setTarget(document.querySelector('.page-heading'));
    }
  }, [target]);

  if (target) {
    return createPortal(
      <>
        <div className={`active-page ${hideTitle ? 'hidden' : ''} ${className}`}>{title}</div>
        {children}
      </>,
      target,
    );
  }

  return null;
};
