export const de = 'de';
export const en = 'en';
export const company = 'Co4 Cloud';
export const langKey = 'co4:app/language';
export const discardEditChange = 'discard-edit-change';
export const saveSystemObject = 'save-system-object';
export const saveSOInformation = 'save-so-information';
export const discardSOInformation = 'discard-so-information';
export const copyTokenDialog = 'copy-token';
export const createSystemObjectDialog = 'create-system-object';
export const systemObjectSelectionDialog = 'select-system-object';
export const systemObjectInformationDialog = 'system-object-information';
export const selectTypeDialog = 'select-type';
export const systemObjectSelectorExtension = 'core:system-object-tree';
export const filterObjectsDialog = 'filter-objects';
export const natureExtension = 'core:nature';
export const naturesExtension = 'core:natures';
export const natureNameExtension = 'core:nature-name';
export const natureIconExtension = 'core:nature-icon';
export const descendantObjects = `descendant-objects`;
export const defaultFeedUrl = 'https://feed.piral.cloud/api/v1/pilet/co4-dev';
export const defaultApiUrl = 'https://gateway.co4.dev';
export const defaultAuthUrl = 'https://auth.co4.dev/auth/realms/co4_dev';
