import Highcharts from 'highcharts';

/**
 * Get the value of a nested property in an object
 * @param obj The object to retrieve the value from
 * @param key The key of the nested property, separated by dots
 * @returns The value of the nested property, or null if not found
 */
export function getNestedValue(obj: Record<string, any>, key: string): any {
  const keys = key.split('.');
  let value = obj;
  for (const k of keys) {
    value = value && value?.[k];
    if (value === undefined) break;
  }
  return value ?? null;
}

/**
 * Deep Compare two objects or arrays
 * @param x First object or array
 * @param y Second object or array
 * @returns `true` if the objects or arrays are equal, `false` otherwise
 */
export function deepCompare(x: any, y: any) {
  if (x === y) {
    return true;
  } else if (
    typeof x === 'object' &&
    x != null &&
    typeof y === 'object' &&
    y != null &&
    Object.keys(x).length === Object.keys(y).length
  ) {
    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepCompare(x[prop], y[prop])) return false;
      } else return false;
    }
    return true;
  }

  return false;
}

/**
 * Set the default locale content for Highcharts.
 * Sets the months, weekdays, thousands and decimal separator.
 * @param lang the language to set
 */
export function setHighchartsDefaultLang(lang: string) {
  const translations: Record<string, Highcharts.LangOptions> = {
    en: {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortWeekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      resetZoom: 'Reset zoom',
      thousandsSep: ',',
      decimalPoint: '.',
    },
    de: {
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      shortWeekdays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      resetZoom: 'Zoom zurücksetzen',
      thousandsSep: "'",
      decimalPoint: '.',
    },
  };

  Highcharts.setOptions({
    lang: translations[lang],
  });
}
