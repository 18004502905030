import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SlIcon, SlTooltip } from '@shoelace-style/shoelace/dist/react';

export interface TabProps {
  selected?: boolean;
  link: string;
  activate?(): void;
  mode: 'active' | 'standby' | 'inactive';
  children?: React.ReactNode;
}

export const NavTab: React.FC<TabProps> = ({ activate, link, selected, mode, children }) => {
  const [t] = useTranslation();

  return (
    <div className={`nav-tab ${selected ? 'active' : ''}`}>
      {mode === 'active' ? (
        <Link to={link} role="tab" aria-selected={selected} aria-disabled={false}>
          {children}
        </Link>
      ) : mode === 'standby' ? (
        <SlTooltip placement="bottom" content={t('activate')} hoist>
          <div role="tab" aria-selected={selected} aria-disabled className="inactive switch-content" onClick={activate}>
            <div>{children}</div>
          </div>
        </SlTooltip>
      ) : (
        <a href="#" role="tab" aria-selected={selected} aria-disabled className="inactive">
          <div>{children}</div>
        </a>
      )}
    </div>
  );
};
