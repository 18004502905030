import React from 'react';
import { ColumnData } from './Table';
import { deepCompare, getNestedValue } from '../utils';

interface TableCellProps {
  cell: ColumnData;
  index: number;
  data: Record<string, string | number>;
}

const TableCell: React.FC<TableCellProps> = (props) => {
  const { cell, index, data } = props;
  return (
    <td data-value={getNestedValue(data, cell.field)}>
      {cell.renderer
        ? cell.renderer({ ...data, rowValue: getNestedValue(data, cell.field), index })
        : getNestedValue(data, cell.field) ?? '-'}
    </td>
  );
};

export default React.memo(TableCell, deepCompare);
