import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SlButton } from '@shoelace-style/shoelace/dist/react';

interface ConfigureAppProps {
  app: string;
  onConfigure: () => void;
}

const ConfigureIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
    <g id="noun-configure-1327144" transform="translate(-1 -1)">
      <g id="Group_4998" data-name="Group 4998" transform="translate(1 1)">
        <g id="Group_4981" data-name="Group 4981" transform="translate(8.197 17.885)">
          <g id="Group_4980" data-name="Group 4980">
            <path
              id="Path_94895"
              data-name="Path 94895"
              d="M30.317,57.815H26.691a.838.838,0,0,1-.788-.709L25.43,53.8a11.645,11.645,0,0,1-3.941-1.655l-2.68,2.048a.737.737,0,0,1-1.025-.079l-2.68-2.6a.742.742,0,0,1-.079-1.024l2.049-2.679A13.129,13.129,0,0,1,15.42,43.87l-3.31-.473a.838.838,0,0,1-.709-.788v-3.7a.838.838,0,0,1,.709-.788l3.31-.473a11.635,11.635,0,0,1,1.655-3.939L15.1,31.027A.736.736,0,0,1,15.183,30l2.6-2.6a.743.743,0,0,1,1.025-.079l2.68,2.048a12.348,12.348,0,0,1,3.941-1.655l.473-3.309a.838.838,0,0,1,.788-.709h3.626a.838.838,0,0,1,.788.709l.473,3.309a11.645,11.645,0,0,1,3.941,1.655l2.6-1.97a.958.958,0,0,1,1.261.079l2.443,2.442a.843.843,0,0,1,.079,1.1l-1.97,2.6a13.129,13.129,0,0,1,1.655,3.939l3.31.473a.838.838,0,0,1,.709.788v3.7a.838.838,0,0,1-.709.788l-3.31.473a11.635,11.635,0,0,1-1.655,3.939l2.049,2.679a.736.736,0,0,1-.079,1.024l-2.522,2.521a.9.9,0,0,1-.631.236h0a1.462,1.462,0,0,1-.552-.158l-2.6-1.97a12.348,12.348,0,0,1-3.941,1.655l-.473,3.309A.988.988,0,0,1,30.317,57.815Zm-3-1.576h2.286L30,53.009a.962.962,0,0,1,.631-.709,12.761,12.761,0,0,0,4.414-1.812,1,1,0,0,1,.946,0l2.522,1.97L40.09,50.8,38.2,48.361a.717.717,0,0,1,0-.945A11.394,11.394,0,0,0,40.012,43a.847.847,0,0,1,.709-.63l3.232-.394V39.694L40.721,39.3a.962.962,0,0,1-.709-.63A12.75,12.75,0,0,0,38.2,34.258a1,1,0,0,1,0-.945l1.97-2.6-1.576-1.655-2.6,1.97a.718.718,0,0,1-.946,0,11.4,11.4,0,0,0-4.414-1.812A.847.847,0,0,1,30,28.506l-.394-3.23H27.322l-.473,3.23a.962.962,0,0,1-.631.709,11.789,11.789,0,0,0-4.335,1.812,1.17,1.17,0,0,1-1.025,0l-2.522-1.97L16.76,30.712l1.97,2.6a.717.717,0,0,1,0,.945,12.75,12.75,0,0,0-1.813,4.412.855.855,0,0,1-.631.63l-3.232.394v2.285l3.232.394c.315.079.631.315.631.63a11.394,11.394,0,0,0,1.813,4.412,1.168,1.168,0,0,1,0,1.024l-1.892,2.521,1.576,1.655,2.522-1.97a.718.718,0,0,1,.946,0A11.4,11.4,0,0,0,26.3,52.458a.847.847,0,0,1,.631.709Z"
              transform="translate(-11.4 -23.7)"
              fill="#536471"
            />
          </g>
        </g>
        <g id="Group_4983" data-name="Group 4983" transform="translate(17.183 26.945)">
          <g id="Group_4982" data-name="Group 4982">
            <path
              id="Path_94896"
              data-name="Path 94896"
              d="M30.84,51.273a8.036,8.036,0,1,1,8.04-8.036A8.072,8.072,0,0,1,30.84,51.273Zm0-14.5A6.461,6.461,0,1,0,37.3,43.236,6.491,6.491,0,0,0,30.84,36.776Z"
              transform="translate(-22.8 -35.2)"
              fill="#536471"
            />
          </g>
        </g>
        <g id="Group_4988" data-name="Group 4988">
          <g id="Group_4985" data-name="Group 4985">
            <g id="Group_4984" data-name="Group 4984">
              <path
                id="Path_94897"
                data-name="Path 94897"
                d="M12.744,22.43H10.537a.838.838,0,0,1-.788-.709l-.236-1.812a8.074,8.074,0,0,1-2.128-.867l-1.419,1.1a.737.737,0,0,1-1.025-.079L3.365,18.491a.742.742,0,0,1-.079-1.024l1.1-1.5a6.367,6.367,0,0,1-.867-2.127l-1.813-.236A.838.838,0,0,1,1,12.818V10.612a.838.838,0,0,1,.709-.788l1.813-.236a7.119,7.119,0,0,1,.867-2.127l-1.1-1.5a.742.742,0,0,1,.079-1.024L4.941,3.364a.743.743,0,0,1,1.025-.079l1.419,1.1a6.373,6.373,0,0,1,2.128-.867l.236-1.812A.838.838,0,0,1,10.537,1h2.207a.838.838,0,0,1,.788.709l.236,1.812a8.074,8.074,0,0,1,2.128.867l1.419-1.024a1.462,1.462,0,0,1,.552-.158.9.9,0,0,1,.631.236l1.419,1.5A.843.843,0,0,1,20,6.042l-1.1,1.418a6.367,6.367,0,0,1,.867,2.127l1.813.236a.838.838,0,0,1,.709.788v2.206a.838.838,0,0,1-.709.788l-1.813.236a6.367,6.367,0,0,1-.867,2.127l1.1,1.5a.736.736,0,0,1-.079,1.024L18.34,20.067a.852.852,0,0,1-.552.236h0a.845.845,0,0,1-.473-.158l-1.419-1.1a6.373,6.373,0,0,1-2.128.867l-.236,1.812A.777.777,0,0,1,12.744,22.43Zm-1.5-1.576h.867L12.35,19.2a.962.962,0,0,1,.631-.709,6.352,6.352,0,0,0,2.6-1.1,1,1,0,0,1,.946,0l1.34,1.024.631-.63-1.025-1.339a.717.717,0,0,1,0-.945A6.213,6.213,0,0,0,18.5,12.9a.855.855,0,0,1,.631-.63l1.655-.236v-.867l-1.655-.236c-.315-.079-.631-.315-.631-.63a7.48,7.48,0,0,0-1.025-2.6,1,1,0,0,1,0-.945L18.34,5.57l-.552-.63-1.34,1.024a.718.718,0,0,1-.946,0,7.631,7.631,0,0,0-2.6-1.1.847.847,0,0,1-.631-.709l-.158-1.576h-.867L11.01,4.23a.962.962,0,0,1-.631.709A6.551,6.551,0,0,0,7.857,5.964a1,1,0,0,1-.946,0L5.572,4.939l-.631.63L5.966,6.909a.717.717,0,0,1,0,.945,6.213,6.213,0,0,0-1.025,2.6.855.855,0,0,1-.631.63l-1.734.236v.867l1.655.236c.315.079.631.315.631.63a6.545,6.545,0,0,0,1.025,2.521,1,1,0,0,1,0,.945l-.946,1.339.631.63,1.34-1.024a.718.718,0,0,1,.946,0,7.631,7.631,0,0,0,2.6,1.1.847.847,0,0,1,.631.709Z"
                transform="translate(-1 -1)"
                fill="#536471"
              />
            </g>
          </g>
          <g id="Group_4987" data-name="Group 4987" transform="translate(5.911 5.988)">
            <g id="Group_4986" data-name="Group 4986">
              <path
                id="Path_94898"
                data-name="Path 94898"
                d="M13.229,18.055a4.727,4.727,0,1,1,4.729-4.727A4.742,4.742,0,0,1,13.229,18.055Zm0-7.879a3.152,3.152,0,1,0,3.153,3.152A3.161,3.161,0,0,0,13.229,10.176Z"
                transform="translate(-8.5 -8.6)"
                fill="#536471"
              />
            </g>
          </g>
        </g>
        <g id="Group_4997" data-name="Group 4997" transform="translate(27.528 0.018)">
          <g id="Group_4990" data-name="Group 4990" transform="translate(9.064 13.396)">
            <g id="Group_4989" data-name="Group 4989">
              <path
                id="Path_94899"
                data-name="Path 94899"
                d="M48.194,29.508a1.121,1.121,0,0,1-.473-.158.847.847,0,0,1-.158-1.1l7.251-9.927a.788.788,0,0,1,1.261.945l-7.251,9.927A.973.973,0,0,1,48.194,29.508Z"
                transform="translate(-47.426 -18.026)"
                fill="#536471"
              />
            </g>
          </g>
          <g id="Group_4992" data-name="Group 4992" transform="translate(0 8.669)">
            <g id="Group_4991" data-name="Group 4991">
              <path
                id="Path_94900"
                data-name="Path 94900"
                d="M36.694,26.9a1.121,1.121,0,0,1-.473-.158.847.847,0,0,1-.158-1.1l9.774-13.315a.788.788,0,0,1,1.261.945L37.324,26.581A.973.973,0,0,1,36.694,26.9Z"
                transform="translate(-35.926 -12.026)"
                fill="#536471"
              />
            </g>
          </g>
          <g id="Group_4994" data-name="Group 4994" transform="translate(9.501)">
            <g id="Group_4993" data-name="Group 4993">
              <path
                id="Path_94901"
                data-name="Path 94901"
                d="M55.483,15.974a7.549,7.549,0,0,1-4.414-1.418,7.333,7.333,0,0,1-3-4.885A7.461,7.461,0,0,1,56.9,1.162a.817.817,0,0,1,.631.945.818.818,0,0,1-.946.63A5.9,5.9,0,0,0,51.937,13.3a5.979,5.979,0,0,0,8.276-1.261,5.889,5.889,0,0,0,.394-6.3.811.811,0,0,1,1.419-.788,7.445,7.445,0,0,1-.552,8.036A7.357,7.357,0,0,1,55.483,15.974Z"
                transform="translate(-47.979 -1.023)"
                fill="#536471"
              />
            </g>
          </g>
          <g id="Group_4996" data-name="Group 4996" transform="translate(13.517 0.16)">
            <g id="Group_4995" data-name="Group 4995">
              <path
                id="Path_94902"
                data-name="Path 94902"
                d="M57.272,11.842a2.181,2.181,0,0,1-1.261-.394l-2.049-1.5a2.091,2.091,0,0,1-.867-1.5,2.722,2.722,0,0,1,.552-2.048l3.626-4.885a.848.848,0,0,1,1.1-.158.847.847,0,0,1,.158,1.1l-3.7,4.885a1.588,1.588,0,0,0-.236.867.681.681,0,0,0,.236.473l2.049,1.5a.977.977,0,0,0,1.261-.394l3.626-4.885a.848.848,0,0,1,1.1-.158.847.847,0,0,1,.158,1.1L59.4,10.739A2.617,2.617,0,0,1,57.272,11.842Z"
                transform="translate(-53.075 -1.226)"
                fill="#536471"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ConfigureApp: React.FC<ConfigureAppProps> = (props) => {
  const { onConfigure, app: name } = props;

  const [t] = useTranslation();
  const app = name ?? t('application');

  return (
    <div className="configure-prompt">
      <div className="configure-prompt__icon">
        <ConfigureIcon />
      </div>
      <div className="configure-prompt__content">
        <span className="configure-prompt__title">{t('notConfiguredYetMsg', { app })}</span>
        <br />
        <span className="configure-prompt__description">{t('notConfiguredYetDesc', { app })}</span>
      </div>
      <SlButton size="small" variant="primary" onClick={onConfigure}>
        {t('configure')}
      </SlButton>
    </div>
  );
};
