import { memo } from 'react';
import { deepCompare } from '../utils';
import { ColumnData, Table } from './Table';

export interface SubRowProps {
  columns: Array<ColumnData>;
  renderer?: (data: Record<string, any>) => React.ReactNode | JSX.Element;
  data: Array<Record<string, any>>;
  colspan: number;
}

const SubRow: React.FC<SubRowProps> = (props) => {
  const { data = [], colspan, columns = [], renderer } = props;

  return (
    <tr className="table-sub-row">
      <td className="expand-button-td" />
      <td colSpan={colspan}>
        {typeof renderer === 'function' ? (
          renderer(data)
        ) : (
          <Table
            expandable={false}
            rowSelection={false}
            columns={columns}
            data={data}
            noDataText={'-'}
            rowsPerPage={15}
          />
        )}
      </td>
    </tr>
  );
};

export default memo(SubRow, deepCompare);
