import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UcumLhcUtils } from '@lhncbc/ucum-lhc';
import { SlMenu as Menu } from '@shoelace-style/shoelace';
import { SlDropdown, SlInput, SlMenu, SlMenuItem } from '@shoelace-style/shoelace/dist/react';
import { useDebouncedCallback, useOnClickOutside } from '../hooks';
import { LeftLabelledInput } from '../LeftLabelledInput';

export const ucum = UcumLhcUtils.getInstance();

export interface UOMInputProps {
  value: string;
  onChange: (ev: Event) => void;
  className?: string;
  wrapperClass?: string;
  label?: string;
  /**
   * Show details of the selected unit e.g: Help text
   * @default true
   */
  showDetails?: boolean;
  placeholder?: string;
  name?: string;
  labelPlacement?: 'top' | 'left';
  required?: boolean;
  readonly?: boolean;
}

const loadOptions = async (query: string) => {
  const results = await fetch(`https://clinicaltables.nlm.nih.gov/api/ucum/v3/search?df=cs_code,name&terms=${query}`)
    .then((res) => res.json())
    .then((res) => res?.at(-1));

  return results?.map(([unit, name]) => ({ label: `${unit} - ${name}`, value: unit })) ?? [];
};

let mounted = false;
export const UOMInput: React.FC<UOMInputProps> = (props) => {
  const { value = '', onChange, showDetails = true, wrapperClass = '', labelPlacement = 'top', ...rest } = props;
  const [t] = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<Menu>(null);

  const [uom, setUOM] = useState({ code: '', name: '' });
  const [options, setOptions] = useState([] as { label: string; value: string }[]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') toggleMenuVisibility(false);
      if (ev.key === 'Down' || ev.key === 'ArrowDown') {
        const firstItem = dropdownMenuRef.current?.firstElementChild as HTMLElement;
        firstItem?.focus();
      }
    };

    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, []);

  useEffect(() => {
    if (!mounted && ((value && !uom?.code) || uom?.code !== value)) {
      const uom = ucum.validateUnitString(value);
      setUOM({ code: uom?.unit?.code, name: uom?.unit?.name });
    }
    mounted = true;
  }, [value]);

  const toggleMenuVisibility = (show: boolean = true) => {
    setVisible(show);
  };

  useOnClickOutside(wrapperRef, () => toggleMenuVisibility(false));

  const selectValue = (val: { label: string; value: string }) => {
    setUOM({ code: val?.value, name: val?.label });
    onChange({ target: { name: rest?.name, value: val?.value } } as any);
  };

  let timeout: NodeJS.Timeout;
  const handleChange = useDebouncedCallback((ev: Event) => {
    const { value } = ev.target as HTMLInputElement;
    onChange(ev);

    clearTimeout(timeout);
    const uom = ucum.validateUnitString(value);

    if (uom?.unit?.code || !value) {
      timeout = setTimeout(() => {
        selectValue({ value: uom?.unit?.code ?? '', label: uom?.unit?.name ?? '' });
      }, 750);
    }

    loadOptions(value).then((res) => {
      setOptions(res);
      toggleMenuVisibility(value && res?.length > 0);
    });
  }, 500);

  //removing undefined props
  Object.keys(rest).forEach((key) => rest[key] === undefined && delete rest[key]);

  const error = useMemo(() => (value ? ucum.validateUnitString(value)?.status === 'invalid' : false), [value]);
  const helpText = useMemo(() => {
    if (error) return t('invalidUnit');
    else if (showDetails) return uom?.name ?? '';
  }, [value, uom?.name]);

  const Input = labelPlacement === 'top' ? SlInput : LeftLabelledInput;

  return (
    <div className={`uom-input ${wrapperClass}`} ref={wrapperRef}>
      <Input
        {...rest}
        aria-invalid={!!error}
        id="uom-input"
        value={value ?? ''}
        helpText={helpText}
        onSlInput={handleChange}
      />
      <SlDropdown open={visible} hoist className="selector-list">
        <SlMenu ref={dropdownMenuRef}>
          {options?.map((option) => (
            <SlMenuItem
              key={option?.label}
              value={option.value}
              onClick={() => {
                selectValue(option);
                toggleMenuVisibility(false);
              }}>
              {option.label}
            </SlMenuItem>
          ))}
        </SlMenu>
      </SlDropdown>
    </div>
  );
};
