import { Address } from './types';

const baseURL = `https://nominatim.openstreetmap.org`;

export async function queryPosition(text: string): Promise<Array<Address>> {
  const query = text.replace(/\s/g, '+').toLowerCase();
  const res = await fetch(`${baseURL}/search?q=${query}&format=jsonv2&limit=7&addressdetails=1`);
  return await res.json();
}

export async function queryOsmId(osmId: string): Promise<Array<Address>> {
  const res = await fetch(`${baseURL}/lookup?osm_ids=${osmId}&format=jsonv2`);
  return await res.json();
}
