import { distinctUntilChanged, BehaviorSubject } from 'rxjs';
import { de, en, langKey } from './constants';
import { LanguageLoader } from './types';

export const availableLanguages = [de, en];

const standardLanguage = normalizeLanguage(
  navigator.languages.find((m) => availableLanguages.includes(m)) || navigator.language,
);

function normalizeLanguage(proposed: string) {
  if (proposed && availableLanguages.includes(proposed)) {
    return proposed;
  }

  return en;
}

function getInitialLanguage() {
  return normalizeLanguage(localStorage.getItem(langKey) || standardLanguage);
}

const browserLocale$ = new BehaviorSubject<string>(getInitialLanguage());

export const currentLanguage$ = browserLocale$.pipe(distinctUntilChanged());

export function getLanguage() {
  return browserLocale$.value;
}

export function setLanguage(value: string) {
  localStorage.setItem(langKey, value);
  browserLocale$.next(value);
}

export function loadLocales(): LanguageLoader {
  const loadTranslations = require.context('./locales', true, /.json$/, 'lazy');
  return (language) => loadTranslations(`./${language}.json`);
}
