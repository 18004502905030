import * as React from 'react';

const sizes = { large: 18, medium: 16, small: 13 };
const weights = { normal: 400, medium: 500, bold: 600 };

export interface CardProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  className?: string;
  actions?: React.ReactNode;
  titleSize?: 'large' | 'medium' | 'small';
  titleWeight?: 'normal' | 'medium' | 'bold';
  bordered?: boolean;
}

export const Card: React.FC<CardProps> = (props) => {
  const { title, subtitle, className, children, actions, titleWeight = 'medium', titleSize = 'medium' } = props;
  const { bordered } = props;
  const fontSize = sizes[titleSize];
  const fontWeight = weights[titleWeight];

  return (
    <div className={`card-wrapper ${bordered ? 'bordered' : ''} ${className || ''}`}>
      <div className="card-head" hidden={!title && !actions}>
        <div className="card-title" hidden={!title} style={{ fontSize, fontWeight }}>
          {title}
        </div>
        <div className="card-actions" hidden={!actions}>
          {actions}
        </div>
      </div>
      <div className="card-subtitle" hidden={!subtitle}>
        {subtitle}
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};
