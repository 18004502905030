import * as React from 'react';
import { matchPath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { SlIcon } from '@shoelace-style/shoelace/dist/react';

export interface NavButtonBase {
  title: React.ReactNode;
  icon?: string;
  iconLibrary?: string;
  src?: string;
  disabled?: boolean;
  exact?: boolean;
}

export interface HrefNavButton extends NavButtonBase {
  href: `/${string}`;
  onClick?: never;
}

export interface ActionNavButton extends NavButtonBase {
  href?: never;
  onClick: () => void;
}

export type NavButtonProps = HrefNavButton | ActionNavButton;

export const NavButton: React.FC<NavButtonProps> = ({
  title,
  icon,
  iconLibrary = 'default',
  src,
  href,
  onClick,
  disabled,
  exact = true,
}) => {
  const iconProps = src ? { src } : { name: icon, library: iconLibrary };
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    if (!disabled) {
      onClick ? onClick() : history.push(href);
    }
  };

  if (typeof href === 'string') {
    const active = matchPath(location.pathname, {
      path: href,
      exact,
    });

    return (
      <div
        className={`nav-button ${!disabled && active ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
        aria-disabled={disabled}
        onClick={handleClick}>
        <SlIcon {...iconProps} />
        {title}
      </div>
    );
  } else {
    return (
      <div className={`nav-button ${disabled ? 'disabled' : ''}`} aria-disabled={disabled} onClick={handleClick}>
        <SlIcon {...iconProps} />
        {title}
      </div>
    );
  }
};
