import * as React from 'react';
import { useIMask, ReactMaskOpts } from 'react-imask';
import './styles.scss';

interface MaskedInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * The mask to apply to the input.
   * 0 for numbers, a for letters, * for any character.
   * Eg: '0000-0000-0000-0000'
   */
  mask: string;
  label?: string;
  helperText?: string;
  /** Options to pass to the react-imask.
   * See https://imask.js.org/guide.html#masked-input for more info
   */
  maskOptions?: ReactMaskOpts;
}

export const MaskedInput: React.FC<MaskedInputProps> = ({ mask, ...inputProps }) => {
  const { value, onChange, label, helperText, maskOptions: maskOpts = {}, ...rest } = inputProps;

  const {
    ref,
    value: maskedValue,
    setValue: setMaskedValue,
  } = useIMask(
    {
      mask,
      ...(maskOpts as any),
    },
    {
      onAccept: (value, mask) => {
        setMaskedValue(value);
      },
    },
  );

  let mounted = false;
  React.useEffect(() => {
    if (!mounted && value !== maskedValue) {
      setMaskedValue((value as string) ?? '');
      onChange?.({ target: { value }, currentTarget: { type: 'text' } } as React.ChangeEvent<HTMLInputElement>);
      mounted = true;
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaskedValue(event.target.value);
    onChange?.(event);
  };

  return (
    <div className="mask-input">
      {label !== undefined && (
        <label className="sub-heading" htmlFor={rest.id}>
          {label}
        </label>
      )}
      <input ref={ref as any} onChange={handleChange} value={maskedValue} {...rest} />
      {helperText && <span className="helper-text">{helperText}</span>}
    </div>
  );
};
