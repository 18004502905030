import { BehaviorSubject } from 'rxjs';
import { Co4App } from './types';

const apps = new BehaviorSubject<Array<Co4App>>([]);

export const apps$ = apps.asObservable();

export function swapApps(setValue: (current: Array<Co4App>) => Array<Co4App>) {
  const current = apps.value;
  const next = setValue(current);

  if (current !== next) {
    setApps(next);
  }
}

export function setApps(value: Array<Co4App>) {
  apps.next(value);
}
