import { IconDefintion } from './types';

export const defaultIcon = { label: 'flat', library: 'fa', name: 'far-building' };

export const icons: Record<string, Array<IconDefintion>> = {
  general: [
    { label: 'portfolio', library: 'lucide', name: 'briefcase' },
    { label: 'collaboration', library: 'fa', name: 'fas-people-line' },
    { label: 'group', library: 'fa', name: 'fas-people-group' },
    { label: 'set', library: 'material', name: 'signal_cellular_0_bar' },
    { label: 'structure', library: 'default', name: 'diagram-3' },
    { label: 'monitoring', library: 'default', name: 'graph-up-arrow' },
    { label: 'report', library: 'default', name: 'file-earmark-bar-graph' },
    { label: 'dashboard', library: 'default', name: 'grid-1x2' },
    { label: 'graph', library: 'unicons', name: 'arrow-growth' },
    { label: 'invoice', library: 'unicons', name: 'invoice' },
    { label: 'book', library: 'default', name: 'journal-bookmark-fill' },
    { label: 'map', library: 'unicons', name: 'map-pin' },
    { label: 'pin', library: 'default', name: 'pin' },
    { label: 'street', library: 'fa', name: 'fas-road' },
    { label: 'location', library: 'lucide', name: 'locate-fixed' },
    { label: 'representative', library: 'default', name: 'person-vcard' },
    {
      label: 'account',
      library: 'lucide',
      name: 'wallet',
    },
  ],
  assets: [
    { label: 'plug', library: 'lucide', name: 'plug-2' },
    { label: 'pvPanel', library: 'material', name: 'solar_power' },
    { label: 'windPower', library: 'material', name: 'wind_power' },
    { label: 'CHP', library: 'material', name: 'factory' },
    { label: 'heatPump', library: 'material', name: 'heat_pump' },
    { label: 'HVAC', library: 'material', name: 'hvac' },
    { label: 'fan', library: 'default', name: 'fan' },
    { label: 'pump', library: 'default', name: 'fuel-pump' },
    { label: 'electricFlash', library: 'unicons', name: 'bolt' },
    { label: 'switchGear', library: 'material', name: 'electric_meter' },
    { label: 'breaker', library: 'default', name: 'nintendo-switch' },
    { label: 'switch', library: 'unicons', name: 'sliders-v-alt' },
    { label: 'batteryStorage', library: 'fa', name: 'fas-car-battery' },
    { label: 'cloudPeers', library: 'core', name: 'cloud-peers' },
    { label: 'iotCloud', library: 'core', name: 'iot-cloud' },
    { label: 'peersNetwork', library: 'core', name: 'peers-network' },
  ],
  buildings: [
    { label: 'residentialSFH', library: 'boxicons', name: 'bx-home' },
    { label: 'residentialMFH', library: 'boxicons', name: 'bx-building-house' },
    { label: 'apartment', library: 'boxicons', name: 'bx-building-house' },
    { label: 'flat', library: 'fa', name: 'far-building' },
    { label: 'block', library: 'lucide', name: 'building' },
    { label: 'colony', library: 'boxicons', name: 'bxs-city' },
    { label: 'condo', library: 'fa', name: 'fas-tree-city' },
    { label: 'quarter', library: 'lucide', name: 'hotel' },
    { label: 'room', library: 'lucide', name: 'bed-double' },
    { label: 'zone', library: 'lucide', name: 'circle-dot' },
    { label: 'area', library: 'default', name: 'map' },
    { label: 'site', library: 'material', name: 'share_location' },
    { label: 'cottage', library: 'material', name: 'cottage' },
  ],
  commercial: [
    { label: 'commercialbuilding', library: 'material', name: 'business' },
    { label: 'warehouse', library: 'material', name: 'warehouse' },
    { label: 'complex', library: 'lucide', name: 'building-2' },
  ],
  industrial: [
    { label: 'industry', library: 'lucide', name: 'factory' },
    { label: 'factory', library: 'material', name: 'factory' },
  ],
  publicPlaces: [
    { label: 'hall', library: 'fa', name: 'fas-users-rectangle' },
    { label: 'mall', library: 'unicons', name: 'shopping-cart' },
    { label: 'shop', library: 'unicons', name: 'store' },
    { label: 'trainStation', library: 'unicons', name: 'metro' },
    { label: 'hospital', library: 'fa', name: 'far-hospital' },
    { label: 'landmark', library: 'lucide', name: 'landmark' },
    { label: 'school', library: 'lucide', name: 'school' },
    { label: 'university', library: 'unicons', name: 'university' },
    { label: 'temple', library: 'material', name: 'synagogue' },
    { label: 'park', library: 'lucide', name: 'trees' },
  ],
  misc: [
    { label: 'flower', library: 'default', name: 'flower2' },
    { label: 'sun', library: 'default', name: 'sun' },
    { label: 'crown', library: 'lucide', name: 'crown' },
    { label: 'hut', library: 'material', name: 'foundation' },
    { label: 'bike', library: 'material', name: 'directions_bike' },
    { label: 'smile', library: 'lucide', name: 'smile' },
    { label: 'money', library: 'lucide', name: 'banknote' },
  ],
  types: [
    { label: 'location', library: 'default', name: 'geo-alt' },
    { label: 'organisation', library: 'default', name: 'diagram-3' },
    { label: 'person', library: 'default', name: 'person' },
    { label: 'site', library: 'core', name: 'site' },
    { label: 'building', library: 'default', name: 'building' },
    { label: 'apartment', library: 'lucide', name: 'fullscreen' },
    { label: 'house', library: 'default', name: 'house' },
    { label: 'meteringDevice', library: 'default', name: 'device-ssd' },
    { label: 'meter', library: 'default', name: 'cpu' },
    { label: 'dataChannel', library: 'default', name: 'activity' },
    { label: 'device', library: 'default', name: 'robot' },
    { label: 'supplyLocation', library: 'core', name: 'supply-location' },
    { label: 'climateZone', library: 'core', name: 'climate-zone' },
    { label: 'zevSite', library: 'default', name: 'bullseye' },
    { label: 'zevZone', library: 'default', name: 'broadcast' },
    { label: 'co2', library: 'core', name: 'co2' },
  ],
};
