import * as React from 'react';
import { SlMenu, SlMenuItem } from '@shoelace-style/shoelace/dist/react';
import type { SlMenu as HTMLMenu } from '@shoelace-style/shoelace';
import { useOnClickOutside, useDebouncedCallback } from '../hooks';
import { queryOsmId, queryPosition } from './api';
import { Address } from './types';

export interface AddressAutocompleteProps {
  onChange: (value: Address) => void;
  value?: string;
  className?: string;
  name?: string;
  id?: string;
  label?: string;
  helperText?: string;
  readOnly?: boolean;
  osmId?: string;
}

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = (props) => {
  const { onChange, value, label, helperText, className = '', readOnly = false, osmId, ...rest } = props;
  const [addresses, setAddresses] = React.useState<Array<Address>>([]);
  const [inputValue, setInputValue] = React.useState(value ?? '');
  const menuRef = React.useRef<HTMLMenu>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const hideMenu = () => {
    if (menuRef.current) {
      menuRef.current.style.display = 'none';
    }
  };

  useOnClickOutside(wrapperRef, hideMenu);

  const getAddressList = useDebouncedCallback((text: string) => {
    queryPosition(text)
      .then((res) => {
        setAddresses(res);

        if (res.length && menuRef.current) {
          menuRef.current.style.display = '';
        }
      })
      .catch((err) => console.error(err));
  });

  const getAddressById = () => {
    if (osmId) {
      queryOsmId(osmId)
        .then(([res]) => setInputValue(res.display_name))
        .catch((err) => console.error(err));
    }
  };

  React.useEffect(getAddressById, [osmId]);

  React.useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: search } = evt.target;
    setInputValue(search);
    getAddressList(search);
  };

  const onAddressSelection = (value: Address) => {
    onChange(value);
    setInputValue(value.display_name);
  };

  return (
    <div className={`address-autocomplete ${className}`} {...rest} ref={wrapperRef}>
      {label !== undefined && (
        <label className="sub-heading" htmlFor={rest.id}>
          {label}
        </label>
      )}
      <input
        autoFocus
        readOnly={readOnly}
        onChange={onInputChange}
        type="text"
        className="address-input"
        value={inputValue}
      />
      {helperText && <span className="helper-text">{helperText}</span>}
      <SlMenu ref={menuRef} onSlSelect={hideMenu} style={{ display: 'none' }}>
        {addresses.map(({ display_name, osm_id, ...rest }) => (
          <SlMenuItem key={osm_id} onClick={() => onAddressSelection({ display_name, osm_id, ...rest })}>
            {display_name}
          </SlMenuItem>
        ))}
      </SlMenu>
    </div>
  );
};
