import React from 'react';
import Lottie from 'lottie-react';
import nodata from '../animations/no-data-found.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface NoDataAnimatedProps {
  loop?: boolean;
  className?: string;
}

export const NoDataAnimated: React.FC<NoDataAnimatedProps> = ({ className, ...props }) => {
  return (
    <div className={`no-data-animation ${className}`}>
      <Lottie {...defaultOptions} animationData={nodata} {...props} />;
    </div>
  );
};
